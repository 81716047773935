import {IProjectListResponse} from "./meta/ProjectListResponse";
import {INocoDbConfiguration} from "../../environments/FacilitiesEvaluationEnvironment";


export abstract class NocoDbProxy {

  protected static readonly AUTH_API = 'api/v1/auth';
  protected static readonly DB_META = 'api/v1/db/meta';


  public abstract signIn(url: string ): Promise<void>;
  public abstract get<T>(url: string ): Promise<T>;

  public isNocoDbVersion202Plus: boolean = false;


  protected _getSignInUrl() {

    return `${this.nocoDbConfig.httpServer}/${NocoDbProxy.AUTH_API}/user/signin`;
  }


  async db_meta_project_list(): Promise<IProjectListResponse> {

    const url = `${this.nocoDbConfig.httpServer}/${NocoDbProxy.DB_META}/projects`;
    return this.get( url );
  }

  constructor( public nocoDbConfig: INocoDbConfiguration) {

    if(  'https://next.mobilitymojo-spot.com' === nocoDbConfig.httpServer ||
      'http://localhost:8081' === nocoDbConfig.httpServer   ) {

      this.isNocoDbVersion202Plus = true;
    }
  }

}
