

import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoCluster, NocoClusterId} from "./NocoCluster";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";


export interface INocoClusterHierarchy {

  Parent: NocoClusterId;
  Child: NocoClusterId;
  Core: number;
  Starter: number;
  Sequence?: number;
}


export class NocoClusterHierarchy extends ValueObject<INocoClusterHierarchy> {

  protected onSetValue(value: INocoClusterHierarchy | null) {
  }

  constructor( value: INocoClusterHierarchy | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoClusterHierarchySet {

  private _log: ILogger = LoggerFactory.build( 'NocoClusterHierarchySet' );

  values: NocoClusterHierarchy[] = [];
  valuesById: {[id: number]: NocoClusterHierarchy} = {};


  public getRoots(): NocoClusterHierarchy[] {

    this._log.debug( 'this.values', this.values );

    const answer: NocoClusterHierarchy[] = [];

    for( const candidate of this.values ) {

      if( candidate.value.Child === candidate.value.Parent ) {

        answer.push( candidate );
      }
    }

    return answer;
  }

  public getHierarchy( parentId: NocoClusterId, childId: NocoClusterId ): NocoClusterHierarchy|null {

    for( const candidate of this.values ) {

      if( candidate.value.Parent === parentId && candidate.value.Child === childId ) {

        return candidate;
      }
    }

    return null;
  }


  public getChildren( clusterId: NocoClusterId ): NocoClusterHierarchy[] {

    const answer: NocoClusterHierarchy[] = [];

    for( const candidate of this.values ) {

      if( candidate.value.Parent === clusterId && candidate.value.Parent !== clusterId ) {

        answer.push( candidate );
      }
    }

    return answer;
  }

  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {

      return 'cluster_hierarchy';
    }
    return 'ClusterHierarchy';

  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoClusterHierarchy>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoClusterHierarchy>( tableName );
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoClusterHierarchySet> {

    const tableName = this._getTableName( proxy );
    const value: IListingReponse<INocoClusterHierarchy> = await proxy.getView<INocoClusterHierarchy>( tableName );
    return new NocoClusterHierarchySet( value );
  }

  public constructor( public value: IListingReponse<INocoClusterHierarchy> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoClusterHierarchy( rowValue );
      this.values.push( reference );
      const key = rowValue.Parent + ":" + rowValue.Child;
      this.valuesById[key] = reference;
    }

  }


}

