import {IProduct} from "../../model.product/IProduct";
import {
  IEnumeratedConstant
} from "../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {FacilitySummaryStructure} from "./FacilitySummaryStructure";
import {AppAspectDefinition} from "../../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";
import {ScoreRuleSet} from "../../javascript.lib.mojo-base/model/score/ScoreRuleSet";
import {FacilityScoreRules3} from "./FacilityScoreRules3";
import {FacilityReportSections4} from "./FacilityReportSections4";
import {FacilityEvaluationSections4} from "./FacilityEvaluationSections4";


export class FacilityAccessibilityProduct4 implements IProduct {

  evaluationSections = FacilityEvaluationSections4.getEvaluationSections();

  questions = AppAspectDefinition.ARRAY.toQuestionSet( FacilityEvaluationSections4.getEvaluationSections() );

  reportSections = FacilityReportSections4.VALUE;

  scoreRules: ScoreRuleSet = FacilityScoreRules3.VALUE;
  summarySections = FacilitySummaryStructure.summarySections;
  summaryStructure = FacilitySummaryStructure.moduleSummaryStructure;

  version = "4.0.0"; // neuro-diversity and outdoor spaces

  public static INSTANCE: FacilityAccessibilityProduct4 = new FacilityAccessibilityProduct4();


  public printCodes() {

    FacilityEnumeratedConstants.printCodes();
  }

  getAllEnums(): IEnumeratedConstant[] {

    return FacilityEnumeratedConstants.getAllEnums();
  }


  public constructor() {

  }

}
