import {IAppAnswer} from "../javascript.lib.mojo-base/model/AppAnswer";
import {AppAspectAnswers} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectAnswers";
import {
  getStorage,
  ref,
  getDownloadURL
} from "firebase/storage";

import {IFirebaseAnswersListener} from "../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/AngularFirebaseAnswers";
import {StorageReference} from "@firebase/storage";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {IFirebaseError} from "../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {FirebaseErrorCodes} from "../javascript.lib.mojo-base/firebase/FirebaseErrorCodes";
import {AppQuestionSet} from "../javascript.lib.mojo-base/model/AppQuestionSet";
import {AppAspectDefinition} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";


export class FirebaseAnswersListener implements IFirebaseAnswersListener {


  private _log = LoggerFactory.build( 'FirebaseAnswersListener' );


  private _allAnswers: { [questionKey: string]: IAppAnswer } = {};
  private _storageRoot: StorageReference;

  _questionKeyToPhotoUrlsMap: { [questionKey: string]: string[] } = {};
  _pathToPhotoUrlMap: { [path: string]: string } = {};
  private _photosNotFound: { [path: string]: boolean } = {};


  private async _updateAspectPhotos( propertyKey: string,
                                     questions: AppQuestionSet,
                                     answers: { [p: string]: IAppAnswer } | null ): Promise<boolean> {



    let aspectPhotosUpdated = false;

    for( const question of questions.questions ) {

      if( !question.isPhoto ) {
        continue;
      }

      const answerValue = answers[question.value.key];

      if( !answerValue ) {
        continue;
      }

      if( !answerValue.value ) {
        continue;
      }

      const photoUrls: string[] = [];

      for( const photoTimestamp of answerValue.value ) {

        const path = `photos/${propertyKey}/${question.value.key}/${photoTimestamp}.popup.jpg`;

        const photoUrl = this._pathToPhotoUrlMap[path];
        if( photoUrl ) {

          photoUrls.push( photoUrl );
          continue;
        }

        if( !this._photosNotFound[photoUrl] ) {

          let imageRef = ref( this._storageRoot, path );
          try {

            const photoUrl = await getDownloadURL( imageRef );
            aspectPhotosUpdated = true;
            photoUrls.push( photoUrl );
            this._log.debug( 'photoUrl', photoUrl );

          } catch ( e ) {


            const firebaseError = e as IFirebaseError;

            if( FirebaseErrorCodes.storage.OBJECT_NOT_FOUND === firebaseError.code ) {
              this._photosNotFound[photoUrl] = true;
            }

            this._log.logError( 'exception caught', (event) => {
              event.value.context['code'] = firebaseError.code;
              event.value.context['message'] = firebaseError.message;
            } );
          }

        }

      }

      this._questionKeyToPhotoUrlsMap[question.value.key] = photoUrls;
    }

    return aspectPhotosUpdated;
  }

  private async _updatePhotoLinks(propertyKey: string, questions: AppQuestionSet, answers: { [p: string]: IAppAnswer } | null ) {


    if( await this._updateAspectPhotos( propertyKey, questions, answers ) ) {

      const storageKey = `${propertyKey}.photos`;
      window.localStorage.setItem( storageKey, JSON.stringify( this._questionKeyToPhotoUrlsMap )  );

    }
  }


  private _updateAllAnswers(propertyKey: string, answers: { [questionKey: string]: IAppAnswer } | null) {

    if( !answers ) {
      return;
    }

    for( const questionKey of Object.keys( answers )) {

      this._allAnswers[questionKey] = answers[questionKey];
    }

    const storageKey = `${propertyKey}.answers`;
    window.localStorage.setItem( storageKey, JSON.stringify( this._allAnswers )  );

  }


  onReadValueXByKey(propertyKey: string, aspectQuestions: AppAspectDefinition, answers: { [questionKey: string]: IAppAnswer } | null) {

    if( null == answers ) {

      return;
    }

    // const storageKey = `${propertyKey}.${aspectQuestions.value.firebaseAspectId}.answers`;
    // window.localStorage.setItem( storageKey, JSON.stringify( answers )  );
    this._updateAllAnswers( propertyKey, answers );
    this._updatePhotoLinks( propertyKey, aspectQuestions.questionSet, answers );
  }

  onWriteValueXByKey(propertyKey: string, aspectAnswers: AppAspectAnswers) {


    // const storageKey = `${propertyKey}.${aspectAnswers.aspectQuestions.value.firebaseAspectId}.answers`;
    // window.localStorage.setItem( storageKey, JSON.stringify( aspectAnswers.value )  );
    this._updateAllAnswers( propertyKey, aspectAnswers.delegate.value );
    this._updatePhotoLinks( propertyKey, aspectAnswers.delegate.questions, aspectAnswers.delegate.value );
  }


  constructor() {

    const storage = getStorage();
    this._storageRoot = ref( storage );

  }
}
