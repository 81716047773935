
<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu homeDisabled="true" [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>


  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png" style="height: 34px;">
          <span style="flex: 1 1 auto;"></span>
        </mat-toolbar-row>

      </mat-toolbar>

      <div aclass="page-content">


        <div fxLayout="column" fxLayoutGap="10px" style="padding-top: 10px;">


          <div fxLayout="row" fxLayoutAlign="start center">

            <div fxFlex>
              <br>
            </div>

          </div>

        </div>

        <div cclass="completed-components">
          <div cclass="score">
            <score [score]="score"></score>
          </div>
          <div cclass="congratulations"
               *ngIf="!showCongratulationsText">
            Congratulations!
          </div>
          <div cclass="completed-text"
               *ngIf="!showCongratulationsText">
            You've completed the first step in promoting your accessible features.
          </div>

        </div>



      </div>

      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row>
          <span style="flex: 1 1 auto;"></span>

          <button mat-button (click)="onHome()" >
            HOME
            &nbsp;&nbsp;
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-toolbar-row>


      </mat-toolbar>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>

