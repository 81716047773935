import {IAppQuestion, AppQuestion} from '../../../model/AppQuestion';
import {IAppAnswer} from "../../../model/AppAnswer";
import {
  GeneralQuestions01,
  GeneralQuestions02,
  GeneralQuestions03,
  GeneralQuestions04,
  GeneralQuestions05,
  GeneralQuestions06,
  GeneralQuestions07,
  GeneralQuestions08,
  GeneralQuestions09,
  GeneralQuestions10,
  GeneralQuestions11,
  GeneralQuestions12,
  GeneralQuestions13,
  GeneralQuestions14,
  GeneralQuestions15
} from "./AccessibilityQuestions";


/**
 * will *eventually* encapsulate a set of questions and their associated answers
 */
export class QuestionAnswerSet {

  public questions: AppQuestion[] = [];


  calculateScore(): number {

    let answer = 0;
    answer += this.calculateScoreAcrossQuestions();
    return answer;
  }


  calculateScoreAcrossQuestions: () => number = () => 0;


  constructor( public questionValues: IAppQuestion[],
               public answerValues: { [key: string]: IAppAnswer; } ) {

    this.questions = questionValues.map( (e) => new AppQuestion( e ));
  }

}





export class General {


  /**
   * @deprecated use 'GeneralQuestions02.QUESTION_VALUES'
   */
  static readonly QUESTIONS_2: IAppQuestion[] = GeneralQuestions02.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions03.QUESTION_VALUES'
   */
  static readonly QUESTIONS_3: IAppQuestion[] = GeneralQuestions03.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions04.QUESTION_VALUES'
   */
  static readonly QUESTIONS_4: IAppQuestion[] = GeneralQuestions04.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions05.QUESTION_VALUES'
   */
  static readonly QUESTIONS_5: IAppQuestion[] = GeneralQuestions05.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions06.QUESTION_VALUES'
   */
  static readonly QUESTIONS_6: IAppQuestion[] = GeneralQuestions06.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions07.QUESTION_VALUES'
   */
  static readonly QUESTION_SET07_VALUES: IAppQuestion[] = GeneralQuestions07.QUESTION_VALUES;

  /**
   * @deprecated use `GeneralQuestions07.QUESTIONS`
   */
  static readonly QUESTION_SET07: AppQuestion[] = GeneralQuestions07.QUESTIONS;

  /**
   * @deprecated use 'GeneralQuestions08.QUESTION_VALUES'
   */
  static readonly QUESTIONS_8: IAppQuestion[] = GeneralQuestions08.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions09.QUESTION_VALUES'
   */
  static readonly QUESTIONS_9: IAppQuestion[] = GeneralQuestions09.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions10.QUESTION_VALUES'
   */
  static readonly QUESTIONS_10: IAppQuestion[] = GeneralQuestions10.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions11.QUESTION_VALUES'
   */
  static readonly QUESTION_SET11_VALUES: IAppQuestion[] = GeneralQuestions11.QUESTION_VALUES;

  /**
   * @deprecated use 'GeneralQuestions11.QUESTIONS'
   */
  static readonly QUESTION_SET11: AppQuestion[] = GeneralQuestions11.QUESTIONS;

  /**
   * @deprecated use `GeneralQuestions12.QUESTIONS`
   */
  static readonly QUESTION_SET12: AppQuestion[] = GeneralQuestions12.QUESTIONS;

  /**
   * @deprecated use 'GeneralQuestions15.QUESTION_VALUES'
   */
  static readonly QUESTION_SET15_VALUES: IAppQuestion[] = GeneralQuestions15.QUESTION_VALUES;


  public static readonly ALL_QUESTION_VALUES_SETS: IAppQuestion[][] = [
    GeneralQuestions01.QUESTION_VALUES,
    GeneralQuestions02.QUESTION_VALUES,
    GeneralQuestions03.QUESTION_VALUES,
    GeneralQuestions04.QUESTION_VALUES,
    GeneralQuestions05.QUESTION_VALUES,
    GeneralQuestions06.QUESTION_VALUES,
    GeneralQuestions07.QUESTION_VALUES,
    GeneralQuestions08.QUESTION_VALUES,
    GeneralQuestions09.QUESTION_VALUES,
    GeneralQuestions10.QUESTION_VALUES,
    GeneralQuestions11.QUESTION_VALUES,
    GeneralQuestions12.QUESTION_VALUES,
    GeneralQuestions13.QUESTION_VALUES,
    GeneralQuestions14.QUESTION_VALUES,
    GeneralQuestions15.QUESTION_VALUES,
  ];



  private static _QUESTIONS_ALL: IAppQuestion[] = null;

  public static getAllQuestionValues(): IAppQuestion[] {

    if ( null !== General._QUESTIONS_ALL ) {

      return General._QUESTIONS_ALL;
    }


    this._QUESTIONS_ALL = [];

    for ( const questionSet of General.ALL_QUESTION_VALUES_SETS ) {
      for ( const question of questionSet ) {

        this._QUESTIONS_ALL.push( question );
      }
    }

    return General._QUESTIONS_ALL;

  }


  static calculateScoreAcrossQuestions( answerValues: { [key: string]: IAppAnswer; } ): number {
    let answer = 0;

    answer += GeneralQuestions01.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions02.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions03.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions04.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions05.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions06.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions07.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions08.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions09.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions10.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions11.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions12.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions13.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions14.calculateScoreAcrossQuestions( answerValues );
    answer += GeneralQuestions15.calculateScoreAcrossQuestions( answerValues );

    return answer;
  }


  }
