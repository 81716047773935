

<mat-toolbar>

  <mat-toolbar-row >
  </mat-toolbar-row>

</mat-toolbar>

<div mat-dialog-content aclass="dialog-content">
  <mat-radio-group aria-label="Select an option"
                   [(ngModel)]="selectedOption"
                   color="primary">

    <mat-radio-button *ngFor="let option of options" [value]="option">
      {{option.label}}
    </mat-radio-button>
  </mat-radio-group>
</div>


<dialog-footer>
  <button mat-stroked-button color="primary" (click)="onCancelClick()">CANCEL</button>
  <span style="width: 8px;"></span>
  <button mat-stroked-button color="primary" (click)="onOkClick()" cdkFocusInitial>OK</button>
</dialog-footer>
