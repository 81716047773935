import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {BaseApplicationContext} from "../browser.lib.evaluation-tool/services.application-context/BaseApplicationContext";

/*
  Generated class for the ApplicationContextProvider provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular DI.
*/
@Injectable()
export class PwaApplicationContextProvider extends BaseApplicationContext {

  constructor() {

    super();


    // is it live ?
    if( 0 === window.location.protocol.indexOf( "https") ) {

      this.isProduction = true;
      this.isTest = false;

    } else if( "10000" === window.location.port ) { // dev ?

      this.isProduction = false;
      this.isTest = true;

    } else { // fall back to production

      this.isProduction = true;
      this.isTest = false;
    }

  }

}
