import {LogEvent} from "./LogEvent";


// export class NullLoggerDelegate implements ILoggerDelegate {
//
//
//   handle( event: LogEvent ) {
//   }
//
// }


export interface ILoggerDelegate {

  handle(event: LogEvent);
}

export class LoggerDelegate {

  public static INSTANCE: ILoggerDelegate = {
    handle(event: LogEvent) {
    }
  }

}

