import {Injectable} from "@angular/core";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FirebaseConnectionVersion9} from "../firebase/FirebaseConnectionVersion9";
import firebase from "firebase/compat";


@Injectable()
export class FirebaseConnectionService extends FirebaseConnectionVersion9 {


    constructor( public afAuth: AngularFireAuth,
                 public afDb: AngularFireDatabase,
                 public afStorage: AngularFireStorage,
                 public afFirestore: AngularFirestore) {

    super( afDb.database, afStorage.storage, afFirestore.firestore );


      this.afAuth.onAuthStateChanged( async (user: firebase.User|null) => {

        this.onAuthStateChanged( user );
      });


    }
}

