



export enum EEvaluationState {

  evaluating = 'evaluating',
  completed = 'completed',
  submitting = 'submitting',
  submitted = 'submitted',
}
