
<mat-toolbar>

  <mat-toolbar-row >
    <span style="flex: 1 1 auto;"></span>
    <h2>{{title}}</h2>
    <span style="flex: 1 1 auto;"></span>
  </mat-toolbar-row>

</mat-toolbar>
