

<div *ngIf="!sessionContext.fbUser">


  <div aclass="component-list">

    <form class="form__"
          [formGroup]="phoneNumberFormGroup"
          (ngSubmit)="onPhoneNumberFormSubmit()"
    >


      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>

      <div aclass="component-list-item">
        <div cclass="form-input">
          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Enter your phone number"
              [formControl]="phoneNumberFormControls.phoneNumber"
              type="text"
            >
          </mat-form-field>
        </div>
      </div>


      <div *ngIf="phoneNumberError" aclass="component-list-item">
        <div aclass="form-error">
          {{phoneNumberError}}
        </div>
      </div>


      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button color="primary" type="submit">Send Authentication code</button>
      </div>

      <div #recaptchaVerifier></div>


      <div aclass="component-list-item">
        <div style="width: 100%; height: 8px;">
        </div>
      </div>


    </form>

  </div>


  <div aclass="component-list">

    <form class="form__"
          [formGroup]="authCodeFormGroup"
          (ngSubmit)="onAuthCodeFormSubmit()"
    >


      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>

      <div aclass="component-list-item">
        <div cclass="form-input">
          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Enter authentication code"
              [formControl]="authCodeFormControls.authCode"
              type="text"
              [disabled]="null===_confirmationResult"
            >
          </mat-form-field>
        </div>
      </div>


      <div *ngIf="authCodeError" aclass="component-list-item">
        <div aclass="form-error">
          {{authCodeError}}
        </div>
      </div>


      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button color="primary" type="submit"
                [disabled]="null===_confirmationResult"
        >
          Authenticate
        </button>
      </div>


    </form>



  </div>


</div>
