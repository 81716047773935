import { Command } from "./Command";


export class HttpGet {



  public static async execute<T>( url: string ): Promise<T> {

    const answer = new Command<T>();

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 400) {

        const json = JSON.parse(xhr.responseText);
        answer.resolve(json);
      } else {

        answer.reject(xhr.status);
      }
    };
    xhr.onerror = function () {

      answer.reject('xhr.onerror');
    };
    xhr.send();

    return answer.toPromise();
  }
}
