import {LocalStore} from "../browser.lib.evaluation-tool/util/LocalStore";


export class AppStorage {


  private static readonly _PropertyKey = 'AppStorage.PropertyKey';

  static setPropertyKey( propertyKey: string ) {

    const store = new LocalStore();
    store.setItem( AppStorage._PropertyKey, propertyKey );
  }

  static getPropertyKey(): string|null {

    const store = new LocalStore();
    return store.getItem( AppStorage._PropertyKey );
  }

  static clear() {

    const store = new LocalStore();
    return store.removeItem( AppStorage._PropertyKey );

  }

}
