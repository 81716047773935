import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {ITypeEnum} from "../../model/AppQuestion";


export interface INocoEnumeratedType {

  Id: number;
  Description: string;
  ProductId: number;
  SpreadsheetRow: number|null;
}


export class NocoEnumeratedType extends ValueObject<INocoEnumeratedType> {

  protected onSetValue(value: INocoEnumeratedType | null) {
  }

  constructor( value: INocoEnumeratedType | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }
}


export class NocoEnumeratedTypeSet {

  enumeratedTypes: NocoEnumeratedType[] = [];
  enumeratedTypesById: {[id: number]: NocoEnumeratedType} = {};



  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {

      return 'enumerated_type';
    }
    return 'EnumeratedType';

  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEnumeratedType>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoEnumeratedType>( tableName );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEnumeratedTypeSet> {

    const value = await NocoEnumeratedTypeSet.getValue( proxy );
    return new NocoEnumeratedTypeSet( value );
  }


  public constructor( public value: IListingReponse<INocoEnumeratedType> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoEnumeratedType( rowValue );
      this.enumeratedTypes.push( reference );
      this.enumeratedTypesById[rowValue.Id] = reference;
    }

  }


}
