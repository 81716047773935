import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {Subscription} from "rxjs";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";

/**
 * Generated class for the QuestionTextComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'question-text',
  templateUrl: 'question-text.html',
  styleUrls: ['question-text.scss']
})
export class QuestionTextComponent implements OnInit, OnDestroy {

  public answer: AppAnswer;
  private _questionAnswer: AppAnswer;
  @Input() set questionAnswer(questionAnswer: AppAnswer) {

    this._questionAnswer = questionAnswer;
    this.answer = questionAnswer.answer;
  };

  get questionAnswer() {
    return this._questionAnswer;
  }

  @Input() showQuestionId: boolean = false;

  @Input() showHelpAndLabel: boolean = true;

  private _dependantSubscription: Subscription|null = null;


  _disabled: boolean = false;

  @Input() set disabled(value: boolean) {

    console.log( [this], 'disabled', value );
    this._disabled = value;


    if( this._disabled ) {

      this.questionAnswer.answer.value.value = "";
      // this.value = "0";
    }
  }

  @Input() placeholder: string = "Enter text ...";



  ngOnInit(): void {

    if( this.questionAnswer.question.value.type !== AppQuestion.TYPE_TEXT ) {
      console.error( [this], 'ngOnInit', 'this.questionAnswer.question.value.type !== QuestionReference.TYPE_TEXT' );
    }

    if( this.questionAnswer.answer.dependant ) {

      this._dependantSubscription = this.questionAnswer.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy(this.questionAnswer.question.value.dependant);
        // console.log( 'ngOnInit', dependant, 'isTruthy: ' + isTruthy );
        this.disabled = !isTruthy;
      } );
    }

  }


  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }


  constructor() {
  }


}
