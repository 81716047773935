import {Component, Input, OnInit} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the TestQuestionBooleanComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'page-password-reset',
  templateUrl: 'password-reset.page.html',
  styleUrls: ['password-reset.page.scss']
})
export class PasswordResetPage implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PasswordResetPage' );

  public emailAddress: string;

  onPasswordResetTriggered() {

    this._log.debug( 'onPasswordResetTriggered' );
    AppRouteManifest.HOME.navigate( this.router );
  }

  async init() {

    const emailAddress = await AppRouteManifest.PASSWORD_RESET.getEmailAddress( this._route );

    if( emailAddress ) {
      this.emailAddress = emailAddress;
    }
  }

  ngOnInit(): void {

    this.init();
  }


  constructor( public router: Router,
               private _route: ActivatedRoute,
               public afAuth: AngularFireAuth,
               public dialog: MatDialog,
               public snackBar: MatSnackBar ) {}


}
