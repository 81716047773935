import {Component, Input} from "@angular/core";


@Component({
  selector: 'dialog-footer',
  styleUrls: ['component.dialog-footer.scss'],
  templateUrl: 'component.dialog-footer.html',
})
export class DialogFooterComponent {

}

