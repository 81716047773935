import {Component, Input, OnInit} from '@angular/core';
import {AppAspectDefinition} from "../../../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";
import {AppPageDefinition} from "../../../javascript.lib.mojo-base/model/app/AppPageDefinition";

/**
 * Generated class for the AspectHeaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'aspect-header',
  templateUrl: 'aspect-header.html'
})
export class AspectHeaderComponent implements OnInit {

  // @Input() aspectQuestions: AppAspectDefinition = null;


  progress: number = 0;

  _currentStep: number = null;
  @Input() set currentStep(value: number) {

    if( this._currentStep !== value ) {

      this._currentStep = value;
      this.init();
    }
  };

  _maxStep: number = null;
  @Input() set maxStep(value: number) {

    this._maxStep = value;
    this.init();
  };

  get currentStep(): number {

    return this._currentStep;
  }

  @Input() sectionTitle: string = null;

  headerText: string = '';


  init() {

    if( null == this._currentStep || null == this._maxStep ) {

      this.progress = 0;
      return;
    }

    if( this._currentStep === this._maxStep  ) {

      this.progress = 100;
    } else {

      this.progress = Math.round(100 * (this._currentStep/this._maxStep));
    }

    this.headerText = ` (${this._currentStep} of ${this._maxStep})`;

  }

  ngOnInit(): void {

    this.init();
  }


  constructor() {
  }


}
