import {IScoreRule, ScoreRule} from "./ScoreRule";
import {ValueObject} from "../ValueObject";
import { QuestionKey } from "../QuestionKey";


export class ScoreRuleSet extends ValueObject<IScoreRule[]>{


  rulesByQuestionKey: { [key: QuestionKey]: ScoreRule[] } = {};

  protected onSetValue(value: IScoreRule[] | null) {


    this.rulesByQuestionKey = {};

    for( const policyValue of value ) {

      const policy = new ScoreRule( policyValue );

      for( const questionKey of policyValue.applicableQuestions ) {

        let questionPolicy: ScoreRule[] = this.rulesByQuestionKey[questionKey];
        if( !questionPolicy ) {

          questionPolicy = [];
          this.rulesByQuestionKey[questionKey] = questionPolicy;
        }
        questionPolicy.push( policy );
      }
    }
  }

  constructor( value: IScoreRule[] ) {

    super( value );
    this.value = value;
  }

}
