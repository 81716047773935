




export class Loggable {

  static topLevelFields( source: any ): any {

    if( 'undefined' == typeof source  ) {

      return "undefined";
    }


    if( null == source ) {

      return "NULL";
    }

    if( 'string' == typeof source  ) {

      return source;
    }

    if( 'boolean' == typeof source  ) {

      return source;
    }

    if( 'number' == typeof source  ) {

      return source;
    }

    if( 'object' == typeof source  ) {

      const answer = {};
      for( const key of Object.keys( source ) ) {

        const value = source[key];

        if( null === value ) {

          answer[key] = "NULL";
          continue;
        }

        const typeofValue = typeof value;
        if( 'string' == typeofValue || 'boolean' == typeofValue ||  'number' == typeofValue ) {

          answer[key] = value;
        } else {

          answer[key] = "[Object]";
        }

      }
      return answer;
    }

    return source;

  }

  static anyToLoggable( source: any ): any {

    return Loggable.topLevelFields( source );
  }

}


