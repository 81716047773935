import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {Subscription} from "rxjs";
import {ValueHelper} from "../../../javascript.lib.mojo-base/util/ValueHelper";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";


@Component({
  selector: 'question-integer',
  templateUrl: 'question-integer.html',
  styleUrls: ['question-integer.scss']
})
export class QuestionIntegerComponent implements OnInit, OnDestroy {


  private _log: ILogger = LoggerFactory.build( 'QuestionIntegerComponent' );

  @Input() required: boolean = true;

  @Input() answer: AppAnswer;

  private _dependantSubscription: Subscription|null = null;


  _disabled: boolean = false;

  @Input() set disabled(value: boolean) {

    this._disabled = value;

    if( value ) {

      this.formControl.disable();

    } else {

      this.formControl.enable();
    }
  }

  @Input() public formControl: FormControl = new FormControl('', []);

  @Input() showQuestionId: boolean = false;


  set model( value: string ) {

    const numberValue = ValueHelper.valueToInteger( value )
    this._log.debug( 'set model', value, numberValue );

    if( null === numberValue ) {

      // no-op
      return;
    }

    this.answer.value.value = numberValue;

    // hacky way to get the subscription to trigger ...
    this.answer.value = this.answer.value;

  }

  get model() {

    return this.answer.value.value;
  }


  hasIssues(): boolean {

    if( !this.required ) {

      return false;
    }

    if( this.formControl.disabled ) {

      return false;
    }

    if( null === this.formControl.errors ) {

      return false;
    } else {

      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    }

    return true;
  }

  ngOnInit(): void {

    if( this.answer.question.value.type !== AppQuestion.TYPE_INTEGER ) {

      this._log.error( 'this.questionAnswer.question.value.type !== QuestionReference.TYPE_INTEGER' );
    }

    {

      // console.log( 'this.answer.question.value.type2', this.answer.question.value.type2 );
      const validators = [

        Validators.min(this.answer.question.value.type2.typeInteger.minValue),
        Validators.max(this.answer.question.value.type2.typeInteger.maxValue),
      ];

      if( this.required ) {

        validators.push( Validators.required );
      }
      this.formControl.setValidators( validators );
    }


    const dependantAnswer = this.answer.dependant;

    if( dependantAnswer ) {

      this._dependantSubscription = dependantAnswer.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy( this.answer.question.value.dependant );
        // this._log.debug( `isTruthy: ${isTruthy}`, dependant );
        this.disabled = !isTruthy;
      } );
    }

  }


  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }


  constructor() {
  }


}



