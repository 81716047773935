import {ValueObject} from './ValueObject';
import {Bathroom} from '../hotel/model/accessibility/Bathroom';
import {Bedroom} from '../hotel/model/accessibility/Bedroom';
import {General} from '../hotel/model/accessibility/General';
import {AppAnswer, IAppAnswer} from './AppAnswer';
import {AppQuestion} from './AppQuestion';


export class AnswersReference extends ValueObject<{ [key: string]: IAppAnswer; }> {

  questions: AppQuestion[];


  listMissingQuestions() {

    const questionsByKey  = {};
    this.questions.forEach( ( e ) => { questionsByKey[e.value.key]  = e; });

    Object.keys( this.value ).forEach( ( e ) => { delete questionsByKey[e]; } );

    console.log( [this], 'listMissingQuestions', questionsByKey );

  }

  getPhotosAnswers(): AppAnswer[] {

    const answer = [];

    for ( const question of this.questions ) {


      if( question.isPhoto ) {

        const anAnswer: IAppAnswer = this.value[ question.value.key ];
        if( !anAnswer ) {

          // console.warn( [this], 'getPhotosAnswers', '!anAnswer', 'question.value.key', question.value.key);
        } else {

          const reference = new AppAnswer(  question, anAnswer );
          answer.push( reference );
        }
      }
    }

    return answer;
  }

  onSetValue(value: { [key: string]: IAppAnswer; }|null) {
  }

  constructor( public section: 'bathroom'|'bedroom'|'general',
               value: { [key: string]: IAppAnswer; }|null,
               public hotelKey: string|null = null,
               ) {

    super( value );

    if ( 'bathroom' === section ) {

      this.questions = AppQuestion.buildArray( Bathroom.getAllQuestionValues() );
    } else if ( 'bedroom' === section ) {

      this.questions = AppQuestion.buildArray( Bedroom.getAllQuestionValues() );
    } else if ( 'general' === section ) {

      this.questions = AppQuestion.buildArray( General.getAllQuestionValues() );
    }

  }

}
