import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireAuth} from '@angular/fire/compat/auth';


/**
 * @deprecated use 'FirebaseConnectionService'
 */
export class FirebaseContext {

  /**
   * @deprecated use 'FirebaseConnectionService'
   */
  public afDb: AngularFireDatabase;

  /**
   * @deprecated use 'FirebaseConnectionService'
   */
  public afAuth: AngularFireAuth;

  constructor( afDb: AngularFireDatabase,
               afAuth: AngularFireAuth ) {

    this.afDb = afDb;
    this.afAuth = afAuth;
  }
}
