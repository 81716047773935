

export class PopupBootstrap {

  // private static _messageListenerSetup = false;
  //
  // setupMessageListener() {
  //   if( PopupBootstrap._messageListenerSetup ) {
  //     return;
  //   }
  //
  //   window.addEventListener('message', function(event) {
  //     if(event.data == 'destroyMojoPopup'){
  //
  //       try {
  //
  //         document.body.removeChild(mojoIframe);
  //       } catch ( e ) {
  //
  //         console.warn(e);
  //       }
  //       // let mj = document.getElementById('mojo');
  //       // mj.removeChild(mojoIframe);
  //     }
  //   }, false);
  //
  //   PopupBootstrap._messageListenerSetup = true;
  //
  // }


  private static _buildMojoIframe( srcAttribute: string ) {

    const mojoIframe = document.createElement('iframe');
    mojoIframe.setAttribute("allowtransparency", "true");
    mojoIframe.style.cssText = 'z-index: 2147483647; display: block; background: rgba(0, 0, 0, 0.004); border: 0px none transparent; overflow-x: hidden; overflow-y: auto; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;'
    mojoIframe.id = "mojoiframe";

    mojoIframe.setAttribute("src", srcAttribute);

    document.body.appendChild(mojoIframe);

    const listener = (event: MessageEvent ) => {
      if(event.data == 'destroyMojoPopup'){

        try {

          document.body.removeChild(mojoIframe);

          // tidy-up ...
          window.removeEventListener( 'message', listener );
        } catch ( e ) {

          console.warn(e);
        }
      }
    }

    window.addEventListener('message', listener, false);

  }


  static showMojoPopup(propertyKey: string ) {

    const src = `/assets/browser.app.facilities-popup/version-2/popup.html?propertyKey=${propertyKey}`;
    PopupBootstrap._buildMojoIframe( src );
  }



}
