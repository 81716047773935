


<div fxLayout="column" fxLayoutGap="10px" style="padding-top: 10px;">

  <div fxLayout="row" fxLayoutAlign="start center" >
    <div fxFlex="83" class="general-header-text" fxFlexOffset="10px">
      <strong>
        {{headerText?headerText:'General'}}
      </strong>

    </div>
    <div fxFlex="16">
          <img src="assets/icons/general_hotel_icon.purple.svg"
               width="40"
               height="40"
               style="background: transparent"
          >
    </div>
  </div>

</div>
