import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {Subscription} from "rxjs";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {DialogData, QuestionBooleanDialogComponent} from "./question-boolean-dialog/question-boolean-dialog";
import {MatDialog} from "@angular/material/dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the QuestionBooleanComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'question-boolean',
  templateUrl: 'question-boolean.html',
  styleUrls: ['question-boolean.scss']
})
export class QuestionBooleanComponent implements OnInit, AfterViewInit, OnDestroy {

  private _log = LoggerFactory.build( 'QuestionBooleanComponent' );

  @Input() answer: AppAnswer;


  @Input() trueLabel: string = 'Yes';
  @Input() falseLabel: string = 'No';
  @Input() showQuestionId: boolean = false;

  _disabled: boolean = false;



  private _dependantSubscription: Subscription|null = null;


  @Input() set disabled(value: boolean) {


    const previouslyDisabled = this._disabled;
    this._disabled = value;

    if( this._disabled ) {

      this.answer.value.value = null;

    } else if( previouslyDisabled ) {

      this.answer.value.value = false;
    }

    // hacky way to get the subscription to trigger
    this.answer.value = this.answer.value;
  }


  @Output() change = new EventEmitter<QuestionBooleanComponent>();



  onOpenDialog(): void {

    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionBooleanDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionBooleanDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {

      this._log.debug('dialog was closed');
    });
  }


  ngOnInit(): void {

    this._log.debug( 'ngOnInit', 'this.answer', this.answer );
    this._log.debug( 'ngOnInit', 'this.answer.dependant', this.answer.dependant );


    if( this.answer.question.value.type !== AppQuestion.TYPE_BOOLEAN ) {

      this._log.error( 'ngOnInit', 'this.questionAnswer.question.value.type !== QuestionReference.TYPE_BOOLEAN' );
    }


    if( this.answer.dependant ) {

      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        // this._log.debug( 'ngOnInit', dependant, 'isTruthy: ' + isTruthy );
        this.disabled = !isTruthy;
      } );
    }

    // this.onOpenDialog();
  }


  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }

  ngAfterViewInit(): void {
  }

  constructor( public dialog: MatDialog ) {
  }


}
