import {Component, OnInit} from '@angular/core';
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {IScore} from "../javascript.lib.mojo-base/hotel/model/accessibility/Score";

/**
 * Generated class for the CompletedPage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
@Component({
  selector: 'page-completed',
  templateUrl: 'completed.html',
  styleUrls: ['completed.scss']
})
export class CompletedPage implements OnInit {

  public score: IScore = {
    numerator: 0,
    denominator: 100,
  };


  showCongratulationsText = false;



  ngOnInit(): void {


    this.score = this.sessionContext.propertyContext.getScore();

    this.sessionContext.propertyContext.completedPageHasBeenShown = true;
  }


  onHome() {

    AppRouteManifest.HOME.navigate( this.router )
  }

  constructor( public router: Router,
               public applicationContext: PwaApplicationContextProvider,
               public sessionContext: PwaSessionContextProvider,
  ) {

    this.showCongratulationsText = sessionContext.propertyContext.showCongratulationsText;
    sessionContext.propertyContext.showCongratulationsText = false;
  }

}
