import {Component, EventEmitter, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {BaseSessionContext, SessionContextState} from "../service.session-context/BaseSessionContext";
import {AppRouteManifest} from "../../app/AppRouteManifest";
import {Router} from "@angular/router";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";

@Component({
  selector: 'app-session-checker',
  templateUrl: './session-checker.component.html'
})
export class SessionCheckerComponent implements OnInit, OnDestroy {

  private _log = LoggerFactory.build( 'SessionCheckerComponent' );

  @Output() sessionIsReady = new EventEmitter<boolean>();

  private _subscription: Subscription;

  public userIsReady = false;
  public userIsLoggedOut = false;

  public intermediaStatus = "checking user login ...";


  onPasswordResetRequested( emailAddress: string ) {

    AppRouteManifest.PASSWORD_RESET.navigateToPage( this.router, emailAddress );
  }



  ngOnInit() {

    this._subscription = this.sessionContext.stateSubject.subscribe( (next) => {

      this.ngZone.run( () => {

        this._log.debug( 'next', next );

        this.userIsReady = false;
        this.userIsLoggedOut = false;

        if( next === SessionContextState.UserIsAuthenticating ) {

          this.intermediaStatus = "Checking user login ...";
        } else if( next === SessionContextState.UserDataLoading ) {

          this.intermediaStatus = "Loading user data ...";
        } else if( next === SessionContextState.PropertyDataLoading ) {

          this.intermediaStatus = "Loading property data ...";
        } else if( next === SessionContextState.UserIsReady ) {

          this.userIsReady = true;
          this.intermediaStatus = "User is ready :-)";
          this.sessionIsReady.emit(true);
        } else if( next === SessionContextState.LoggedOut ) {

          this.userIsLoggedOut = true;
          this.intermediaStatus = "User is logged out :-(";
        }

      });
    });

  }

  ngOnDestroy(): void {

    this._subscription.unsubscribe();
  }

  constructor( public router: Router,
               public sessionContext: BaseSessionContext,
               private ngZone: NgZone ) {
  }

}
