import {IColumnDefinition} from "../../model.popup/ColumnDefinition";
import {IAppPageDefinition} from "../../javascript.lib.mojo-base/model/app/AppPageDefinition";


  export class FacilitySummaryStructure {



    static readonly summarySections: IColumnDefinition[] = [
      {
        title: 'PARKING & ENTRANCE',
        sections: [
          {
            title: 'Parking and Entrance ',
            questionKeys: [
              'Bkp11', // Is there parking available for visitors/employees?
              'BkqAn', // Are there designated wheelchair accessible parking spaces available?
              'BkqAx', // How many wheelchair parking spaces are provided?
              'BkqA7', // Is there an accessible entrance leading from the indoor car park to the main building?
              'BkqBF', // From the car park, does the building entrance have an intercom or two-way communication system?		Entrance intercom system
              'BkqBP', // From the car park, is the entrance to the building through two doors in a series?
              'BkqBZ', // What is the distance between the two doors in the series? (ie. the passage between the two doors).                                                        (In cm)
              'BkqBj', // Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)
              'BkqB3', // Is there step free access into the main building entrance? This could be level, ramp or lift access.
              'BkqCB', // How many steps are there up to the main entrance?
              'BkqCL', // What type of door is there into the main entrance?
              'BkqCf', // What is the main entrance door width measurement? (In cm)
              'BkqCp', // Is the main entrance door made of full clear glass panels?
              'BkqCz', // Does the glass door/doors strongly contrast visually with the background and/or have color contrasted strips or markings so that it is easily identifiable ?
              'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
              'BkqCV', // Is there an alternative accessible entrance?
              'CjAUx', // Is there step free access into the alternative accessible entrance? This could be levels, access or lift access.
              'CjEZ3', // What type of door do you have into the alternative accessible entrance?
              'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
              'BkqBt', // Is the alternative accessible entrance clearly sign posted?
              'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
            ],
          },
          {
            title: 'Reception area',
            questionKeys: [
              'Bkve7', // Is there a lower counter/desk in the reception area?
              'BkvfF', // Is there an assistive listening system at the reception area? (eg. induction loop)
              'C9KCH', // Is there visitor seating provided at the reception area?
              'BkvfP', // Is there directional and informational signage in the reception area?
              'Bkvfj', // Are guide dogs or service dogs welcome in the premises?
              'BkvfZ', // Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)
            ],
          },
          {
            title: 'Safety Procedures',
            questionKeys: [
              'Bk3dP', // Does the fire alarm system have visible flashing lights?
              'Bk3dZ', // Is there an emergency evacuation procedure available for people with accessibility needs?
              'Bk3dj', // Is evacuation information available in both visual and audible formats?
              'Bk3dt', // Is there an emergency evacuation chair in the building?
              'Bk3d3', // Do staff receive disability awareness training?
              'Bk3eB', // Does the building have an accessibility certification, inclusion or universal design standards accreditation?
              'DHYrn', // What type of accessibility certification, inclusion or universal design standards accreditation does the building have ?
            ],
          },
        ],
      },
      {
        title: 'FACILITIES & EVENT SPACES',
        sections: [
          {
            title: 'General Facilities',
            questionKeys: [
              'BkyVv', // Are baby changing facilities available on the premises?
              'BkyV5', // Is there a baby feeding room on the premises?
              'BkyWD', // Are there cloakrooms available on the premises?
              'BkyWN', // Is there a cloakroom accessible for a wheelchair user?
              'BkyWX', // Are there storage lockers available on the premises?
              'BkyWh', // Are there storage lockers available at varying heights?
              'BkyWr', // Are there gym facilities on the premises?
              'BkyW1', // Is there step free access into the gym? This could be level, ramp or lift access.
            ]
          },
          {
            title: 'Routes & Corridors',
            questionKeys: [
              'Bky5D', // Are main routes clearly defined to assist in orientation for people with visual impairment? For example, by using colour contrast between wall and floor surfaces.
              'Bky5N', // Is there suitable and sufficient lighting providing good illumination throughout the building?
              'Bky5X', // Please take 1 photo of a typical main route/corridor (in landscape mode)
            ]
          },
          {
            title: 'Food Services',
            questionKeys: [
              'BkzJT', // Is there a cafeteria on the premises?
              'BkzJd', // Does the cafeteria have step free access? This could be level, ramp or lift access.
              'BkzJn', // Are the tables and chairs movable or fixed to the floor?
              'BkzJx', // Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?
              'BkzJ7', // Please take 3 photos of the cafeteria from different angles (in landscape mode)
              'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, vending machines etc.)
              'Cmgc1', // Is there step free access into some of the breakout areas? This could be level, ramp or lift access.
              'CmgpB', // Are appliances placed at a height reachable from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
              'CmgyZ', // Can the sink be reached from a seated position in the most accessible breakout area?
              'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
              'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
              'Cmie_', // Are appliances visually contrasting with countertops in the most accessible breakout area?
            ]
          },
          {
            title: 'Stairs & Elevator',
            questionKeys: [
              'BkzKF', // Are there flights of stairs within the building?
              'BkzKP', // Do the stairs have handrails that colour contrast with the surrounding walls?
              'BkzeF', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
              'Bkz__', // Is there a lift/elevator in the building?
              'Bk0AJ', // Is there a step free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp or lift access., //
              'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
              'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
              'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
              'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
              'Bk0BF', // Is there braille, raised numbers or appropriate symbols on the most accessible lift/elevator buttons?
              'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
              'Bk0BP', // Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)
            ]
          },
          {
            title: 'Event Spaces',
            questionKeys: [
              'Bkzpp', // Is there an auditorium in the building?
              'Bkzpz', // How many wheelchair accessible seating spaces are provided?
              'Bkzp9', // Is there an assistive listening system provided? (eg. audio induction loop)
              'BkzqH', // Is there a text display or subtitles option on presentation screens?
              'Bkzqb', // Please take 2 photos of the auditorium from different angles (in landscape mode)
            ]
          },
        ]
      },
      {
        title: 'WORK STATIONS & MEETING/TRAINING ROOMS',
        sections: [
          {
            title: 'Work Stations',
            questionKeys: [
              'Bk2BD', // Are there different floor surfaces around open-plan areas and workstations that can aid orientation for people with visual impairments?
              'Bk2BN', // Does the office furniture colour contrast with their surrounding surfaces such as the walls and floor?  (Couches, chairs, tables etc.)
              'Bk2Br', // Are some office desks height adjustable?
              'Bk2BX', // Are some office desk electrical sockets at a reachable height for someone in a seated position?
              'Bk2Bh', // Are some office desk electrical sockets a different colour to the surrounding background wall on which they are mounted?
              'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)
              'Bk2B_', // Do some print stations have adequate space for a wheelchair user?
            ]
          },
          {
            title: 'Meeting Rooms',
            questionKeys: [
              'Bk0fj', // Do you have meeting rooms on the premises?
              'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
              'Bk0f3', // Is there braille, raised lettering or appropriate symbols on the room signage?
              'Bk0gB', // What is the door width measurement of the most accessible meeting room? (In cm)
              'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?
              'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
              'Bk0gf', // Do you have an assistive listening device available in any of the meeting rooms? (eg. Audio induction loop)
              'Cmlsx', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?
              'Bk0gp', // Please take 1 photo of the most accessible meeting room (in landscape mode)
            ]
          },
          {
            title: 'Training Rooms',
            questionKeys: [
              'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
              'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
              'Bk1db', // Is there braille, raised lettering or appropriate symbols on the room signage?
              'Bk1dl', // What is the width of the most accessible training room door? (In cm)
              'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?
              'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
              'Bk1eD', // Do you have an assistive listening device available in any of the training rooms? (eg. Audio induction loop)
              'Bk1eN', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?
              'Bk1eX', // Please take 1 photo of the most accessible training room (in landscape mode)
            ]
          },
        ]
      },
      {
        title: 'ACCESSIBLE BATHROOMS',
        sections: [
          {
            title: 'Visitors Bathroom',
            questionKeys: [
              'Bkvf3', // Does the bathroom have braille, raised lettering or appropriate symbols to identify the bathroom type?
              'Cjlgx', // What type of handles are on the visitor bathroom entrance door?
              'D1sTp', // What type of door locks are on the accessible bathroom door?
              'Cjp6z', // What is the width of the visitors bathroom door? (In cm)
              'Cjp_z', // Can you see yourself in the mirror from a seated position in the visitors bathroom?
              'Cjwul', // What type of tap handles are on the sink in the visitors bathroom?
              'CjqNt', // Is there a sink at a lower height in the visitors bathroom?
              'CjqVr', // What is the height of the lower sink in the visitors bathroom?
            ]
          },
          {
            title: 'Employees Bathroom',
            questionKeys: [
              'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
              'Bk2fD', // How many wheelchair accessible bathrooms are on the premises?
              'Bk2fN', // Do some bathroom doors have braille, raised lettering or appropriate symbols to identify the bathroom type?
              'Bk2fX', // Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?
              'Bk2fr', // What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)
              'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
              'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
              'Bk2f_', // In the most wheelchair accessible bathroom, what is the width of the door?   (In cm)
              'Bk2gJ', // In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?
              'Bk2gT', // In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)
              'Bk2gn', // In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet?
              'Bk2gx', // In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?
              'Bk2g7', // In the most wheelchair accesible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?
              'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
              'Bk2hP', // In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?
              'Bk2hZ', // Is there a roll in shower on the premises?
              'Bk2hj', // Please take 2 photos of the most  accessible wheelchair bathroom from different angles (in landscape mode)
            ]
          },
        ]
      },
    ];



  static readonly moduleSummaryStructure: IAppPageDefinition[] = [
    {
      title: 'PARKING & ENTRANCE',
      questionKeys: [],
      subSections: [
        {
          title: 'Parking and Entrance',
          questionKeys: [
            'Bkp11', // Is there parking available for visitors/employees?
            'MpjwN', // Are parking charges in place?
            'Mpj2n', // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
            'BkqAn', // Are there designated wheelchair accessible parking spaces available?
            'BkqAx', // How many wheelchair accessible parking spaces are provided?
            'BkqA7', // Is there an accessible entrance from the indoor car park to the main building?
            'ajdl0', // Is there a step-free and unobstructed route from the designated accessible parking space(s) to the most accessible building entrance?
            'BkqBF', // From the car park, does the building entrance have an intercom or two-way communication system?
            'BkqBP', // From the car park, is the entrance to the building through two doors in a series?
            'BkqBZ', // In centimetres, what is the distance between the two doors in the series? (ie. the clear space between the two doors).
            'BkqBj', // Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)
            'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
            'BkqCB', // How many steps are there up to the main entrance?
            'ajd8e', // Are handrails provided at steps?
            'BkqCL', // What type of door is there into the main entrance?
            'ajeoO', // How do the automatic doors open?
            'ajhxA', // Do automatic doors that swing open have audible warnings?
            'aji50', // What type of door handles are on the entrance door?
            'ajkEg', // Can the entrance door be opened by someone with limited strength?
            'BkqCf', // What is the main entrance door width measurement? (In cm)
            'BkqCp', // Is the main entrance door made of full clear glass panels?
            'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
            'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
            'BkqCV', // Is there an alternative accessible entrance?
            'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
            'CjEZ3', // What type of door is there into the alternative accessible entrance?
            'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
            'BkqBt', // Is the alternative accessible entrance clearly signposted?
            'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
      ],
          subSections: [],
        },
        {
          title: 'Reception Area',
          questionKeys: [
            'Bkve7', // Is there a lower counter/desk in the reception area?
            'BkvfF', // Are assistive listening systems provided at the reception area? (eg. Audio Induction Loop)
            'C9KCH', // Is there visitor seating provided at the reception area?
            'BkvfP', // Is there directional and informational signage in the reception area?
            'Mpj2x', // Are tactile maps available in the reception/lobby area?
            'Bkvfj', // Are guide/service dogs welcome on the premises?
            'BkvfZ', // Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)

          ],
          subSections: []
        },
        {
          title: 'Safety Procedures',
          questionKeys: [
            'Bk3dP', //Does the fire alarm system have visible flashing lights?
            'Bk3dj', //Is emergency evacuation information available in both visual and audible formats?
            'Bk3dZ', //Is there an emergency evacuation procedure available for people with accessibility needs?
            'Bk3dt', //Is there an emergency evacuation chair in the building?
            'TgYWG', //Are all emergency exits clearly signposted?
            'XQATW', //In the case of fire or an emergency, are areas of refuge provided in your building?
            'Bk3d3', //Do staff receive disability awareness training?
            'Bk3eB', //Does the building have an accessibility compliance certification, inclusion or universal design standards, building code accreditation?
            'DHYrn', //What type of accessibility certification, inclusion or universal design standards accreditation does the building have?
          ],
          subSections: []
        },
      ],
    },
    {
      title: 'FACILITIES & EVENT SPACES',
      questionKeys: [],
      subSections: [
        {
          title: 'General Facilities',
          questionKeys: [
            'BkyVv', //Are baby changing facilities available on the premises?
            'BkyV5', //Is there a baby feeding room on the premises?
            'BkyWD', //Are there cloakrooms available on the premises?
            'BkyWN', //Are some coat hooks reacheable from a seated position?
            'BkyWX', //Are there storage lockers available on the premises?
            'BkyWh', //Are there storage lockers available at different heights?
            'Mpj4z', //Are there designated quiet spaces or rooms in the building?
            'BkyWr', //Are there gym facilities on the premises?
            'BkyW1', //Is there step-free access into the gym? This could be level, ramp, or lift access.
            'Mpj4L', //Are there vending machines in the building?
            'Mpj4V', //Is the control panel on the vending machine operable from a seated position?
          ],
          subSections: []
        },
        {
          title: 'Routes & Corridors',
          questionKeys: [
            'Bky5D', // Are all main routes clearly defined to assist the orientation of people with visual impairments? For example, by using colour contrast between wall and floor surfaces.
            'Bky5N', // Is there suitable and sufficient lighting providing good illumination throughout the building?
            'Bky5X', // Please take 1 photo of a typical main route/corridor (in landscape mode)
          ],
          subSections: []
        },
        {
          title: 'Food Services',
          questionKeys: [
            'BkzJT', // Is there a cafeteria on the premises?
            'BkzJd', // Is there step-free access into the cafeteria? This could be level, ramp, or lift access.
            'Mpj27', // What type of cafeteria service is provided?
            'Mpj3F', // What is the height of the food counters from the floor to the top of the counter?
            'Mpj33', // Are there food display cabinets or shelves where food and drink products are on display for purchase?
            'Mpj4B', // Are the food and drink products displayed vertically on shelves and in cabinets?
            'ajlli', // Are menus available in print?
            'ajluw', // Are menus available in larger print?
            'ajl5E', // Are menus available in digital format?
            'ajmCw', // Are menus available in braille?
            'BkzJn', // Are the tables and chairs movable or fixed to the floor?
            'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
            'BkzJx', // Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?
            'BkzJw', // Please take 3 photos of the cafeteria from different angles (in landscape mode)
            'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
            'Cmgc1', // Is there step-free access into some of the breakout areas? This could be level, ramp, or lift access.
            'CmgpB', // Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
            'CmgyZ', // Can the sink be reached from a seated position in the most accessible breakout area?
            'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
            'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
            'Cmie_', // Do kitchen appliances colour contrast with countertops in the most accessible breakout area?
            'BkzJ7', // Please take 3 photos of the most accessible breakout area from different angles (in landscape mode)

          ],
          subSections: []
        },
        {
          title: 'Stairs & Elevator',
          questionKeys: [
            'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
            'BkzKF', // Are there flights of stairs within the building?
            'BkzKP', // Do the stairs have handrails that colour contrast with the surrounding walls?
            'ajmM6', // Are internal stairs well lit?
            'BkzeF', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
            'Bkz__', // Is there a lift/elevator in the building?
            'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
            'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
            'ajm9M', // Do the lift/elevator call buttons have visual indicators?
            'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
            'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
            'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
            'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
            'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
            'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
            'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
            'ajnX6', // Does the emergency alarm in the lift/elevator have braille or raised letters?
            'XP_vG', // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
            'TgYOI', // Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?
            'Bk0BP', // Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)
          ],
          subSections: []
        },
        {
          title: 'Event Spaces',
          questionKeys: [
            'Bkzpp', // Is there an auditorium in the building?
            'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
            'Mpj4f', // Is there a clear line of sight from the wheelchair designated space to the presenter in the auditorium?
            'Bkzp9', // Are assistive listening systems provided in the auditorium? (eg. Audio Induction Loop)
            'BkzqH', // Is there a text display or subtitles option on presentation screens provided in the auditorium?
            'Mpj4p', // Is there space for a guide/service dog in the auditorium?
            'Bkzqb', // Please take 2 photos of the auditorium from different angles (in landscape mode)
          ],
          subSections: []
        },
        {
          title: 'Bar Area',
          questionKeys: [
            'QZ_lT', // Is there a bar or lounge that serves beverages in the office?
            'QZ_ob', // Is there step-free access into the bar/lounge? This could be level, ramp, or lift access.
            'QZ_pD', // Is any part of the bar counter set at a lower height?
            'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
            'QZ_qx', // Are some lower tables provided where wheelchair users or persons of short stature can rest their plates and drinks?
            'QZ_rZ', // Is there at least 91cm of clear aisle space around some tables and chairs?
            'QZ_sf', // Please take 2 photo of the most accessible bar or lounge area (in landscape mode)
          ],
          subSections: []
        },
      ]
    },
    {
      title: 'WORK STATIONS & MEETING/TRAINING ROOMS',
      questionKeys: [],
      subSections: [
        {
          title: 'Work Stations',
          questionKeys: [
            'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
            'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
            'Bk2Br', // Are some office desks height adjustable?
            'Bk2BX', // Are some switches and sockets reachable from a seated position?
            'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
            'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
            'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)
          ],
          subSections: []
        },
        {
          title: 'Meeting Rooms',
          questionKeys: [
            'Bk0fj', // Are there meeting rooms on the premises?
            'Bk0ft', // How many of the meeting rooms are wheelchair accessible?
            'Bk0f3', // Is there braille, raised lettering, or appropriate symbols on the meeting room signage?
            'Bk0gB', // What is the door width measurement of the most accessible meeting room? (In cm)
            'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?
            'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
            'eau2O', // Is there at least 91cm of clear aisle space around some tables and chairs?
            'Bk0gf', // Are assistive listening systems provided in any of the meeting rooms? (eg. Audio Induction Loop)
            'Cmlsx', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?
            'Bk0gp', // Please take 1 photo of the most accessible meeting room (in landscape mode)
          ],
          subSections: []
        },
        {
          title: 'Training Rooms',
          questionKeys: [
            'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
            'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
            'Bk1db', // Is there braille, raised lettering, or appropriate symbols on the training room signage?
            'Bk1dl', // What is the width of the most accessible training room door? (In cm)
            'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?
            'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
            'eau3K', // Is there at least 91cm of clear aisle space around some tables and chairs?
            'Bk1eD', // Are assistive listening systems provided in any of the training rooms? (eg. Audio Induction Loop)
            'Bk1eN', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?
            'Bk1eX', // Please take 1 photo of the most accessible training room (in landscape mode)
          ],
          subSections: []
        },
      ]
    },
    {
      title: 'Bathrooms/Restrooms',
      questionKeys: [],
      subSections: [
        {
          title: 'General Bathrooms',
          questionKeys: [
            'Bkvf3', // Do the general bathrooms have braille, raised lettering, or appropriate symbols to identify the bathroom type?
            'Cjp6z', // What is the width of the general bathroom entrance doors? (In cm)
            'Cjlgx', // What type of handles are on the general bathroom entrance doors?
            'Cjp_z', // Can you see yourself in the mirror from a seated position in the general bathrooms?
            'D1sTp', // What type of door locks are on the general bathroom doors?
            'Cjwul', // What type of tap handles are on the sink in the general bathrooms?
            'CjqNt', // Is there a sink at a lower height in the general bathrooms?
            'CjqVr', // What is the height of the lower sink in the general bathrooms?
          ],
          subSections: []
        },
        {
          title: 'Accessible Bathrooms',
          questionKeys: [
            'Bk2e5', // "Is there a wheelchair accessible bathroom on the premises for employees?"
            'Bk2fD', // How many wheelchair accessible bathrooms are on the premises?
            'Bk2fN', // Do the accessible bathroom doors have braille, raised lettering, or appropriate symbols to identify the bathroom type?
            'Bk2fX', // Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?
            'Bk2fr', // What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)
            'Bk2f_', // In the most wheelchair accessible bathroom, what is the width of the door? (In cm)
            'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
            'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
            'Bk2gT', // In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)
            'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
            'Bk2gn', // In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet?
            'ajzYg', // In the most accessible bathroom, is the toilet flush handle located beside a wide clear floor space?
            'Bk2gx', // In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?
            'Bk2g7', // In the most wheelchair accessible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?
            'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
            'eauye', // Is there a sink at a lower height in the accessible bathroom?
            'eau1c', // What is the height of the lower sink in the accessible bathrooms?
            'Bk2gJ', // In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?
            'Bk2hP', // In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?
            'Bk2hZ', // Is there a roll-in shower on the premises?
            'Bk2hj', // Please take 2 photos of the most  accessible bathroom/restroom from different angles (in landscape mode)
          ],
          subSections: []
        },
      ]
    },
  ];
}
