import {ValueObject} from "./ValueObject";
import {CodeHelper} from "../util/CodeHelper";



export type EnumeratedValueAsString = string;


export interface IEnumeratedConstant {

  codeAsNumber: number;
  codeAsString: EnumeratedValueAsString;
  label: string;
  popupLabel: string | null; // when null, will not be rendered in the popup

  canonicalId?: string;
  nocoId?: number;
}


export class EnumeratedConstantReference extends ValueObject<IEnumeratedConstant> {

  static readonly yes: IEnumeratedConstant = {

    codeAsNumber: 0x1,
    codeAsString: '1',
    label: 'Yes',
    popupLabel: 'Yes',
  };

  static readonly no: IEnumeratedConstant = {

    codeAsNumber: 0x0,
    codeAsString: '0',
    label: 'No',
    popupLabel: null, // suppress
  };


  static readonly notApplicable: IEnumeratedConstant = {

    codeAsNumber: 0x6e2f61,
    codeAsString: 'n/a',
    label: 'Not applicable',
    popupLabel: null, // suppress
  };


  static addEnumsFromObject( obj: any, enums:IEnumeratedConstant[] ) {

    for( const key of Object.keys( obj )) {

      const aEnum = obj[key] as IEnumeratedConstant;
      if( aEnum.codeAsString ) {

        enums.push( aEnum );
      }
    }
  }

  public static printCode( code: string ) {


    const numberCode = CodeHelper.asciiToNumberCode( code ).toString(16);

    console.log( `'${code}' -> 0x${numberCode}`)

  }

  public static printEnum( enumeratedType: string, enumeratedTypeId: number, enumValues: any) {

    console.log( `${enumeratedType} ...`);

    for( const key of Object.keys(enumValues) ) {

      const code: IEnumeratedConstant = enumValues[key];

      const numberCode = CodeHelper.asciiToNumberCode( code.codeAsString );
      const hexNumberCode = numberCode.toString(16);

      if( numberCode != code.codeAsNumber ) {

        if( code !== EnumeratedConstantReference.yes && code !== EnumeratedConstantReference.no ) {

          console.log( `ERROR: numberCode != code.codeAsNumber; code.codeAsString: '${code.codeAsString}'; hexNumberCode: 0x${hexNumberCode}\`` );
        }

      } else {

        console.log( `'${code.codeAsString}' -> 0x${hexNumberCode}`);
      }

    }
  }


  public static numberCodeToAscii(code: number): string {

    if( code === 0) {

      return '0';
    }

    if( code === 1) {

      return '1';
    }

    let answer = '';
    while (0 < code) {

      const asciiCode = code & 0xFF;

      { // sanity check

        const slash = 0x2f;
        const A = 0x41;
        const Z = 0x5a;
        const a = 0x61;
        const z = 0x7a;
        if( asciiCode < A && asciiCode != slash ) {

          return '' + code;
        }
        if( asciiCode > Z && asciiCode < a ) {

          return '' + code;
        }
        if( asciiCode > z ) {

          return '' + code;
        }

      }

      // console.log( asciiCode );
      answer = String.fromCharCode(asciiCode) + answer;

      code >>= 8;
    }

    return answer;
  }

  // lib.json_broker.fault.stringFaultCodeToNumber( '!fnd' );
  public static asciiCodeToNumber(code: string): number {

    if( code === '0') {

      return 0;
    }

    if( code === '1') {

      return 1;
    }

    let answer = 0;

    for (let index = 0; index < code.length; index++) {

      answer <<= 8;
      answer |= code.charCodeAt(index);
    }

    return answer;
  }


  protected onSetValue(value: IEnumeratedConstant | null) {
  }

}

export namespace EnumeratedConstants {


  /**
   * @deprecated use EnumeratedConstantReference.yes
   */
  export const yes = EnumeratedConstantReference.yes;

  /**
   * @deprecated use EnumeratedConstantReference.no
   */
  export const no = EnumeratedConstantReference.no;

  /**
   * @deprecated use EnumeratedConstantReference.no
   */
  export const notApplicable = EnumeratedConstantReference.notApplicable;

  export namespace AlternativeAccessibleEntranceOptions {

    export const yes: IEnumeratedConstant = EnumeratedConstantReference.yes;
    export const no: IEnumeratedConstant = EnumeratedConstantReference.no;
    export const notApplicable: IEnumeratedConstant = EnumeratedConstantReference.notApplicable;

    export const all: IEnumeratedConstant[] = [
      no,
      yes,
      notApplicable
    ];
  }


  //
  // export namespace DiningBarAccessibility {
  //
  //   export const no: IEnumeratedConstant = EnumeratedConstantReference.no;
  //   export const yes: IEnumeratedConstant = EnumeratedConstantReference.yes;
  //   export const notApplicable: IEnumeratedConstant = EnumeratedConstantReference.notApplicable;
  //
  //   export const all: IEnumeratedConstant[] = [
  //     no,
  //     yes,
  //     notApplicable
  //   ];
  // }
  //
  export namespace BarAccessibility {

    export const no: IEnumeratedConstant = EnumeratedConstantReference.no;
    export const yes: IEnumeratedConstant = EnumeratedConstantReference.yes;
    export const notApplicable: IEnumeratedConstant = EnumeratedConstantReference.notApplicable;

    export const all: IEnumeratedConstant[] = [
      no,
      yes,
      notApplicable
    ];
  }


  export namespace DiningAccessibility {

    export const no: IEnumeratedConstant = EnumeratedConstantReference.no;
    export const yes: IEnumeratedConstant = EnumeratedConstantReference.yes;
    export const notApplicable: IEnumeratedConstant = EnumeratedConstantReference.notApplicable;

    export const all: IEnumeratedConstant[] = [
      no,
      yes,
      notApplicable
    ];
  }


  export namespace EntranceDoorTypes {


    export const automatic: IEnumeratedConstant = {

      codeAsNumber: 0x6175746f,
      codeAsString: 'auto',
      label: 'Automatic',
      popupLabel: 'Automatic door at entrance',
    };

    export const manual: IEnumeratedConstant = {

      codeAsNumber: 0x6d616e75,
      codeAsString: 'manu',
      label: 'Manual',
      popupLabel: 'Manual door at entrance',
    };

    export const all: IEnumeratedConstant[] = [
      manual,
      automatic,
    ];
  }


  export namespace ShowerSeatType {

    export const none: IEnumeratedConstant = {

      codeAsNumber: 0x0,
      codeAsString: '0',
      label: 'No seat',
      popupLabel: null,
    };

    export const fixed: IEnumeratedConstant = {

      codeAsNumber: 0x66697864,
      codeAsString: 'fixd',
      label: 'Fixed',
      popupLabel: 'Fixed shower seat provided',
    };

    export const portable: IEnumeratedConstant = {

      codeAsNumber: 0x706f7274,
      codeAsString: 'port',
      label: 'Portable',
      popupLabel: 'Portable shower seat provided',
    };

    export const all: IEnumeratedConstant[] = [
      none,
      fixed,
      portable
    ];
  }


  export namespace LiftAccessibility {

    export const no: IEnumeratedConstant = EnumeratedConstantReference.no;
    export const yes: IEnumeratedConstant = EnumeratedConstantReference.yes;
    export const notApplicable: IEnumeratedConstant = EnumeratedConstantReference.notApplicable;

    export const all: IEnumeratedConstant[] = [
      no,
      yes,
      notApplicable
    ];
  }


  export namespace StandardsAccreditation {

    export const none: IEnumeratedConstant = {

      codeAsNumber: 0x0,
      codeAsString: 'none',
      label: 'None',
      popupLabel: null,
    };

    export const ada: IEnumeratedConstant = {

      codeAsNumber: 0x616461,
      codeAsString: 'ada',
      label: 'ADA',
      popupLabel: 'Has ADA accreditation',
    };

    export const dac: IEnumeratedConstant = {

      codeAsNumber: 0x646163,
      codeAsString: 'dac',
      label: 'Disability Access Cert',
      popupLabel: 'Has Disability Access Cert',
    };

    export const iso: IEnumeratedConstant = {

      codeAsNumber: 0x69736f,
      codeAsString: 'iso',
      label: 'ISO Cert',
      popupLabel: 'Has ISO Cert',
    };

    export const other: IEnumeratedConstant = {

      codeAsNumber: 0x6f746872,
      codeAsString: 'othr',
      label: 'Other',
      popupLabel: 'Other Accessibility Design Cert',
    };

    export const all: IEnumeratedConstant[] = [
      none,
      ada,
      iso,
      dac,
      other
    ];

  }


  export namespace BedroomFloorSurface {

    export const carpet: IEnumeratedConstant = {

      codeAsNumber: 0x63727074,
      codeAsString: 'crpt',
      label: 'Carpet',
      popupLabel: 'Carpet floor surface',
    };

    export const wood: IEnumeratedConstant = {

      codeAsNumber: 0x616461,
      codeAsString: 'wood',
      label: 'Wood',
      popupLabel: 'Wood floor surface',
    };

    export const tile: IEnumeratedConstant = {

      codeAsNumber: 0x646163,
      codeAsString: 'tile',
      label: 'Tile',
      popupLabel: 'Tile floor surface',
    };

    export const other: IEnumeratedConstant = {

      codeAsNumber: 0x69736f,
      codeAsString: 'othr',
      label: 'Other',
      popupLabel: 'Other floor surface',
    };

    export const all: IEnumeratedConstant[] = [
      carpet,
      wood,
      tile,
      other,
    ];

  }



}
