


export class SectionIdentifiers {

  public static readonly ACCESSIBLE_BATHROOMS_ID = 'accessible-bathrooms';
  public static readonly FACILITIES_AND_EVENT_SPACES_ID = "facilities-and-event-spaces";
  public static readonly EMPLOYEE_BATHROOMS_ID = "employee-bathrooms";
  public static readonly PARKING_AND_ENTRANCE_ID = "parking-and-entrance";
  public static readonly SAFETY_PROCEDURES_ID = 'safety-procedures';
  public static readonly WORK_STATIONS_AND_ROOMS_ID = 'rooms-and-work-stations';

}
