// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Levis: Facilities',
  filename: 'environment.facilities-76698673.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    databaseURL: "https://facilities-76698673-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-76698673",
    storageBucket: "facilities-76698673.appspot.com",

    apiKey: "AIzaSyCRHecLK18hJkzwIqT-6_hokT3orItGxP0",
    authDomain: "facilities-76698673.firebaseapp.com",
    messagingSenderId: "211958067518",
    appId: "1:211958067518:web:fed9a95f5426c066cbbc6f"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-76698673.cloudfunctions.net',

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,

  },




};
