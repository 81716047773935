import {FirebaseValueObject} from "./FirebaseValueObject";


export interface IFirebaseUpdate {

  when: number,
  who: string,
}

export interface IFirebaseMetaData {

  updates: IFirebaseUpdate[];
  deleted?: IFirebaseUpdate;
}

export class FirebaseMetaData {

  public static buildMeta(): IFirebaseMetaData {

    return {
      updates: []
    };
  }



}
