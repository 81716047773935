import {LoggerFactory} from "../log/LoggerFactory";


export class Command<T> {


  private static log = LoggerFactory.build( 'Command' );


  private promise: Promise<T> = null;
  private resolveOfPromise: (value?: T | PromiseLike<T>) => void;
  private rejectOfPromise: (reason?: any) => void;



  public static watch<T>( promise: Promise<T>, log = Command.log ) {

    promise.then( (value: T) => {

      // no-op
    }).catch( (error: any) => {

      log.error( error );
    });
  }


  reject( reason: any ) {

    this.rejectOfPromise( reason );
  }

  resolve( value: T) {

    this.resolveOfPromise( value );
  }

  toPromise() {

    return this.promise;
  }

  constructor() {

    this.promise = new Promise<T>( (resolve, reject) => {

      this.resolveOfPromise = resolve;
      this.rejectOfPromise = reject;
    });
  }

}
