
import {LogEvent} from "./LogEvent";
import {Base64} from "../util/Base64";
import {Loggable} from "./Loggable";

export abstract class BaseLogger {

  public static readonly CORRELATION_ID: string = Base64.encode( new Date().getTime() );


  private _addParams( logEvent: LogEvent, ... params ) {
    if( params.length ) {

      // logEvent.value.context = {
      //   params
      // }

      for( let i = 0; i < params.length; i++ ) {
        const key = `param[${i}]`;
        logEvent.value.context[key] = Loggable.anyToLoggable( params[i] );
      }
    }
  }


  buildDebugEvent( message: string, ... params ): LogEvent {

    const answer = LogEvent.debug(this.logContainer, BaseLogger.CORRELATION_ID, message);
    this._addParams( answer, ... params );
    return answer;
  }

  buildErrorEvent( message: string, ... params ): LogEvent {

    const answer = LogEvent.error(this.logContainer, BaseLogger.CORRELATION_ID, message);
    this._addParams( answer, ... params );
    return answer;
  }

  buildInfoEvent( message: string, ... params ): LogEvent {

    const answer = LogEvent.info( this.logContainer, BaseLogger.CORRELATION_ID, message);
    this._addParams( answer, ... params );
    return answer;
  }


  buildWarnEvent( message: string, ... params ): LogEvent {

    const answer = LogEvent.warn(this.logContainer, BaseLogger.CORRELATION_ID, message);
    this._addParams( answer, ... params );
    return answer;
  }

  constructor(public logContainer: string) {

    const time = new Date().getTime();
  }
}
