import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {QuestionKey} from "../../model/QuestionKey";
import {IEnumScore, IAppQuestion} from "../../model/AppQuestion";
import {INocoDependantDescriptor, NocoDependantDescriptor} from "./NocoDependantDescriptor";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {IListingReponse} from "../ListingReponse";
import {EnumeratedConstantReference} from "../../model/EnumeratedConstant";
import {NocoQuestionType} from "./NocoQuestionType";


export type NocoEvaluationQuestionId = number;


export interface INocoEvaluationQuestion {

  Id: NocoEvaluationQuestionId;
  DependencyDescription: string;
  DependencyId: number;
  EnumeratedTypeId: number|null;
  EvaluationQuestionImageId: number|null;
  InfoBox: string;
  MaxPoints: number;
  MiscNotes: string|null;
  MysteriousTechColumn: QuestionKey;
  PopupWording: string;
  ProductId: number;
  QuestionText: string;
  QuestionTypeId: number;
  Required: number;
  WeightedScore: string;
  DependencyJson: string;
  ScoringJson: string;
}


interface INocoEnumScore {

  codeAsNumber?: number,

  /**
   * @deprecated : use 'codeAsNumber'
   */
  codeAsString?: string,
  score: number,
}

export class NocoEvaluationQuestion extends ValueObject<INocoEvaluationQuestion>{

  private _log: ILogger = LoggerFactory.build( 'NocoEvaluationQuestion' );

  public isEnum: boolean = false;

  protected onSetValue(value: INocoEvaluationQuestion | null) {
    this.isEnum = false;

    // is it a hack ... No no no not at all ;-)
    if( NocoQuestionType.ENUMERATED_TYPE_ID === value.QuestionTypeId ) {

      this.isEnum = true;
    }
  }

  isEnumeratedType() {

    this.value.QuestionTypeId

  }



  private _getEnumScoring( nocoEnumScores: INocoEnumScore[] ): IEnumScore[] {

    const answer: IEnumScore[] = [];

    try {

      for( const nocoEnumScore of nocoEnumScores ) {

        if( 'undefined' != typeof nocoEnumScore.codeAsNumber ) {

          answer.push( {
            codeAsNumber: nocoEnumScore.codeAsNumber,
            score: nocoEnumScore.score
          });
          continue;
        }

        if( 'undefined' != typeof nocoEnumScore.codeAsString ) {

          const codeAsNumber = EnumeratedConstantReference.asciiCodeToNumber( nocoEnumScore.codeAsString );
          answer.push( {
            codeAsNumber,
            score: nocoEnumScore.score
          });
          continue;
        }

      }
    } catch ( e ) {

      this._log.error( `caught exception 'e'`, 'e', e, 'this.value', this.value );
    }


    return answer;


  }

  public initQuestionScoring(question: IAppQuestion ) {

    if( 583 === question.nocoDbId ) {

      this._log.debug( '583 === question.nocoDbId', 'question', question, 'this.value', this.value );
    }


    if( !this.value.ScoringJson ) {

      return;
    }


    if( !question.type2 ) {

      this._log.warn( '!question.type2', 'question', question, 'this.value.ScoringJson', this.value.ScoringJson );
      return;
    }

    let scoring: any = null;

    try {

      scoring = JSON.parse( this.value.ScoringJson );
    } catch ( error ) {

      this._log.error( 'error', error, 'this.value', this.value );
      return;
    }


    if( question.type2.typeBoolean ) {

      question.type2.typeBoolean.scoring = scoring;
    } else if( question.type2.typeCmMeasurement ) {

      question.type2.typeCmMeasurement.scoring = scoring;
    } else if( question.type2.typeEnum ) {

      question.type2.typeEnum.scoring = this._getEnumScoring( scoring );

    } else if( question.type2.typeInteger ) {

      question.type2.typeInteger.scoring = scoring;
    } else if( question.type2.typeFloat ) {

      question.type2.typeFloat.scoring = scoring;
    } else if( question.type2.typeTernary ) {

      question.type2.typeTernary.scoring = scoring;
    } else if( question.type2.typeText ) {

      question.type2.typeText.scoring = scoring;
    } else {

      this._log.warn( 'unhandled type', 'question', question );
    }

    // if( 'DHYrn' === question.key ) {
    //
    //   this._log.debug( `'DHYrn' === question.key`, 'question', question );
    // }

  }

  public update( question: IAppQuestion ) {

    question.nocoDbId = this.value.Id;
    question.maximumScore = this.value.MaxPoints;
    question.label = this.value.QuestionText;
    question.popupLabel = this.value.PopupWording;
    question.helpText = this.value.InfoBox;

    if( this.value.DependencyJson ) {

      const dependency: INocoDependantDescriptor = JSON.parse(this.value.DependencyJson );
      question.dependant = NocoDependantDescriptor.toDependantDescriptor( dependency );
    }

    this.initQuestionScoring( question );

  }


  constructor( value: INocoEvaluationQuestion | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }
}


export class NocoEvaluationQuestionSet {

  values: NocoEvaluationQuestion[] = [];
  valuesById: {[id: number]: NocoEvaluationQuestion} = {};
  valuesByMysteriousTechColumn: {[id: QuestionKey]: NocoEvaluationQuestion} = {};

  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {

      return 'evaluation_question';
    }
    return 'EvaluationQuestion';

  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEvaluationQuestion>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoEvaluationQuestion>( tableName );
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEvaluationQuestionSet> {

    const value = await NocoEvaluationQuestionSet.getValue( proxy );
    return new NocoEvaluationQuestionSet( value );
  }


  public constructor( public value: IListingReponse<INocoEvaluationQuestion> ) {


    for( const rowValue of value.list ) {

      const reference = new NocoEvaluationQuestion( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
      if( !reference.value.MysteriousTechColumn ) {
        console.error( "reference.value", reference.value );
      }
      this.valuesByMysteriousTechColumn[ reference.value.MysteriousTechColumn.trim() ] = reference;
    }

  }


}
