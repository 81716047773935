import { AppCluster } from "./AppCluster";
import {AppParentChildSet} from "./AppParentChildSet";
import {AppClusterSet} from "./AppClusterSet";
import {AppParentChild} from "./AppParentChild";
import {AppClusterTypeSet} from "./AppClusterTypeSet";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";



// export interface IAppClusterChild {
//
//   root: AppClusterNode
// }

export class AppClusterNode {

  private static _log: ILogger = LoggerFactory.build( 'AppClusterNode' );


  children: AppClusterNode[] = [];
  public iconName: string|null = null;

  public static buildHierarchy( parentCluster: AppCluster,
                                clusters: AppClusterSet,
                                parentChildren: AppParentChildSet,
                                clusterTypes: AppClusterTypeSet,
                                ): AppClusterNode {

    const parentRelation = parentChildren.findParentOfChild( parentCluster._self );
    const answer = new AppClusterNode( parentCluster, parentRelation );
    // answer.cluster = parentCluster;
    // answer.parentRelation = parentChildren.findParentOfChild( parentCluster._self );
    // answer.children = [];

    const children = parentChildren.getChildren( parentCluster._self );
    AppClusterNode._log.debug( 'children', children );

    for( const child of children ) {

      const childCluster = clusters.valuesById[ child.toString ];

      if( !childCluster || !childCluster._self ) {

        AppClusterNode._log.warn( '!childCluster || !childCluster._self', 'child', child );
        continue;
      }

      const childNode = AppClusterNode.buildHierarchy( childCluster, clusters, parentChildren, clusterTypes );

      // try find the icon ?
      {
        const clusterType = clusterTypes.valueByKey[childCluster.value.clusterTypeId];
        if( !clusterType ) {

          AppClusterNode._log.warn( '!clusterType', 'childCluster.value.clusterTypeId', childCluster.value.clusterTypeId);
        } else {
            childNode.iconName = clusterType.iconName;
        }
      }

      answer.children.push( childNode );
    }

    return answer;
  }


  public constructor( public cluster: AppCluster,
                      public parentRelation: AppParentChild|null // null when root
                      ) {
  }
}
