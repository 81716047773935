import {Component, Input, OnInit} from '@angular/core';
import {IScore} from "../../javascript.lib.mojo-base/hotel/model/accessibility/Score";

/**
 * Generated class for the ScoreComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'score',
  templateUrl: 'score.html',
  styleUrls: ['score.scss']
})
export class ScoreComponent implements OnInit {


  ratingUrl = '';

  @Input() score: IScore = {
    numerator: 0,
    denominator: 100
  };


  ngOnInit(): void {

    const rawScore = this.score.numerator / this.score.denominator;

    let percentage = Math.ceil((rawScore) * 50) * 2;


    if (percentage >= 94) {

      this.ratingUrl = "assets/page.completed/component.score/rating-platinum.png";
    } else if (percentage >= 82) {

      this.ratingUrl = "assets/page.completed/component.score/rating-gold.png";
    } else if (percentage >= 71) {

      this.ratingUrl = "assets/page.completed/component.score/rating-silver.png";
    } else if (percentage >= 60) {

      this.ratingUrl = "assets/page.completed/component.score/rating-bronze.png";
    } else {

      // no-op
    }

  }

  doSomethingWithCurrentValue( $event ) {

  }

  scoreLabel( current, max ) {

  }

  constructor() {}

}
