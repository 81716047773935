import {Component, OnInit} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmCancelDialogComponent
} from "../browser.lib.evaluation-tool/module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppClusterType} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {AppParentChild} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChild";
import {
  FirebaseParentChild
} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseParentChild";
import {AppParentChildSet} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChildSet";
import {AppTypedReference} from "../javascript.lib.mojo-base/model/cg/core/AppTypedReference";
import {AppClusterSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterSet";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {AppStorage} from "../app/AppStorage";
import {AppPageDefinitionSet} from "../javascript.lib.mojo-base/model/app/AppPageDefinitionSet";
import {AppClusterNode} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterNode";
import {
  ClusterAddDialogComponent,
  IClusterAddDialogParams,
  IClusterAddDialogResponse
} from "./component.cluster-add-dialog/cluster-add-dialog";
import {environment} from "../environments/environment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {AppClusterTypeSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterTypeSet";
import {FirebaseProperty} from "../javascript.lib.mojo-base/firebase/realtime-database/properties/FirebaseProperty";
import {PropertyReference} from "../javascript.lib.mojo-base/product.facilities/Property";
import {EEvaluationState} from "./EEvaluationState";
import {HttpClient} from "@angular/common/http";
import {IProxyResponse} from "../javascript.lib.mojo-base/firebase/functions/ProxyResponse";
import {IEvaluationState} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {FirebaseEvaluationState} from "../javascript.lib.mojo-base/firebase/evaluation/FirebaseEvaluationState";


class PropertySetup {

  private _log: ILogger = LoggerFactory.build( 'PropertySetup' );

  private async _addApplicableChildren( parentClusterType: AppClusterType, parent: AppCluster, depth: number ) {

    if( 3 === depth ) {

      return;
    }

    const product = NocoDbProduct.INSTANCE;

    this._log.debug( 'parentClusterType', parentClusterType );
    const childrenTypes: AppClusterType[] = product.getClusterTypeChildren( parentClusterType, true );

    for( const childType of childrenTypes ) {



      const child = childType.buildAppCluster();


      const hierarchy = product.clusterHierarchies.getHierarchy( parentClusterType.nocoCluster.value.Id, childType.nocoCluster.value.Id );

      child.value.core = 1 === hierarchy.value.Core;

      await FirebaseCluster.writeReference( this.firebase, this.propertyKey, child );

      const parentChild = AppParentChild.buildNew( parent, child, hierarchy.value.Sequence );
      this._log.debug( 'parentChild', parentChild );
      await FirebaseParentChild.writeReference( this.firebase, this.propertyKey, parentChild );

      await this._addApplicableChildren( childType, child, depth + 1);

    }

  }


  public async setupInitialHierarchy( rootClusterType: AppClusterType ): Promise<AppCluster> {


    const property: PropertyReference = await FirebaseProperty.readReferenceRedux( this.firebase, this.propertyKey );

    const rootCluster = rootClusterType.buildAppCluster();
    rootCluster.value.core = true;
    rootCluster.value.name = property.value.name;
    await FirebaseCluster.writeReference( this.firebase, property.propertyKey, rootCluster );

    await this._addApplicableChildren( rootClusterType, rootCluster, 1 );
    return rootCluster;

  }



  constructor(public propertyKey: string,
              public firebase: FirebaseConnectionService) {

  }


}

@Component({
  selector: 'page-home-cluster',
  styleUrls: ['page-home-cluster.component.scss'],
  templateUrl: './page-home-cluster.component.html'
})
export class PageHomeClusterComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PageHomeClusterComponent' );

  public canEditClusters = environment.productConfig.canEditClusters;

  public productType = environment.productConfig.productType;
  public productTypes = {
    brandHome: EProductType.brandHome,
    facilities: EProductType.facilities,
    manufacturing: EProductType.manufacturing,
  };


  public propertyKey: string = null;

  public completed: boolean = false;

  clusterType: AppClusterType;
  cluster: AppCluster;

  public applicableChildrenTypes: AppClusterType[] = [];
  public children: AppClusterSet = new AppClusterSet( {});

  public hasQuestionsForEvaluation: boolean = false;

  public parentChildHierarchy: AppParentChildSet;


  // public clusterPath: AppCluster[] = [];

  public hierarchyRoot: AppClusterNode;


  public initCompleted = false;
  public initInProgress: boolean = false;


  submittingAsCompleted = false;

  public evaluationState: EEvaluationState = EEvaluationState.evaluating;


  evaluationStates = {
    evaluating: EEvaluationState.evaluating,
    completed: EEvaluationState.completed,
    submitting: EEvaluationState.submitting,
    submitted: EEvaluationState.submitted,
  }


  // private async _addApplicableChildren( parentClusterType: AppClusterType, parent: AppCluster, depth: number ) {
  //
  //   if( 3 === depth ) {
  //
  //     return;
  //   }
  //
  //   const product = NocoDbProduct.INSTANCE;
  //
  //   this._log.debug( 'parentClusterType', parentClusterType );
  //   const childrenTypes: AppClusterType[] = product.getClusterTypeChildren( parentClusterType, true );
  //
  //   for( const childType of childrenTypes ) {
  //
  //
  //
  //     const child = childType.buildAppCluster();
  //
  //
  //     const hierarchy = product.clusterHierarchies.getHierarchy( parentClusterType.nocoCluster.value.Id, childType.nocoCluster.value.Id );
  //
  //     child.value.core = 1 === hierarchy.value.Core;
  //
  //     await FirebaseCluster.writeReference( this.firebase, this.propertyKey, child );
  //
  //     const parentChild = AppParentChild.buildNew( parent, child );
  //     this._log.debug( 'parentChild', parentChild );
  //     await FirebaseParentChild.writeReference( this.firebase, this.propertyKey, parentChild );
  //
  //     await this._addApplicableChildren( childType, child, depth + 1);
  //
  //   }
  //
  // }
  //
  // private async _setupInitialHierarchy(  rootClusterType: AppClusterType, property: PropertyReference ): Promise<AppCluster> {
  //
  //   const rootCluster = rootClusterType.buildAppCluster();
  //   rootCluster.value.core = true;
  //   rootCluster.value.name = property.value.name;
  //   await FirebaseCluster.writeReference( this.firebase, property.propertyKey, rootCluster );
  //
  //   await this._addApplicableChildren( rootClusterType, rootCluster, 1 );
  //   return rootCluster;
  //
  // }

  private async _initClusterAndType( clusterSet: AppClusterSet): Promise<AppClusterSet> {

    const product = NocoDbProduct.INSTANCE;


    const rootClusterType = product.getClusterTypeRoot();
    this._log.debug( 'rootClusterType', rootClusterType );
    this.clusterType = rootClusterType;

    // try find the root ...
    {
      const cluster = clusterSet.findClusterByType( rootClusterType );
      if( cluster ) {
        this.cluster = cluster;
        return clusterSet;
      }
    }

    // root has not been setup yet ...



    // add a new root ...
    {
      const propertyKey = AppStorage.getPropertyKey();
      const propertySetup: PropertySetup = new PropertySetup( propertyKey, this.firebase );
      this.cluster = await propertySetup.setupInitialHierarchy( rootClusterType );
    }

    clusterSet = await FirebaseCluster.readReferences( this.firebase, this.propertyKey );
    return clusterSet;

  }


  private async _updateCompletedSections() {

    const propertyKey = AppStorage.getPropertyKey();

    let evaluationCompleted = true;

    for( const section of this.hierarchyRoot.children ) {

      let sectionCompleted = true;

      if(  0 !== section.children.length ) {

        for( const subSection of section.children ) {

          if (!subSection.cluster.value.completed) {
            sectionCompleted = false;
          }
        }
      } else {
        sectionCompleted = false;
      }

      if( section.cluster.value.completed != sectionCompleted ) {

        section.cluster.value.completed = sectionCompleted;
        await FirebaseCluster.writeReference( this.firebase, propertyKey, section.cluster );
      }

      if( !sectionCompleted ) {

        evaluationCompleted = false;
      }
    }


    if( evaluationCompleted && this.evaluationState == EEvaluationState.evaluating ) {

      this.evaluationState = EEvaluationState.completed;
    }

    if( !evaluationCompleted && this.evaluationState == EEvaluationState.completed ) {

      this.evaluationState = EEvaluationState.evaluating;
    }

    if( evaluationCompleted !== this.hierarchyRoot.cluster.value.completed ) {

      this.hierarchyRoot.cluster.value.completed = evaluationCompleted;
      await FirebaseCluster.writeReference( this.firebase, propertyKey, this.hierarchyRoot.cluster );
    }

  }

  private async _onInit() {


    this.initCompleted = false;

    const product = NocoDbProduct.INSTANCE;

    this.propertyKey = AppStorage.getPropertyKey();

    {
      const evaluationStatus = await FirebaseEvaluationState.read( this.firebase, environment.productConfig.productType, this.propertyKey );
      this._log.debug( 'evaluationStatus', evaluationStatus );
      if( evaluationStatus && evaluationStatus.value.completed ) {

        this.evaluationState = EEvaluationState.submitted;
      }
    }

    let clusterSet = await FirebaseCluster.readReferences( this.firebase, this.propertyKey );
    this._log.debug( 'clusterSet', clusterSet );


    clusterSet = await this._initClusterAndType( clusterSet );

    {
      const pageDefinitions: AppPageDefinitionSet = product.clusterQuestions.toPageDefinitions( this.cluster, product.evaluationQuestions );
      this._log.debug( 'pageDefinitions', pageDefinitions );


      if( 0 === pageDefinitions.value.length ) {

        this.hasQuestionsForEvaluation = false;
      } else {
        this.hasQuestionsForEvaluation = true;
      }
    }


    {
      this.applicableChildrenTypes = product.getClusterTypeChildren( this.clusterType );
      this._log.debug( 'this.applicableChildren', this.applicableChildrenTypes );
    }

    {
      this.parentChildHierarchy = await FirebaseParentChild.readReferences( this.firebase, this.propertyKey );
      const childrenReferences: AppTypedReference[] = this.parentChildHierarchy.getChildren( this.cluster._self );
      this.children = clusterSet.getSubset( childrenReferences );
      this._log.debug( 'this.children', this.children );
    }


    {
      const clusterTypes: AppClusterTypeSet = new AppClusterTypeSet( product.clusters );

      this._log.debug( 'this.cluster', this.cluster );
      this._log.debug( 'clusterSet', clusterSet );
      this._log.debug( 'this.parentChildHierarchy', this.parentChildHierarchy );
      this._log.debug( 'clusterTypes', clusterTypes );
      const hierarchy = AppClusterNode.buildHierarchy( this.cluster, clusterSet, this.parentChildHierarchy, clusterTypes );
      this.hierarchyRoot = hierarchy;
      this._log.debug( 'hierarchy', hierarchy );
    }

    this._updateCompletedSections();

    // {
    //   const pathReferences = [this.parentChildHierarchy.findParentOfChild( this.cluster._self ).parent];
    //   const clusterPath: AppCluster[] = [];
    //   for( const pathReferenceElement of pathReferences ) {
    //
    //     const pathElement = await FirebaseCluster.readReference( this.firebase, this.propertyKey, pathReferenceElement.toString );
    //     clusterPath.push( pathElement );
    //   }
    //
    //   // this.clusterPath = clusterPath;
    //   // this._log.debug( 'this.clusterPath', this.clusterPath );
    // }

    this.initCompleted = true;

  }

  private async _tryOnInit() {

    if( this.initInProgress ) {
      this._log.warn( 'this.initInProgress', this.initInProgress );
      return;
    }
    try {

      this.initInProgress = true;
      await this._onInit();

    } finally {
       this.initInProgress = false;
    }
  }

  ngOnInit() {

    this.route.paramMap.subscribe( async (params) => {

      this._tryOnInit();
    });

    this._tryOnInit();
  }


  // onOpenParentCluster(parent: AppCluster) {
  //
  //   this._log.debug( 'onChildClick', 'child._self.toString', parent._self.toString );
  //   AppRouteManifest.CLUSTER_CLUSTER_ID.navigateToPage( this.router, parent._self.toString );
  // }

  private async _onDeleteChild( child: AppCluster) {

    this._log.debug( 'onDeleteChild', 'child', child );

    const parentChild: AppParentChild = this.parentChildHierarchy.getParentChild( this.cluster._self, child._self );
    if( !parentChild ) {

      this._log.error( 'onDeleteChild', 'this.cluster._self', this.cluster._self, 'child._self', child._self );
    } else {

      parentChild.value.trashed = true;
      await FirebaseParentChild.writeReference( this.firebase, this.propertyKey, parentChild );

      const clusterSet = await FirebaseCluster.readReferences( this.firebase, this.propertyKey );
      const childrenReferences: AppTypedReference[] = this.parentChildHierarchy.getChildren( this.cluster._self );
      this.children = clusterSet.getSubset( childrenReferences );
      this._log.debug( 'this.children', this.children );
    }

  }

  async onDeleteChild( child: AppCluster) {

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `Remove area '${child.value.name}'?`,
      title: 'Remove area?',
    });

    dialog.afterClosed().subscribe(result => {

      this._log.debug( 'result', result );
      if( result ) {

        this._onDeleteChild(child);
      }
    });
  }

  async _onDeleteCluster( parent: AppClusterNode, childForDeletion: AppClusterNode ) {

    this._log.debug( 'parent', parent );
    this._log.debug( 'childForDeletion', childForDeletion );

    childForDeletion.parentRelation.value.trashed = true;
    const propertyKey = this.sessionContext.propertyContext.propertyKey;
    await FirebaseParentChild.writeReference( this.firebase, propertyKey, childForDeletion.parentRelation );

    const updatedChildren: AppClusterNode[] = [];
    for( const e of parent.children ) {

      if( e === childForDeletion ) {
        continue;
      }
      updatedChildren.push( e );
    }

    parent.children = updatedChildren;

    this._updateCompletedSections();

  }


  async onDeleteCluster( parent: AppClusterNode, childForDeletion: AppClusterNode ) {

    this._log.debug( 'parent', parent );
    this._log.debug( 'childForDeletion', childForDeletion );

    const childCluster = childForDeletion.cluster;

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `Remove area '${childCluster.value.name}'?`,
      title: 'Remove area?',
    });

    dialog.afterClosed().subscribe(result => {

      this._log.debug( 'result', result );
      if( result ) {

        this._onDeleteCluster( parent, childForDeletion );
      }
    });

  }

  async onAddSubSection( parent: AppClusterNode ) {

    this._log.debug( 'parent', parent );

    const product = NocoDbProduct.INSTANCE;
    const parentType = product.getClusterType( parent.cluster );
    const applicableTypes = product.getClusterTypeChildren( parentType );

    const params: IClusterAddDialogParams = {

      clusterType: null,
      name: '',
      applicableTypes,
      responsibility: parent.cluster.value.responsibility,
    };


    const dialog = ClusterAddDialogComponent.open( this.dialog, params);

    dialog.afterClosed().subscribe(async (response: IClusterAddDialogResponse|null) => {

      this._log.debug( 'dialog.afterClosed().subscribe', 'response', response );
      if( response ) {

        const newChild = response.clusterType.buildAppCluster( response.name );
        await FirebaseCluster.writeReference( this.firebase, this.propertyKey, newChild );

        const parentChild = AppParentChild.buildNew( parent.cluster, newChild );
        await FirebaseParentChild.writeReference( this.firebase, this.propertyKey, parentChild );

        // this.children.add( newChild );

        {
          const childClusterNode = new AppClusterNode( newChild, parentChild );
          parent.children.push( childClusterNode );
        }

        await this._updateCompletedSections();

      }
    });


  }


  async onAddSection() {

    await this.onAddSubSection( this.hierarchyRoot );
  }


  onEvaluateCurrentClick() {

    this._log.debug( 'onEvaluateCurrentClick' );

    // this.completed=!this.completed;
    AppRouteManifest.CLUSTER_PAGE.navigateToPage( this.router, this.cluster._self.toString, 0 );
  }

  private async _evaluationCompleted() {


    const propertyId: string = this.sessionContext.propertyContext.propertyKey;
    const userUid = this.sessionContext.fbUser.uid;
    const userEmail = this.sessionContext.fbUser.email;

    const proxy = await this.sessionContext.buildAuthenticatedProxy( this.httpClient );
    const response: IProxyResponse<IEvaluationState> = await proxy.evaluationCompleted( propertyId, userUid, userEmail );
    if( "0" !== response.status ) {

      throw response.status;
    }
    this.evaluationState = EEvaluationState.submitted;


  }

  async onEvaluationCompleted() {

    this._log.debug( "onEvaluationCompleted" );

    // this.toggleFireworks();
    // return;

    // this.enabled = true;

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: "Please confirm that you consider the evaluation complete and are ready for it to be reviewed.",
      title: 'Submit for review?',
      okLabel: 'SUBMIT'
    });

    dialog.afterClosed().subscribe(result => {

      console.log('The dialog was closed', result );

      if( result ) {

        try {

          this.submittingAsCompleted = true;
          this._evaluationCompleted();

        } finally {
          this.submittingAsCompleted = false;
        }
      } else {

        // this._showFireworks();
      }

    });

  }


  public reasonSubmitDisabled(): string|null {


    if( this.evaluationState == EEvaluationState.evaluating ) {

      return "Complete evaluation";
    }

    if( this.evaluationState == EEvaluationState.submitting ) {

      return "Submitting ...";
    }

    if( this.evaluationState == EEvaluationState.submitted ) {

      return "Successfully submitted";
    }


    return null;
  }


  submitEvaluationText(): string {

    const answer: string = this.reasonSubmitDisabled();

    if( null != answer ) {

      return answer;
    }

    return "Submit completed evaluation";

  }





  constructor( public router: Router,
               public route: ActivatedRoute,
               public dialog: MatDialog,
               public sessionContext: PwaSessionContextProvider,
               public firebase: FirebaseConnectionService,
               public httpClient: HttpClient) {

    // this.view = new PageClusterView(
    //   {
    //     type: 'Hallway',
    //     completed: false,
    //     name: '1st Floor Main Hallway',
    //     responsibility: 'landlord'
    //   },
    //   [
    //     {
    //       type: 'Toilets',
    //       completed: true,
    //       name: 'Mens Toilet',
    //       responsibility: 'tenant',
    //     },
    //     {
    //       type: 'Toilets',
    //       completed: false,
    //       name: 'Womens Toilet',
    //       responsibility: 'tenant',
    //     },
    //     {
    //       type: 'Meeting Room',
    //       completed: true,
    //       name: 'Meeting Room #1',
    //       responsibility: 'tenant',
    //     },
    //   ]);
  }

}
