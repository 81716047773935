import {AppAnswer, IAppAnswer} from '../AppAnswer';
import {AppAspectDefinition} from './AppAspectDefinition';
import {AppQuestion} from '../AppQuestion';
import {IScore} from "../../hotel/model/accessibility/Score";
import {AppAnswerSet} from "../AppAnswerSet";


/**
  */
export class AppAspectAnswers {

  public delegate: AppAnswerSet;

  /**
   * Will create an answer if required
   * @param question
   */
  getAnswer( question: AppQuestion ): AppAnswer {

    return this.delegate.getAnswer( question );
  }


  scrubMarkupTagsFromText( originalValue: string ) {

    this.delegate.scrubMarkupTagsFromText( originalValue );
  }


  // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
  scrubMarkupTagsFromTextAnswers() {

    this.delegate.scrubMarkupTagsFromTextAnswers();
  }

  willEditAnswers(): void {

    this.delegate.willEditAnswers();
  }

  /**
   * @deprecated call 'answer.getScore()'
   */
  public getScoreForAnswer( answer: AppAnswer ): number {

    return this.delegate.getScoreForAnswer( answer );
  }

  getScore( debug: boolean = false ): IScore|null {

    return this.delegate.getScore( debug );
  }

  isCompleted(): boolean {

    return this.delegate.isCompleted();
  }

  constructor( value: { [key: string]: IAppAnswer }|null,
               public aspectQuestions: AppAspectDefinition ) {


    this.delegate = new AppAnswerSet( aspectQuestions.questionSet, value  );
  }

}
