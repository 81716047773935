import firebase from 'firebase/compat/app';
import storage = firebase.storage;
import {StorageReference} from "@firebase/storage";
import {
  getStorage,
  ref,
  getDownloadURL
} from "firebase/storage";


export class DocumentReference {

  public isPdf: boolean = false;
  public isOther: boolean = false;

  public canBeViewedInBrowser: boolean = false;

  public filename: string;
  public storageUrl: string = null;


  public async resolveStorageUrl(storage: StorageReference  ): Promise<string> {

    if( null === this.storageUrl ) {

      let fileRef = ref( storage, this.storagePath );
      this.storageUrl = await getDownloadURL(fileRef);
    }

    return this.storageUrl;
  }

  constructor( public storagePath: string ) {

    {
      const lastSlash = storagePath.lastIndexOf( '/');
      this.filename = storagePath.substr( lastSlash + 1 );
    }

    const lowerCasePath = storagePath.toLowerCase();
    if( lowerCasePath.endsWith( '.pdf')) {

      this.isPdf = true;
      this.canBeViewedInBrowser = true;
    } else {

      this.isOther = true;
    }
  }
}
