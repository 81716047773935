import {Component, Input} from '@angular/core';


@Component({
  selector: 'general-header',
  templateUrl: 'general-header.html'
})
export class GeneralHeaderComponent {

  @Input() headerText: string = null;


  constructor() {

  }

}
