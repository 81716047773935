


<mat-spinner diameter="64" strokeWidth="4">

</mat-spinner>




