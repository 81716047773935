import {Component, Input, OnInit} from '@angular/core';
import {UserPropertyView} from "./UserPropertyView";


@Component({
  selector: 'app-user-property',
  templateUrl: './user-property.component.html',
})
export class UserPropertyComponent implements OnInit {

  @Input() userPropertyView: UserPropertyView;

  ngOnInit() {
  }

  constructor() { }

}
