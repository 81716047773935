import {AppAspectAnswers} from './AppAspectAnswers';
import {AppAnswer} from '../AppAnswer';
import {AppPageDefinition} from "../app/AppPageDefinition";


export class AppAspectPage {

  answers: AppAnswer[] = [];

  public static getAnswers( questions: AppPageDefinition,
                            answers: AppAspectAnswers ): AppAnswer[] {
    const response: AppAnswer[] = [];


    for( const q of questions.questions ) {

      response.push( answers.getAnswer( q ) );
    }

    return response;
  }

  constructor( public questions: AppPageDefinition,
               answers: AppAspectAnswers ) {

    this.answers = AppAspectPage.getAnswers( questions, answers );
  }

}
