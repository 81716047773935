import {LogEvent} from "./LogEvent";
import {ILogger} from "./Logger";
import {BaseLogger} from "./BaseLogger";
import {ILoggerFactory} from "./LoggerFactory";
import {LoggerDelegate} from "./LoggerDelegate";

export class TestConsoleLogger extends BaseLogger implements ILogger {

  public static factory: ILoggerFactory = {

    build(logContainer: string): ILogger {

      return new TestConsoleLogger(logContainer);
    }
  }


  public debug(message: string, ...params) {

    // by default chrome will suppress 'console.debug'
    // tslint:disable-next-line:no-console
    console.log(`${this.logContainer}:: ${message}`, ...params);



  }

  enteredMethod(methodName: string = null) {

    if (methodName) {

      // by default chrome will suppress 'console.debug'
      console.log(`${this.logContainer}: enteredMethod: ${methodName}`);
    } else {

      // by default chrome will suppress 'console.debug'
      console.log(`${this.logContainer}: enteredMethod`);
    }
  }

  public error(message: string, ...params) {


    {
      const event = this.buildErrorEvent(message, params );
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.error(`${this.logContainer}: ${message}`, ... params);
  }

  public info(message: string, ...params) {

    {
      const event = this.buildInfoEvent( message, params );
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.info(`${this.logContainer}: ${message}`, ... params);
  }


  logDebug(message: string, addContext: (event: LogEvent) => void) {

    const event = this.buildDebugEvent(message);
    addContext(event);


    console.debug(`${this.logContainer}: ${message}`);
  }


  logError(message: string, addContext: (event: LogEvent) => void) {

    const event = this.buildErrorEvent(message);
    addContext(event);
    LoggerDelegate.INSTANCE.handle( event );

    console.error(`${this.logContainer}: ${message}`);
  }

  logInfo(message: string, addContext: (event: LogEvent) => void) {

    const event = this.buildInfoEvent(message);
    addContext(event);
    LoggerDelegate.INSTANCE.handle( event );

    console.info(`${this.logContainer}: ${message}`);
  }

  logWarn(message: string, addContext: (event: LogEvent) => void) {

    const event = this.buildWarnEvent(message);
    addContext(event);
    LoggerDelegate.INSTANCE.handle( event );

    console.warn(`${this.logContainer}: ${message}`);
  }


  public warn(message: string, ...params) {

    {
      const event = this.buildWarnEvent( message, params );
      LoggerDelegate.INSTANCE.handle( event );
    }

    if( params.length ) {

      console.warn(`${this.logContainer}: ${message}`, params);
    } else {

      console.warn(`${this.logContainer}: ${message}` );
    }

  }

  private constructor(logContainer: string) {

    super(logContainer);
  }
}
