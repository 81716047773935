import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppCluster} from "../../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {AppClusterNode} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterNode";
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {
  ClusterEditDialogComponent,
  IClusterEditDialogParams, IClusterEditDialogResponse
} from "./component.cluster-edit-dialog/cluster-edit-dialog";
import {NocoDbProduct} from "../../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppRouteManifest} from "../../app/AppRouteManifest";
import {AppClusterType} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {MatDialog} from "@angular/material/dialog";
import {
  FirebaseCluster
} from "../../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {PwaSessionContextProvider} from "../../service.pwa-session-context/pwa-session-context";
import {
  FirebaseConnectionService
} from "../../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {Router} from "@angular/router";
import {EEvaluationState} from "../EEvaluationState";
import {environment} from "../../environments/environment";
import {
  ClusterCommentDialogComponent,
  IClusterCommentDialogParams, IClusterCommentDialogResponse
} from "./component.cluster-comment/cluster-comment-dialog";
import {AppQuestion} from "../../javascript.lib.mojo-base/model/AppQuestion";
import {
  AppClusterAnswers,
  IAppClusterAnswers
} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterAnswers";
import {
  FirebaseClusterAnswers
} from "../../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswers";
import {AppQuestionSet} from "../../javascript.lib.mojo-base/model/AppQuestionSet";




@Component({
  selector: 'app-cluster',
  styleUrls: ['cluster.component.scss'],
  templateUrl: './cluster.component.html'
})
export class ClusterComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'ClusterComponent' );


  public canEditClusters = environment.productConfig.canEditClusters;

  private _commentQuestion: AppQuestion|null = null;
  public clusterQuestions: AppQuestionSet|null = null;

  @Input() sampleInput: string = "some sample input";
  @Input() childCluster: AppCluster;
  @Input() clusterNode: AppClusterNode;
  @Input() sectionIndex: number = 1;
  @Input() evaluationState: EEvaluationState = EEvaluationState.evaluating;

  evaluationStates = {
    evaluating: EEvaluationState.evaluating,
    completed: EEvaluationState.completed,
    submitting: EEvaluationState.submitting,
    submitted: EEvaluationState.submitted,
  }


  @Output() deleteCluster = new EventEmitter<AppClusterNode>();
  @Output() addCluster = new EventEmitter<AppClusterNode>();


  async onComment( clusterNode: AppClusterNode ) {



    this._log.debug( 'onChildClick', 'clusterNode', clusterNode );



    const propertyKey = this.sessionContext.propertyContext.propertyKey;


    // get the answers ...
    let clusterAnswers: AppClusterAnswers = null;
    {

      const clusterAnswersValue: IAppClusterAnswers = await FirebaseClusterAnswers.readValue( this.firebase, propertyKey, clusterNode.cluster );
      if( clusterAnswersValue ) {

        clusterAnswers = new AppClusterAnswers( clusterAnswersValue );
      } else { // first time answering these questions

        clusterAnswers = AppClusterAnswers.build( clusterNode.cluster._self.value.id );
      }
    }


    const answerSet = clusterAnswers.toAnswerSet( this.clusterQuestions );
    const comment = answerSet.getAnswer( this._commentQuestion );

    const params: IClusterCommentDialogParams = {
      clusterName: clusterNode.cluster.value.name,
      comment
    };

    this._log.debug('params', params);

    const dialog = ClusterCommentDialogComponent.open( this.dialog, params );

    dialog.afterClosed().subscribe( async  (response: IClusterCommentDialogResponse) => {

      this._log.debug('dialog.afterClosed().subscribe', 'result', response);
      if( response ) {
        clusterAnswers.update( answerSet );
        await FirebaseClusterAnswers.writeValueObject( this.firebase, propertyKey, clusterAnswers );
      }

    });

  }
  onEditChild( child: AppClusterNode ) {

    this._log.debug( 'onChildClick', 'child', child );


    const product = NocoDbProduct.INSTANCE;

    const childCluster = child.cluster;

    const clusterType: AppClusterType = product.getClusterType( childCluster );


    const params: IClusterEditDialogParams = {

      name: childCluster.value.name,
      clusterType,
      applicableTypes: [clusterType],
      responsibility: childCluster.value.responsibility,
      deleteDisabled: childCluster.value.core,
    };

    const dialog = ClusterEditDialogComponent.open( this.dialog, params );

    dialog.afterClosed().subscribe( async  (response: IClusterEditDialogResponse) => {

      this._log.debug( 'dialog.afterClosed().subscribe', 'result', response );

      if( response ) {

        if( response.delete ) {

          this.deleteCluster.emit( child );

        } else {

          const cluster = this.clusterNode.cluster;
          childCluster.value.name = response.name;
          childCluster.value.responsibility = response.responsibility;
          const propertyKey = this.sessionContext.propertyContext.propertyKey;
          await FirebaseCluster.writeReference( this.firebase, propertyKey, childCluster );
        }
      }
    })
  }


  onEvaluateChild(child: AppClusterNode ) {

    this._log.debug( 'onChildClick', 'child', child );
    AppRouteManifest.CLUSTER_PAGE.navigateToPage( this.router, child.cluster._self.toString,0 );
  }

  onAddSubSection() {

    this.addCluster.emit( this.clusterNode );
  }

  ngOnInit() {

    this._log.debug( 'ngOnInit' );
  }

  constructor( public router: Router,
               public dialog: MatDialog,
               public sessionContext: PwaSessionContextProvider,
               public firebase: FirebaseConnectionService) {


    if( environment.productConfig.canCommentOnClusters ) {
      this._commentQuestion = environment.productConfig.product.questions.getQuestionById( AppQuestion.KEY_IDS._274_COMMENTS );;
      this._log.debug( 'this._commentQuestion', this._commentQuestion );

      if( this._commentQuestion ) {
        this.clusterQuestions = new AppQuestionSet( [this._commentQuestion.value]);
      }
    }

  }

}
