import {ValueObject} from "../ValueObject";


export interface IEvaluationStarted {

  userEmail?: string;
  userUid: string;
  when: number;
  whenAsString?: string;
}


export class EvaluationStarted extends ValueObject<IEvaluationStarted> {

  public static build( userUid: string, userEmail: string = null ): EvaluationStarted {

    const value: IEvaluationStarted = {
      userUid,
      when: new Date().getTime()
    }

    if( userEmail ) {

      value.userEmail = userEmail;
    }

    return new EvaluationStarted( value );
  }

  protected onSetValue(value: IEvaluationStarted | null) {
  }


  public constructor( value: IEvaluationStarted = null ) {

    super( value );
    if ( value ) {

      this.value = value;
    }
  }

}
