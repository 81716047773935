import { Injectable } from '@angular/core';
// import {AngularFireDatabase} from "angularfire2/database";
// import {FirebaseListObservable} from "../../../node_modules/angularfire2/database/firebase_list_observable";


export interface IUser {

  userUid
  email: string;
  hotels: { [hotelKey: string]: boolean; }
}

/*
*/
@Injectable()
export class UserProvider {


  // private static readonly BASE_PATH = "/users";
  //
  // constructor(private database: AngularFireDatabase) {
  // }
  //
  //
  // private getScoresObservable( userUid: string ): FirebaseListObservable<any[]> {
  //
  //   const path = "/users/";
  //   return this.database.list( path );
  // }



}
