
<div *ngIf="!sessionContext.sessionIsReady"
     style="width: 100%; height: 75%">

  <h3 cclass="header" *ngIf="!userIsReady&&!userIsLoggedOut">
    {{intermediaStatus}}
  </h3>

  <h3 cclass="header" *ngIf="userIsReady">
    User is ready
  </h3>



  <div cclass="header" *ngIf="userIsLoggedOut">

    <sign-in
      (passwordResetRequested)="onPasswordResetRequested($event)">
    </sign-in>
  </div>

</div>
