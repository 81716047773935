import {AppAspectDefinition} from "../../../model/app.aspect/AppAspectDefinition";
import {IAppAnswer} from "../../../model/AppAnswer";
import {IFirebaseAnswersListener} from "../../../../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/AngularFirebaseAnswers";
import {AnswersReference} from "../../../model/Answers";
import {AppAspectAnswers} from "../../../model/app.aspect/AppAspectAnswers";
import {IFirebaseConnection} from "../../FirebaseConnection";
import {environment} from "../../../../environments/environment";



export class FirebaseAnswers {


  // private static _log = LoggerFactory.build( 'FirebaseAnswers' );
  public static listener: IFirebaseAnswersListener = null;


  public static getPath( hotelKey: string, section: string ): string {

    const answer = `/answers/${hotelKey}/${section}`;
    // FirebaseAnswers._log.debug( 'getPath', answer);
    return answer;
  }


  public static getPathX( propertyKey: string, aspectQuestions: AppAspectDefinition ) {

    const answer = `${environment.productConfig.firebaseAnswersRoot}/${propertyKey}/${aspectQuestions.value.firebaseAspectId}`;
    // FirebaseAnswers._log.debug( 'aspectQuestions.value.firebaseAnswersRoot', aspectQuestions.value.firebaseAnswersRoot);
    // FirebaseAnswers._log.debug( 'propertyKey', propertyKey);
    return answer;
  }

  public static async readBathroom(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswers.readReference( firebaseConnection, hotelKey, 'bathroom' );
  }

  public static async readBathroomValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return FirebaseAnswers.readValue( firebaseConnection, hotelKey, 'bathroom' );
  }

  public static async readBedroom(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswers.readReference( firebaseConnection, hotelKey, 'bedroom' );
  }

  public static async readBedroomValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return FirebaseAnswers.readValue( firebaseConnection, hotelKey, 'bedroom' );
  }

  public static async readGeneral(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswers.readReference( firebaseConnection, hotelKey, 'general' );
  }

  public static async readGeneralValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return FirebaseAnswers.readValue( firebaseConnection, hotelKey, 'general' );
  }

  private static async readReference( firebaseConnection: IFirebaseConnection, hotelKey: string, section: 'bathroom'|'bedroom'|'general' ): Promise<AnswersReference> {

    const value = await FirebaseAnswers.readValue( firebaseConnection, hotelKey, section );
    return new AnswersReference( section, value, hotelKey );
  }

  private static async readValue( firebaseConnection: IFirebaseConnection, hotelKey: string, section: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    const path =  FirebaseAnswers.getPath(hotelKey,section);

    return firebaseConnection.database2.getValue<{ [key: string]: IAppAnswer; }>( path );
  }


  public static async readReferenceX( firebaseConnection: IFirebaseConnection,
                                      hotelKey: string,
                                      aspectQuestions: AppAspectDefinition ): Promise<AppAspectAnswers> {


    const value = await FirebaseAnswers.readValueX( firebaseConnection, hotelKey, aspectQuestions );
    return new AppAspectAnswers( value, aspectQuestions );
  }


  public static async readValueX( firebaseConnection: IFirebaseConnection,
                                  hotelKey: string,
                                  aspectQuestions: AppAspectDefinition ): Promise<{ [key: string]: IAppAnswer; }|null> {


    const path = FirebaseAnswers.getPathX( hotelKey, aspectQuestions );
    return firebaseConnection.database2.getValue<{ [key: string]: IAppAnswer; }|null>( path );
  }



}
