import {NocoClusterId, NocoClusterSet} from "../../nocodb/model/NocoCluster";
import {AppClusterType} from "./AppClusterType";



export class AppClusterTypeSet {

  value: AppClusterType[] = [];
  valueByKey: { [clusterId: NocoClusterId]: AppClusterType } = {};



  public constructor( nocoClusters: NocoClusterSet ) {

    for( const nocoCluster of nocoClusters.values ) {

      const appClusterType = AppClusterType.build(nocoCluster);
      this.value.push( appClusterType );
      this.valueByKey[ appClusterType.value.clusterId ] = appClusterType;
    }
  }


}
