// vvv derived from open source code: https://github.com/rlong/browser.lib.json_broker/blob/master/json_broker.ts
import {EnumeratedConstantReference} from "../model/EnumeratedConstant";

export class CodeHelper {


  /**
   * @deprecated use `EnumeratedConstantReference.numberCodeToAscii`
   * @param faultCode
   */
  static numberCodeToAscii(faultCode: number): string {

    return EnumeratedConstantReference.numberCodeToAscii( faultCode );
  }

  /**
   *
   * @deprecated use `EnumeratedConstantReference.asciiToNumberCode`
   * @param faultCode
   */
  static asciiToNumberCode(faultCode: string): number {

    return EnumeratedConstantReference.asciiCodeToNumber( faultCode );
  }





}
// ^^^ derived from open source code: https://github.com/rlong/browser.lib.json_broker/blob/master/json_broker.ts
