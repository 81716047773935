






<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu homeDisabled="true" [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>



  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <h2>Documents</h2>

          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>

      </mat-toolbar>

      <div aclass="page-content">

        <app-session-checker
          *ngIf="!sessionContext.sessionIsReady">
        </app-session-checker>


        <div *ngIf="sessionContext.sessionIsReady">

          <mat-card>

            <div *ngFor="let document of sessionContext.documents" cclass="view-and-download">

<!--              mat-raised-button color="primary"-->
              <button mat-stroked-button color="primary"
                      (click)="onViewDocument(document)">
                <mat-icon style="font-size: 24px;">cloud_download</mat-icon>
                &nbsp;
                {{document.filename}}
              </button>

            </div>



            <!--          <div cclass="view-and-download">-->
            <!--            <button mat-raised-button color="primary" >-->
            <!--              Download-->
            <!--            </button>-->

            <!--            <button mat-raised-button color="primary" >-->
            <!--              View-->
            <!--            </button>-->
            <!--          </div>-->

          </mat-card>

        </div>

      </div>

      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row>

          <span style="flex: 1 1 auto;"></span>

          <button mat-button (click)="onGoHome()" >
            HOME
            &nbsp;&nbsp;
            <mat-icon>chevron_right</mat-icon>
          </button>

        </mat-toolbar-row>



      </mat-toolbar>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>



