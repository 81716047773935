import {HttpClient} from "@angular/common/http";
import {BaseProxy} from "./BaseProxy";
import {environment} from "../../../environments/environment";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";



export class PublicProxy {

  private _log = LoggerFactory.build( 'PublicProxy' );

  private _proxy: BaseProxy;


  public async signInFailed( email: string ) {


    const url = `${environment.cloudFunctionsUrl}/public/functions/signInFailed?email=${email}`;
    this._log.debug( 'url', url );
    await this._proxy.get( url );
  }


  constructor( http: HttpClient ) {

    this._proxy = new BaseProxy( http );
  }

}
