


<div aclass="page-container">

  <mat-toolbar aclass="header-toolbar">

    <mat-toolbar-row >
      <span style="flex: 1 1 auto;"></span>
      <h2>Manage Users</h2>
      <span style="flex: 1 1 auto;"></span>
    </mat-toolbar-row>

  </mat-toolbar>

  <div aclass="page-content" style="user-select: text">

    <app-back-office-session (sessionStateChanged)="ngOnInit()">
    </app-back-office-session>

    <div
      *ngIf="'AuthenticatedAsAdministrator' === sessionContext.state">
      <mat-tab-group style="padding-left: 16px; padding-right: 16px;"
                     [selectedIndex]="0">

        <mat-tab label="Add User">

          <app-user-add [existingUsers]="users"
                        [properties]="properties"
                        (userAdded)="onUsersModified()"
          ></app-user-add>

        </mat-tab>

        <mat-tab label="User List">

          <ul>
            <li *ngFor="let user of users">
              {{user.value.email}} ({{user.userUid}})
            </li>
          </ul>

        </mat-tab>

        <mat-tab label="Edit User">
          <app-user-edit [existingUsers]="users"
                         [properties]="properties"
                         (usersModified)="onUsersModified()"
          ></app-user-edit>
        </mat-tab>




      </mat-tab-group>

    </div>


  </div>


  <mat-toolbar aclass="footer-toolbar">

    <mat-toolbar-row>
    </mat-toolbar-row>

  </mat-toolbar>


</div>

<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">
  </mat-sidenav>


  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <span style="flex: 1 1 auto;"></span>
          <h2>User Manage</h2>
          <span style="flex: 1 1 auto;"></span>
        </mat-toolbar-row>

      </mat-toolbar>

      <div aclass="page-content">



      </div>

      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row>
        </mat-toolbar-row>


      </mat-toolbar>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>





