

<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu homeDisabled="true" [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>


  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">

        <mat-toolbar-row >
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png" style="height: 34px;">
          <span style="flex: 1 1 auto;"></span>
        </mat-toolbar-row>

      </mat-toolbar>

      <div aclass="page-content">

        <app-session-checker *ngIf="!sessionContext.sessionIsReady"
                             (passwordResetRequested)="onPasswordResetRequested()">
        </app-session-checker>

        <div *ngIf="sessionContext.sessionIsReady">
          <div fxLayout="column" fxLayoutGap="10px">

            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex cclass="instructions">
                <strong>
                  Select a facility
                </strong>
              </div>
            </div>
          </div>

          <mat-list>
            <mat-list-item
              *ngFor="let hotel of sessionContext.availableProperties"
              class="mat_list_item__hotel">

              <button mat-raised-button cclass="property" color="primary"
                      (click)="onHotelSelect(hotel)"
              >
                <div *ngIf="hotel !== sessionContext.propertyContext.property; else current">
                  {{hotel.value.name}}
                </div>
                <ng-template #current>
                  <div style="font-weight: bold;">
                    {{hotel.value.name}} (current)
                  </div>
                </ng-template>
                <div>

                </div>

              </button>
            </mat-list-item>
          </mat-list>
        </div>

      </div>

      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row>
        </mat-toolbar-row>


      </mat-toolbar>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>


