
<div cclass="logical-unit">

  <div aclass="component-list">
    <div aclass="component-list-item">
      <div aclass="form-header">
        Password Reset
      </div>
    </div>
  </div>


  <div aclass="component-list">

    <form class="form__"
          [formGroup]="emailPasswordFormGroup"
          (ngSubmit)="onSendPasswordResetEmail()"
    >

      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>


      <div aclass="component-list-item">
        <div cclass="form-input">
          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Enter your email"
              [formControl]="passwordResetControls.email"
              type="text"
            >
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="emailControlError" aclass="component-list-item">
        <div aclass="form-error">
          {{emailControlError}}
        </div>
      </div>

      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button color="primary" type="submit">Send Password Reset Email</button>
      </div>

      <div aclass="component-list-item">
        <div style="width: 100%; height: 8px;">
        </div>
      </div>


    </form>

  </div>

</div>

