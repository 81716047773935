import { Component } from '@angular/core';
import {environment} from "../environments/environment";
import {PageManageUser} from "../module.back-office/page.manage-user/page-manage-user";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirestoreLoggerDelegate} from "../javascript.lib.mojo-base/log/FirestoreLoggerDelegate";
import {ILogEvent, LogEvent, LogLevel} from "../javascript.lib.mojo-base/log/LogEvent";
import {LoggerDelegate} from "../javascript.lib.mojo-base/log/LoggerDelegate";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],
})
export class MyApp {


  private _log: ILogger = LoggerFactory.build( 'MyApp' );

  rootPage:any = PageManageUser;

  isTest: boolean = environment.isTest || environment.isDevelopment;


  private _onRouterEvent( event: Event ) {

    // [How to detect a route change in Angular? - Stack Overflow](https://stackoverflow.com/questions/33520043/how-to-detect-a-route-change-in-angular)
    this._log.debug( 'event', event );
  }

  constructor( public applicationContext: PwaApplicationContextProvider,
               public firebase: FirebaseConnectionService,
               private router: Router ) {

    if( !environment.isDevelopment ) {

      LoggerDelegate.INSTANCE = new FirestoreLoggerDelegate( firebase.firestore );
    }
    {
      const event = LogEvent.info( "MyApp", "none", "Hello from MyApp" );
      LoggerDelegate.INSTANCE.handle( event );
    }

    this.router.events.subscribe((event: Event) => {

      this._onRouterEvent( event );

    });

  }


}

