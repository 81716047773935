


export class FirebaseErrorCodes {

  static readonly auth = {

    EMAIL_ALREADY_EXISTS: 'auth/email-already-exists',
    INVALID_DYNAMIC_LINK_DOMAIN: 'auth/invalid-dynamic-link-domain',
    USER_NOT_FOUND: 'auth/user-not-found',
    WRONG_PASSWORD: 'auth/wrong-password',
  };

  static readonly storage = {
    OBJECT_NOT_FOUND: 'storage/object-not-found'
  }

}
