import {ValueObject} from "../../model/ValueObject";
import {IDependantDescriptor} from "../../model/AppQuestion";
import {QuestionKey} from "../../model/QuestionKey";
import {EnumeratedConstantReference} from "../../model/EnumeratedConstant";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";




export interface INocoDependantDescriptor {

  questionKey: QuestionKey;
  truthyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'
  falsyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'
  truthyBooleanValue?: boolean;
  truthyTernaryCodes?: string[];
  isEssential?: boolean;

}

export class NocoDependantDescriptor {

  private static _log: ILogger = LoggerFactory.build( 'NocoDependantDescriptor' );

  // public toDependantDescriptor() {
  //
  // }



  private static _mapFromStringCodesToNumericCodes( codes: any[] ) : number[] {

    const answer = [];

    // this._log.debug( 'codes', codes );

    for( const code of codes ) {

      if( 'number' === typeof code ) {

        answer.push( code as number );
        continue;
      }

      if( 'string' === typeof code ) {

        const stringCode =  code as string;
        const numberCode = EnumeratedConstantReference.asciiCodeToNumber( stringCode );
        answer.push( numberCode );
        continue;
      }

      NocoDependantDescriptor._log.warn( "unhandled code", 'code', code );
    }

    return answer;
  }

  public static toDependantDescriptor( dependantDescriptor: INocoDependantDescriptor ): IDependantDescriptor {

    const answer: IDependantDescriptor = {
      questionKey: dependantDescriptor.questionKey
    }

    if( 'undefined' != typeof dependantDescriptor.truthyEnumCodes ) {

      answer.truthyEnumCodes =
        NocoDependantDescriptor._mapFromStringCodesToNumericCodes( dependantDescriptor.truthyEnumCodes );
    }

    if( 'undefined' != typeof dependantDescriptor.falsyEnumCodes ) {
      answer.falsyEnumCodes =
        NocoDependantDescriptor._mapFromStringCodesToNumericCodes( dependantDescriptor.falsyEnumCodes );
    }

    if( 'undefined' != typeof dependantDescriptor.truthyBooleanValue ) {
      answer.truthyBooleanValue = dependantDescriptor.truthyBooleanValue;
    }

    if( 'undefined' != typeof dependantDescriptor.truthyTernaryCodes ) {
      answer.truthyTernaryCodes =
        NocoDependantDescriptor._mapFromStringCodesToNumericCodes( dependantDescriptor.truthyTernaryCodes );
    }

    if( 'undefined' != typeof dependantDescriptor.isEssential ) {
      answer.isEssential = dependantDescriptor.isEssential;
    }

    return answer;
  }

  private static mapFromNumericCodesToStringCodes( codes: number[] ) : string[] {

    const answer = [];

    // this._log.debug( 'codes', codes );

    for( const code of codes ) {

      const asciiCode = EnumeratedConstantReference.numberCodeToAscii( code );
      answer.push( asciiCode );
    }

    return answer;
  }


  public static build( dependantDescriptor: IDependantDescriptor ): INocoDependantDescriptor {

    const answer: INocoDependantDescriptor = {
      questionKey: dependantDescriptor.questionKey
    }

    if( 'undefined' != typeof dependantDescriptor.truthyEnumCodes ) {

      // this._log.debug( 'dependantDescriptor.truthyEnumCodes', dependantDescriptor.truthyEnumCodes );
      answer.truthyEnumCodes = dependantDescriptor.truthyEnumCodes;
    }

    if( 'undefined' != typeof dependantDescriptor.falsyEnumCodes ) {
      answer.falsyEnumCodes = dependantDescriptor.falsyEnumCodes;
    }

    if( 'undefined' != typeof dependantDescriptor.truthyBooleanValue ) {
      answer.truthyBooleanValue = dependantDescriptor.truthyBooleanValue;
    }

    if( 'undefined' != typeof dependantDescriptor.truthyTernaryCodes ) {
      answer.truthyTernaryCodes = NocoDependantDescriptor.mapFromNumericCodesToStringCodes( dependantDescriptor.truthyTernaryCodes );
    }

    if( 'undefined' != typeof dependantDescriptor.isEssential ) {
      answer.isEssential = dependantDescriptor.isEssential;
    }

    return answer;

  }


  protected onSetValue(value: INocoDependantDescriptor | null) {
  }

  // constructor(value: INocoDependantDescriptor|null) {
  //
  //   super(value);
  // }


}
