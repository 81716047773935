

<mat-toolbar aclass="header-footer">

  <mat-toolbar-row >

    <span style="flex: 1 1 auto;"></span>

    <ng-content></ng-content>

  </mat-toolbar-row>

</mat-toolbar>
