import {AngularFireDatabase} from '@angular/fire/compat/database';
import {first} from 'rxjs/operators';
import {IFirebaseConnection} from "../../../../javascript.lib.mojo-base/firebase/FirebaseConnection";
import { AppAspectDefinition } from '../../../../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition';
import {IAppAnswer} from "../../../../javascript.lib.mojo-base/model/AppAnswer";
import {AppAspectAnswers} from "../../../../javascript.lib.mojo-base/model/app.aspect/AppAspectAnswers";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {AnswersReference} from "../../../../javascript.lib.mojo-base/model/Answers";
import {HotelReference} from "../../../../javascript.lib.mojo-base/hotel/model/Hotel";
import {
  FirebaseAnswers
} from "../../../../javascript.lib.mojo-base/firebase/realtime-database/answers-x/FirebaseAnswers";
import {environment} from "../../../../environments/environment";


interface IAnswersMeta {
  completed: boolean;
}

export interface IFirebaseAnswersListener {

  onReadValueXByKey( propertyKey: string,
                     aspectQuestions: AppAspectDefinition,
                     answers: { [key: string]: IAppAnswer; }|null );

  onWriteValueXByKey( propertyKey: string, aspectAnswers: AppAspectAnswers );
}

export class AngularFirebaseAnswers {



  private static log = LoggerFactory.build( 'AngularFirebaseAnswers' );

  public static listener: IFirebaseAnswersListener = null;



  // static async read( firebaseConnection: FirebaseConnection, hotelKey: string): Promise<HotelReference|null> {
  //
  //   const path = FirebaseHotels.getPath( hotelKey );
  //
  //   const value = await RealtimeDatabase.getValue<IHotel[]>( firebaseConnection, path );
  //
  //   if ( value ) {
  //
  //     return new HotelReference( value[0], hotelKey );
  //   }
  //   return null;
  // }


  /**
   * @deprecated use `FirebaseAnswers.readGeneral`
   * @param afDb
   * @param hotelKey
   */
  static async readGeneral( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await AngularFirebaseAnswers.readValue( afDb, hotelKey, 'general' );
    return new AnswersReference( 'general', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswers.readGeneralValue`
   * @param afDb
   * @param hotelKey
   */
  static async readGeneralValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return AngularFirebaseAnswers.readValue( afDb, hotelKey, 'general' );
  }

  /**
   * @deprecated use `FirebaseAnswers.readBathroom`
   * @param afDb
   * @param hotelKey
   */
  static async readBathroom( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await AngularFirebaseAnswers.readValue( afDb, hotelKey, 'bathroom' );
    return new AnswersReference( 'bathroom', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswers.readBathroomValue`
   * @param afDb
   * @param hotelKey
   */
  static async readBathroomValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return AngularFirebaseAnswers.readValue( afDb, hotelKey, 'bathroom' );
  }

  /**
   * @deprecated use `FirebaseAnswers.readBedroom`
   * @param afDb
   * @param hotelKey
   */
  static async readBedroom( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await AngularFirebaseAnswers.readValue( afDb, hotelKey, 'bedroom' );
    return new AnswersReference( 'bedroom', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswers.readBedroomValue`
   * @param afDb
   * @param hotelKey
   */
  static async readBedroomValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    return AngularFirebaseAnswers.readValue( afDb, hotelKey, 'bedroom' );
  }


  public static async readValueX( afDb: AngularFireDatabase,
                                  hotel: HotelReference,
                                  aspectQuestions: AppAspectDefinition ): Promise<{ [key: string]: IAppAnswer; }|null> {


    const path = FirebaseAnswers.getPathX( hotel.hotelKey, aspectQuestions );
    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAppAnswer; };
    return answer;
  }


  /**
   * @deprecated use 'AngularFirebaseAnswers.readValueXByKeyRedux'
   */
  public static async readValueXByKey( afDb: AngularFireDatabase,
                                       propertyKey: string,
                                       aspectQuestions: AppAspectDefinition ): Promise<{ [key: string]: IAppAnswer; }|null> {


    const path = FirebaseAnswers.getPathX( propertyKey, aspectQuestions );
    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAppAnswer; };

    if( answer && AngularFirebaseAnswers.listener ) {
      try {


        AngularFirebaseAnswers.listener.onReadValueXByKey( propertyKey, aspectQuestions, answer );

      } catch ( e ) {

        AngularFirebaseAnswers.log.error( 'listener raised error', e );
      }
    }
    return answer;
  }



  public static async readValueXByKeyRedux( firebaseConnection: IFirebaseConnection,
                                            propertyKey: string,
                                            aspectDefinition: AppAspectDefinition ): Promise<{ [key: string]: IAppAnswer; }|null> {

    const debug = 'parking-and-entrance' === aspectDefinition.value.firebaseAspectId;

    if( debug ) {

      const path = FirebaseAnswers.getPath( propertyKey,  aspectDefinition.value.firebaseAspectId );
      AngularFirebaseAnswers.log.debug( `debug`, 'path', path );
    }

    const answer = await FirebaseAnswers.readValueX( firebaseConnection, propertyKey,  aspectDefinition );

    if( answer && FirebaseAnswers.listener ) {
      try {


        FirebaseAnswers.listener.onReadValueXByKey( propertyKey, aspectDefinition, answer );

      } catch ( e ) {

        AngularFirebaseAnswers.log.error( 'listener raised error', e );
      }
    }

    if( debug ) {

      AngularFirebaseAnswers.log.debug( `'parking-and-entrance' === evaluationSection.value.firebaseAspectId`, 'answer', answer );
    }


    return answer;

  }


  public static async writeValueXByKey( afDb: AngularFireDatabase,
                                        propertyKey: string,
                                        aspectAnswers: AppAspectAnswers ): Promise<void> {


    const value = aspectAnswers.delegate.value;
    if( !value ) {

      AngularFirebaseAnswers.log.error( 'writeValue', '!value', propertyKey,  aspectAnswers.aspectQuestions.value.name );
      return;
    }

    {
      const keys = Object.keys( value );
      if( 0 === keys.length ) {

        AngularFirebaseAnswers.log.error( 'writeValue', '0 === keys.length', propertyKey, aspectAnswers.aspectQuestions.value.name );
        return;
      }
    }

    const path = FirebaseAnswers.getPathX( propertyKey, aspectAnswers.aspectQuestions );

    if( AngularFirebaseAnswers.listener ) {
      try {

        AngularFirebaseAnswers.listener.onWriteValueXByKey( propertyKey, aspectAnswers );

      } catch ( e ) {

        AngularFirebaseAnswers.log.error('listener raised error', e);

      }
    }


    return afDb.object( path ).set( value );
  }


  public static async writeValueX( afDb: AngularFireDatabase,
                                   hotel: HotelReference,
                                   aspectAnswers: AppAspectAnswers ): Promise<void> {

    return AngularFirebaseAnswers.writeValueXByKey( afDb, hotel.hotelKey, aspectAnswers );
  }

  private static getMetaPath( hotel: HotelReference, aspectQuestions: AppAspectDefinition ) {

    const answer = `${environment.productConfig.firebaseAnswersRoot}/${hotel.hotelKey}/_meta`;
    AngularFirebaseAnswers.log.debug( 'answer', answer);
    return answer;
  }

  public static async writeMeta( afDb: AngularFireDatabase,
                                 hotel: HotelReference,
                                 aspectQuestions: AppAspectDefinition,
                                 value: IAnswersMeta ): Promise<void> {

    const path = AngularFirebaseAnswers.getMetaPath( hotel, aspectQuestions );
    AngularFirebaseAnswers.log.debug( 'writeMeta', 'path', path );
    return afDb.object( path ).set( value );
  }


  private static async readValue( afDb: AngularFireDatabase, hotelKey: string, section: string ): Promise<{ [key: string]: IAppAnswer; }|null> {

    const path =  FirebaseAnswers.getPath(hotelKey,section);
    AngularFirebaseAnswers.log.debug( 'path', path);

    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAppAnswer; };
    return answer;
  }


  private static writeValue( afDb: AngularFireDatabase, hotelKey: string, section: string, value: { [key: string]: IAppAnswer; } ): Promise<void> {

    if( !value ) {

      AngularFirebaseAnswers.log.error( 'writeValue', '!value', hotelKey, section );
      return;
    }

    {
      const keys = Object.keys( value );
      if( 0 === keys.length ) {

        AngularFirebaseAnswers.log.error( 'writeValue', '0 === keys.length', hotelKey, section );
        return;
      }
    }

    const path = FirebaseAnswers.getPath(hotelKey,section);
    return afDb.object( path ).set( value );
  }

  static async writeBathroom(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, answers.hotelKey, 'bathroom', answers.value );
  }


  static async writeBathroomValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAppAnswer; } ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, hotelKey, 'bathroom', value );
  }

  static async writeBedroom(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, answers.hotelKey, 'bedroom', answers.value );
  }

  static async writeBedroomValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAppAnswer; } ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, hotelKey, 'bedroom', value );
  }

  static async writeGeneral(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, answers.hotelKey, 'general', answers.value );
  }

  static async writeGeneralValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAppAnswer; } ): Promise<void> {

    await AngularFirebaseAnswers.writeValue( afDb, hotelKey, 'general', value );
  }



}
