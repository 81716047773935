import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationPagesRoutingModule } from './evaluation-pages-routing.module';


@NgModule({
  declarations: [


  ],
  imports: [
    CommonModule,
    EvaluationPagesRoutingModule
  ]
})
export class EvaluationPagesModule { }

