import {AppPageDefinition} from "./AppPageDefinition";
import {AppQuestionSet} from "../AppQuestionSet";
import {IAppQuestion, AppQuestion} from "../AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {NocoDbProduct} from "../../nocodb/NocoDbProduct";


export class AppPageDefinitionSet {

  private _log: ILogger = LoggerFactory.build( 'AppPageDefinitionSet' );

  public questionCount = 0;

  toQuestionSet( questions: AppQuestionSet ): AppQuestionSet {

    const answerValue: IAppQuestion[] =[];

    for( const pageDefinition of this.value ) {

      for( const questionKey of pageDefinition.value.questionKeys ) {

        const question: AppQuestion = questions.getQuestionById( questionKey );
        if( !question ) {

          this._log.warn( '!question', 'questionKey', questionKey );
          continue;
        }
        answerValue.push( question.value );
      }
    }

    return new AppQuestionSet( answerValue );
  }

  constructor( public value: AppPageDefinition[] ) {

    this.questionCount = 0;

    for( const pageDefinition of this.value ) {


      this.questionCount += pageDefinition.value.questionKeys.length;
    }
    this._log.debug( 'this.questionCount', this.questionCount );

  }
}
