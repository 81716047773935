
    <mat-toolbar cclass="side-menu-header">


      <mat-toolbar-row >
        <img src="../../../assets/mobility-mojo/logo.white.png" style="height: 20px;">
        <span style="font-size: 1.8rem;">&nbsp;Menu</span>
        <span style="flex: 1 1 auto;"></span>
      </mat-toolbar-row>

    </mat-toolbar>


    <mat-list>
      <div *ngFor="let menuItem of model.menuItems">
        <mat-list-item>
          <button cclass="menu-item" mat-button style=""
                  (click)="onClick(menuItem)"
                  [disabled]="menuItem.disabled()"

          >
            <fa-icon  style="font-size: 1.2em; border-bottom: 1px solid #dedede;"
                      [icon]="menuItem.icon"></fa-icon>&nbsp;
            <span style="">
            {{menuItem.label()}}
            </span>
            <span>
          </span>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>

    <div style="padding: 10px 0 0 44px; user-select: text;">
      {{version}}
    </div>
