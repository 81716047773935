import { NgModule } from '@angular/core';
import { QuestionIntegerComponent } from './question-integer/question-integer';
import { QuestionTextComponent } from './question-text/question-text';
import { QuestionPhotoComponent } from './question-photo/question-photo';
import { QuestionCentimetersComponent } from './question-centimeters/question-centimeters';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {QuestionBooleanComponent} from './question-boolean/question-boolean';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {QuestionTernaryComponent} from './question-ternary/question-ternary';
import { QuestionEnumComponent } from './question-enum/question-enum';
import {HelpComponent} from './component.help/help';
import {GeneralHeaderComponent} from './general-header/general-header';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AspectHeaderComponent} from './component.aspect-header/aspect-header';
import {AspectQuestionListComponent} from './component.aspect-question-list/aspect-question-list.component';
import {CompletedTick} from './component.completed-tick/completed-tick';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HelpDialog} from './component.help/component.help-dialog/help-dialog';
import {QuestionBooleanDialogComponent} from "./question-boolean/question-boolean-dialog/question-boolean-dialog";
import {QuestionEnumDialogComponent} from "./question-enum/question-enum-dialog/question-enum-dialog";
import {QuestionTernaryDialogComponent} from "./question-ternary/question-ternary-dialog/question-ternary-dialog";
import {MatListModule} from "@angular/material/list";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BaseComponentsModule} from "../module.base-components/base-components.module";

@NgModule({
    declarations: [
      AspectHeaderComponent,
      CompletedTick,
      GeneralHeaderComponent,
      HelpComponent,
      HelpDialog,
      QuestionBooleanComponent,
      QuestionBooleanDialogComponent,
      QuestionCentimetersComponent,
      QuestionEnumComponent,
      QuestionEnumDialogComponent,
      QuestionIntegerComponent,
      AspectQuestionListComponent,
      QuestionPhotoComponent,
      QuestionTernaryComponent,
      QuestionTernaryDialogComponent,
      QuestionTextComponent,
    ],
    exports: [
      AspectHeaderComponent,
      CompletedTick,
      GeneralHeaderComponent,
      HelpComponent,
      QuestionBooleanComponent,
      QuestionCentimetersComponent,
      QuestionIntegerComponent,
      AspectQuestionListComponent,
      QuestionPhotoComponent,
      QuestionTernaryComponent,
      QuestionTextComponent,
      QuestionEnumComponent,
    ],
    imports: [


      // Core ...
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      // angular material ...
      MatButtonModule,
      MatDialogModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatSidenavModule,
      MatTabsModule,
      MatToolbarModule,

      // 3rd party ...
      FlexLayoutModule,
      FontAwesomeModule,

      // in-house ...
      BaseComponentsModule,
    ]
})
export class EvaluationComponentsModule {}
