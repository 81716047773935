import firebase from "firebase/compat";
import {FirebaseConnectionVersion9} from "./FirebaseConnectionVersion9";
import {IRealtimeDatabase} from "../../javascript.lib.mojo-base/firebase/RealtimeDatabase";




export class RealtimeDatabaseVersion9 implements IRealtimeDatabase {

  static async getValue<T>(firebaseConnection: FirebaseConnectionVersion9, path: string ): Promise<T|null> {

    const snapshot = await firebaseConnection.database().ref( path ).once( 'value' );
    const value: T = snapshot.val();
    return value;
  }


  static async setValue<T>(firebaseConnection: FirebaseConnectionVersion9, path: string, value: T ): Promise<void> {

    return firebaseConnection.database().ref( path ).set( value );
  }

  async getValue<T>( path: string ): Promise<T|null> {

    const snapshot = await this._database.ref( path ).once( 'value' );
    const value: T = snapshot.val();
    return value;
  }

  async setValue<T>( path: string, value: T ): Promise<void> {

    return this._database.ref( path ).set( value );
  }

  constructor( private _database: firebase.database.Database ) {
  }

}
