



<mat-slide-toggle
  [(ngModel)]="userPropertyView.include"
>
</mat-slide-toggle>

{{userPropertyView.property.value.name}} ({{userPropertyView.property.propertyKey}})







