import {BaseSideMenuModel} from "../browser.lib.evaluation-tool/service.side-menu-model/BaseSideMenuModel";
import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {
  IMenuItem,
  SideMenuComponent
} from "../browser.lib.evaluation-tool/module.base-components/component.side-menu/side-menu.component";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";
import { faList } from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import {SessionContextState} from "../browser.lib.evaluation-tool/service.session-context/BaseSessionContext";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {MatDialog} from "@angular/material/dialog";


@Injectable()
export class PwaSideMenuModel extends BaseSideMenuModel implements OnInit, OnDestroy {

  public static ICONS = {
    faList,
  };



  private _sessionSateSubscription: Subscription;


  async onGoHome() {

    AppRouteManifest.HOME.navigate( this.router );
  }


  async onSelectHotel() {

    AppRouteManifest.PROPERTY_SELECT.navigate( this.router );
  }


  async doLogout() {

    await this.sessionContext.signOut();
    AppRouteManifest.HOME.navigate( this.router );
  }

  ngOnDestroy(): void {

    if( null != this._sessionSateSubscription ) {

      this._sessionSateSubscription.unsubscribe();
      this._sessionSateSubscription = null;
    }
  }

  addNonClusteredProperty(menuItems: IMenuItem[] ) {

    let index = 0;

    for (const aspect of this.sessionContext.propertyContext.product.evaluationSections) {

      const aspectIndex = index++;
      menuItems.push({
        disabled: () => {
          return false;
        },
        icon: PwaSideMenuModel.ICONS.faList,
        label: () => {
          return `${aspectIndex + 1}: ${aspect.value.name}`;
        },
        onClick: async () => {

          AppRouteManifest.ASPECT_SECTION.navigateToPage(this.router, aspectIndex, 0);
        }
      });
    }
  }

  ngOnInit(): void {

    const self = this;

    this._sessionSateSubscription = this.sessionContext.stateSubject.subscribe( (next: SessionContextState) => {

      if( SessionContextState.UserIsReady === next) {

        this.menuItems = [
          this.getHomeMenuItem(),
        ];


        if( self.sessionContext.propertyContext ) {

          if( !self.sessionContext.propertyContext.property.value.clustered ) {

            this.addNonClusteredProperty( this.menuItems );
          }


          this.menuItems.push( this.getPropertyMenuItem() );

        }
        this.menuItems.push( this.getLogoutMenuItem() );

      } else {

        // minimal ...
        this.menuItems = [
          this.getHomeMenuItem(),
        ]
      }
    });

  }


  getPropertyMenuItem(): IMenuItem {

    return {
      disabled: () => {

        return !this.sessionContext.fbUser;
      },
      icon: SideMenuComponent.icons.faHotel,
      label: () => {
        if( this.sessionContext.propertyContext ) {
          return `Facility: ${this.sessionContext.propertyContext.property.value.name}`;
        }
        return 'Facility: -';
      },
      onClick: async () => {

        return this.onSelectHotel();
      }
    };
  }

  getHomeMenuItem(): IMenuItem {

    return {
      disabled: () => {

        return false;
      },
      icon: SideMenuComponent.icons.faHome,
      label: () => {
        return 'Home';
      },
      onClick: async () => {

        return this.onGoHome();
      }
    };
  }

  getLogoutMenuItem(): IMenuItem {
    return {
      disabled: () => {

        return !this.sessionContext.fbUser;
      },
      icon: SideMenuComponent.icons.faSignOutAlt,
      label: () => {

        if( this.sessionContext.fbUser ) {
          return `Logout: ${this.sessionContext.fbUser.email}`;
        }
        return 'Logout: -';
      },
      onClick: async () => {

        return this.onLogout( this.sessionContext.fbUser.email );
      }
    };
  }

  constructor( public sessionContext: PwaSessionContextProvider,
               public applicationContext: PwaApplicationContextProvider,
               public router: Router,
               dialog: MatDialog ) {

    super( dialog );

    // minimal ...
    this.menuItems = [
      this.getHomeMenuItem(),
    ]

    // vvv [javascript - ngOnInit not being called when Injectable class is Instantiated - Stack Overflow](https://stackoverflow.com/questions/35110690/ngoninit-not-being-called-when-injectable-class-is-instantiated)
    this.ngOnInit();
    // ^^^ [javascript - ngOnInit not being called when Injectable class is Instantiated - Stack Overflow](https://stackoverflow.com/questions/35110690/ngoninit-not-being-called-when-injectable-class-is-instantiated)

  }

}
