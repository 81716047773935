import {MMBaseException} from "../../../util/MMBaseException";


export enum ECGReferenceType {

  answer_cluster = "answer_cluster",
  cluster = "cluster",
  parent_child = "parent_child",


  // Answer = "Ans",
  answer_boolean = "answer_boolean",
  answer_enum = "answer_enum",
  answer_float = "answer_float",
  answer_integer = "answer_integer",
  answer_line = "answer_line",
  answer_measurement = "answer_measurement",
  answer_photo = "answer_photo",
  answer_ternary = "answer_ternary",
  answer_text = "answer_text",


  none = "none",

  // ParentChild = "PaCh",

  QuestionCluster = "QuCl",

  Question = "Qstn",
  // QuestionBoolean = "QuBo",
  // QuestionEnum = "QuEn",
  // QuestionFloat = "QuFl",
  // QuestionInteger = "QuInt",
  // QuestionLine = "QuLin",
  // QuestionMeasurement = "QuMe",
  // QuestionPhoto = "QuPh",
  // QuestionTernary = "QuTe",
  // QuestionText = "QuTxt",

  // Product = "Prdct",

  ScoreReview = "score_review",
  ScoreReviewCluster = "score_review_cluster",

  // Undefined = "undefined",
}


export class CGReferenceTypeHelper {


  public static lookup( type: string ): ECGReferenceType {

    throw MMBaseException.build( 'CGReferenceTypeHelper', `'type' not found`, {
      type
    });

  }

}
