

<section>
  <form [formGroup]="loadFormGroup" (ngSubmit)="onLoadUser()">

    <div fxLayout="column" fxLayoutGap="10px" style="padding: 10px 8px 0px 8px;">

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

        <div fxFlex="10">
        </div>

        <div fxFlex="80">
          <mat-form-field style="width: 100%">
            <input matInput
                   placeholder="User email"
                   type="text"
                   [formControl]="loadFormControls.email"
            >
            <mat-error *ngIf="loadFormControls.email.hasError('required')">
              User UID <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="10">
        </div>

      </div>



      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

        <div fxFlex="10">
        </div>

        <div fxFlex="80">

          <button mat-raised-button type="submit" color="primary">
            Load user
          </button>

        </div>

        <div fxFlex="10">
        </div>
      </div>

    </div>
  </form>

</section>

<mat-divider></mat-divider>

<section *ngIf="existingUser">
  <form [formGroup]="editFormGroup" (ngSubmit)="onSaveUser()">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

      <div fxFlex="10">
      </div>

      <div fxFlex="80">
        <mat-form-field style="width: 100%">
          <input matInput
                 placeholder="Full name"
                 type="text"
                 [formControl]="editFormControls.fullName"
          >
          <mat-error *ngIf="editFormControls.fullName.hasError('required')">
            Name <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="10">
      </div>



    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

      <div fxFlex="10">
      </div>

      <div fxFlex="80">
        <ul>
          <li *ngFor="let userPropertyView of userPropertyViews">
            <app-user-property [userPropertyView]="userPropertyView">
            </app-user-property>
          </li>
        </ul>
      </div>

      <div fxFlex="10">
      </div>



    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

      <div fxFlex="10">
      </div>

      <div fxFlex>
        <button mat-raised-button type="submit" color="primary">
          Update User
        </button>
      </div>

      <div fxFlex="10">
      </div>

    </div>


  </form>
</section>



