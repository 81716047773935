import {IFirebaseFirestore} from "../../javascript.lib.mojo-base/firebase/IFirebaseFirestore";
import firebase from "firebase/compat";
import Firestore = firebase.firestore.Firestore;
import {addWarning} from "@angular-devkit/build-angular/src/utils/webpack-diagnostics";


export class FirebaseFirestoreVersion9 implements IFirebaseFirestore {

  async getValue<T>( path: string ): Promise<T> {

    const snapshot = await  this._firestore.doc( path ).get();
    return snapshot.data() as T;
  }

  async setValue<T>(path: string, value: T): Promise<void> {

    return this._firestore.doc( path ).set( value );
  }


  constructor( private _firestore: Firestore ) {
  }
}
