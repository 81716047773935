import {BehaviorSubject, Subscription} from 'rxjs';
import {Subscriptions} from './Subscriptions';


export class Watchable<T> {


  // private _observable: Observable<T> = null;
  // private _observableSubscription: Subscription;

  private _subject = new BehaviorSubject<T>(null);

  private _value: T = null;

  public set value( value: T|null) {

    this._value = value;

    this._subject.next( value );
  }

  public get value(): T|null {

    return this._value;
  }


  // private get observable(): Observable<T> | null {
  //
  //   return this._observable;
  // }
  //
  // private set observable(observable: Observable<T> | null) {
  //
  //   if ( this._observableSubscription ) {
  //
  //     this._observableSubscription.unsubscribe();
  //   }
  //
  //   this._observable = observable;
  //
  //   if ( observable ) {
  //
  //     this._observableSubscription = this._observable.subscribe( (value: T) => {
  //
  //       this.value = value;
  //     }, (error: any) => {
  //
  //       console.error( [this], 'set observable', error );
  //     });
  //   }
  // }


  /**
   * @deprecated use `watch`
   * @param next
   * @param error
   * @param complete
   */
  public subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {

    return this._subject.subscribe( next, error, complete );
  }

  public watch( subscriptions: Subscriptions,
                next?: (value: T) => void,
                error?: (error: any) => void,
                complete?: () => void ): void {

    const subscription = this._subject.subscribe( next, error, complete );
    subscriptions.add( subscription );
  }

  constructor( initialvalue: T = null ) {

    this.value = initialvalue;
  }


}
