import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { AppClusterType } from "../../../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {DialogConfig} from "../../../browser.lib.evaluation-tool/angular/DialogConfig";
import {
  ConfirmCancelDialogComponent
} from "../../../browser.lib.evaluation-tool/module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";


export interface IClusterEditDialogParams {

  // pageClusterView: IPageClusterView;

  name: string;
  clusterType: AppClusterType;
  responsibility: 'landlord'|'tenant';

  applicableTypes: AppClusterType[];

  deleteDisabled: boolean;
}

export interface IClusterEditDialogResponse {

  name: string;
  responsibility: 'landlord'|'tenant';

  delete: boolean;
}



@Component({
  selector: 'cluster-edit-dialog',
  templateUrl: 'cluster-edit-dialog.html',
  styleUrls: ['cluster-edit-dialog.scss']
})
export class ClusterEditDialogComponent {

  public static PANEL_CLASS = "cluster-edit-dialog";

  isNew: boolean;

  private _log: ILogger = LoggerFactory.build( 'ClusterEditDialogComponent' );

  // sectionView: IPageClusterView;

  public params: IClusterEditDialogParams;
  public response: IClusterEditDialogResponse;


  formControls = {
    name: new FormControl(null, [ Validators.required ] ),
    responsibility: new FormControl( null, [ Validators.required ] ),
    clusterType: new FormControl( null,[ Validators.required ] ),
  };

  formGroup = new FormGroup({
    name: this.formControls.name,
    responsibility: this.formControls.responsibility,
    clusterType: this.formControls.clusterType,
  });


  onSendPasswordResetEmail() {

    this._log.debug( 'onSendPasswordResetEmail' );

  }

  static open(dialog: MatDialog, params: IClusterEditDialogParams ): MatDialogRef<ClusterEditDialogComponent> {

    const answer = dialog.open<ClusterEditDialogComponent, IClusterEditDialogParams, IClusterEditDialogResponse>
    ( ClusterEditDialogComponent,
      {
        data: params,
        panelClass: ClusterEditDialogComponent.PANEL_CLASS,
        minWidth: DialogConfig.minWidth,
      });

    return answer;
  }

  onCancelClick(): void {

    this.dialogRef.close(null );
  }

  onDeleteClick() {

    this.response.delete = true;

    this.dialogRef.close( this.response );

  }

  onOkClick(): void {

    this.response.name = this.formControls.name.value;
    this.response.responsibility = this.formControls.responsibility.value;

    this.dialogRef.close( this.response );
  }


  constructor(
    public dialogRef: MatDialogRef<ClusterEditDialogComponent, IClusterEditDialogResponse>,
    @Inject(MAT_DIALOG_DATA) params: IClusterEditDialogParams,
  ) {

    this.params = params;
    this._log.debug( 'this.params', this.params );
    // this.sectionView = params.pageClusterView;

    if( params.clusterType ) {

      this.isNew = false;
      this.formControls.clusterType.disable();
    } else {

      this.isNew = true;
    }

    this.formControls.name.setValue( params.name );
    this.formControls.responsibility.setValue( params.responsibility );
    this.formControls.clusterType.setValue( params.clusterType );

    this._log.debug( 'this.formControls.responsibility.value', this.formControls.responsibility.value );

    this.response = {
      name: '',
      responsibility: 'landlord',
      delete: false,
    }
  }

}
