import {EvaluationStarted, IEvaluationStarted} from "./EvaluationStarted";
import {EvaluationCompleted, IEvaluationCompleted} from "./EvaluationCompleted";
import {ValueObject} from "../ValueObject";


export interface IEvaluationState {

  started?: IEvaluationStarted;
  completed?: IEvaluationCompleted;
}


export class EvaluationStatus extends ValueObject<IEvaluationState> {

  started: EvaluationStarted|null = null;
  completed: EvaluationCompleted|null = null;


  isStarted(): boolean {

    return !!this.started;
  }

  isCompleted(): boolean {

    return !!this.completed
  }




public evaluationCompleted( userUid: string, userEmail: string = null ): EvaluationCompleted {

    this.completed = EvaluationCompleted.build( userUid, userEmail );
    this.value.completed = this.completed.value;

    return this.completed;
  }

  public evaluationStarted( userUid: string, userEmail: string = null ): EvaluationStarted {

    this.started = EvaluationStarted.build( userUid, userEmail );
    this.value.started = this.started.value;

    return this.started;
  }


  protected onSetValue(value: IEvaluationState | null) {

    this.started = null;
    this.completed = null;

    if( !value ) {
      this.value = {};
      return;
    }

    if( value.started ) {

      this.started = new EvaluationStarted( value.started );
    }
    if( value.completed ) {

      this.completed = new EvaluationCompleted( value.completed );
    }
  }

  constructor( value: IEvaluationState | null ) {

    super( value );
    if ( value ) {

      this.value = value;
    }
  }

}
