import {AppTypedReference, IAppTypedReference} from "../../model/cg/core/AppTypedReference";
import {IFirebaseMetaData} from "./FirebaseMetaData";
import {ValueObject} from "../../model/ValueObject";


export interface IMMValueObject {

  _self: IAppTypedReference;
  _meta: IFirebaseMetaData;
}

export class FirebaseValueObject<T extends IMMValueObject> extends ValueObject<T>{

  public _self: AppTypedReference;


  protected onSetValue(value: T | null) {

    this._self = null;

    if( !value ) {
      return;
    }

    this._self = new AppTypedReference( value._self );
  }


  constructor( value: T) {

    super( value );
  }


}
