import {ValueObject} from "../javascript.lib.mojo-base/model/ValueObject";
import {IReportFeature, ReportFeature} from "./ReportFeature";




export interface IReportSection {

  features: IReportFeature[];
  identifier: string;
  nocoDbReportSectionId?: number;
  questionKeys: string[];
  title: string;
  overview: string;
}


export class ReportSection extends ValueObject<IReportSection>{

  features: ReportFeature[] = [];



  protected onSetValue(value: IReportSection | null) {

    this.features = [];

    for( const feature of value.features ) {

      this.features.push( new ReportFeature( feature ));
    }
  }

  constructor( value: IReportSection ) {

    super(value);
    this.value = value;
  }

}

