import {IProduct } from "./IProduct";

export class ProductHelper {



  public static cloneProduct( product: IProduct, excludePhotos = false ) {

    const answer: IProduct = {

      evaluationSections: product.evaluationSections,

      questions: product.questions,

      reportSections: product.reportSections,

      scoreRules: product.scoreRules,
      summarySections: product.summarySections,

      version: product.version,

      getAllEnums: product.getAllEnums,
      printCodes: product.printCodes,
    };


    if( excludePhotos ) {

      answer.evaluationSections = [];
      for( const aspectQuestions of product.evaluationSections ) {

        answer.evaluationSections.push( aspectQuestions.cloneWithoutPhotos() );
      }
    }

    return answer;

  }
}
