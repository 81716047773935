import {Component, Input} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

/**
 * Generated class for the TestQuestionBooleanComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'completed-tick',
  templateUrl: 'completed-tick.html',
  animations: [
    trigger('completed-text', [
      state('yes',
        style({
        opacity: 1,
      })),
      state('no', style({
      })),
      transition('no => yes', [
        animate('0.2s')
      ]),
    ]),
    trigger('completed-img', [
      state('yes',
        style({
          opacity: 1,
          height: '100%',
          width: '100%',
          'max-height': '60px',
          'max-width': '60px',
        })),
      state('no', style({
      })),
      transition('no => yes', [
        animate('0.3s')
      ]),
    ])
  ],
  styleUrls: ['completed-tick.scss']
})
export class CompletedTick {

  private _completed:boolean|undefined = false;
  public completedText: boolean;
  public completedImg: boolean;

  @Input() set completed(value: boolean|undefined) {

    this._completed = value;
    this.completedText = value;

    if( value ) {

      const intervalId = setInterval( () => {

        this.completedImg = value;
        clearInterval( intervalId );

      }, 250 );
    } else {

      this.completedImg = value;
    }
  }

  get completed(): boolean|undefined {

    return this._completed;
  }

  constructor() {
  }
}
