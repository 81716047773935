import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePage} from "../page.home/home";
import {HotelSelectPage} from "../page.hotel-select/hotel-select";
import {CompletedPage} from "../page.completed/completed";
import {PageAspectSectionComponent} from "../page.aspect-section/page-aspect-section.component";
import {DocumentsComponent} from "../page.documents/documents.component";
import {PasswordResetPage} from "../page.password-reset/password-reset.page";
import {PageHomeClusterComponent} from "../page.home-cluster/page-home-cluster.component";
import {PageClusterPageComponent} from "../page.cluster-page/page-cluster-page.component";

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    // component: HomePage,
  },
  // {
  //   path: ':params',
  //   // redirectTo: 'home',
  //   // pathMatch: 'full',
  //   component: HomePage,
  // },
  {
    path: 'aspect/:aspectIndex/:sectionIndex',
    component: PageAspectSectionComponent,
  },
  {
    path: 'back-office',
    loadChildren: () => import('../module.back-office/back-office.module').then(mod => mod.BackOfficeModule)
  },
  // {
  //   path: 'cluster/:clusterId',
  //   component: PageHomeClusterComponent,
  // },
  {
    path: 'cluster-page/:clusterId/:activeIndex',
    component: PageClusterPageComponent,
  },
  {
    path: 'development',
    loadChildren: () => import('../module.development/development.module').then(mod => mod.DevelopmentModule)
  },
  {
    path: 'documents',
    component: DocumentsComponent,
  },
  {
    path: 'home',
    component: HomePage,
    pathMatch: 'full',
  },
  {
    path: 'home:params',
    component: HomePage,
    pathMatch: 'full',
  },
  {
    path: 'home-cluster',
    component: PageHomeClusterComponent,
  },
  {
    path: 'password-reset/:emailAddress',
    component: PasswordResetPage,
  },
  {
    path: 'property-select',
    component: HotelSelectPage,
  },
  {
    path: 'score',
    component: CompletedPage
  },
  {
    path: 'test',
    loadChildren: () => import('../browser.lib.evaluation-tool/module.test/test.module').then(mod => mod.TestModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy',
    useHash: true })],
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
