import {Component} from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'toast',
  templateUrl: 'toast.html',
  styleUrls: ['toast.scss']
})
export class ToastComponent {

  public static show( snackBar: MatSnackBar, message: string ) {

    snackBar.open( message, null, {
      duration: 3000,
    } );

    // snackBar.openFromComponent(ToastComponent, {
    //   duration: 1000,
    // });

  }

}
