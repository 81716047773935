import {BehaviorSubject} from "rxjs";
import firebase from 'firebase/compat/app';
import User = firebase.User;
import {IFirebaseError} from "../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {AppStorage} from "../../app/AppStorage";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import { Injectable, } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {FirebaseRoles} from "../../javascript.lib.mojo-base/firebase/FirebaseRoles";

export enum BackOfficeSessionState {

  Unknown = "Unknown",
  NotAuthenticated = "NotAuthenticated",
  Authenticating = "Authenticating",
  AuthenticatedAsAdministrator = "AuthenticatedAsAdministrator",
  AuthenticatedAsOther = "AuthenticatedAsOther",
}

export interface ICustomClaims {

  administrator: boolean;
}

interface IGetCustomClaimsResponse {

  status: string;
  payload: ICustomClaims
}

@Injectable()
export class BackOfficeSessionContext {


  private static readonly _ROLE_ADMINISTRATOR = 'role_administrator';

  public fbUser: User|null = null; // not null when authenticated

  private _log = LoggerFactory.build( 'BackOfficeSessionContext' );

  private _state: BackOfficeSessionState = BackOfficeSessionState.NotAuthenticated;
  public stateSubject = new BehaviorSubject<BackOfficeSessionState>(BackOfficeSessionState.Unknown );
  public sessionIsReady = false;
  public userIsAdministrator = false;
  public emailAddress = null;


  get state() {

    return this._state;
  }


  set state( value: BackOfficeSessionState ) {

    this._log.debug( `${this._state} => ${value}`);
    this._state = value;

    this.stateSubject.next( value );

    if( BackOfficeSessionState.AuthenticatedAsAdministrator === value ) {

      this.sessionIsReady = true;
    } else {

      this.sessionIsReady = false;
    }
  }



  private async _init( user: firebase.User|null ) {

    this.fbUser = user;

    this.userIsAdministrator = false;
    this.emailAddress = null;

    if( !user ) {
      this.state = BackOfficeSessionState.NotAuthenticated;
      return;
    }


    this.emailAddress = user.email;

    const idTokenResult: firebase.auth.IdTokenResult = await user.getIdTokenResult();
    this._log.debug( 'idTokenResult.claims', idTokenResult.claims );
    if( idTokenResult.claims[FirebaseRoles.ROLE_ADMINISTRATOR] ) {

      this.userIsAdministrator = true;
      this.state = BackOfficeSessionState.AuthenticatedAsAdministrator;
    } else {

      this.userIsAdministrator = false;
      this.state = BackOfficeSessionState.AuthenticatedAsOther;
    }

  }

  async signOut() {

    AppStorage.clear();

    this.state = BackOfficeSessionState.NotAuthenticated;
    return this.afAuth.signOut();
  }

  async signInWithPopup( provider: firebase.auth.OAuthProvider ): Promise<IFirebaseError|null> {

    try {

      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithPopup( provider );
      await this._init( userCredential.user );
      return null; // no error

    } catch (e) {

      const firebaseAuthError: IFirebaseError = e as IFirebaseError;

      this._log.logError( 'signInWithPopup: catch (e)', event => {

        event.value.context['code'] = firebaseAuthError.code;
        event.value.context['message'] = firebaseAuthError.message;
      } )
      return firebaseAuthError;
    }


    return null; // no error
  }
  async signInWithEmailAndPassword(email: string, password: string ): Promise<IFirebaseError|null> {

    try {

      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithEmailAndPassword( email, password );
      await this._init( userCredential.user );
      return null; // no error

    } catch (e) {

      const firebaseAuthError: IFirebaseError = e as IFirebaseError;

      // this._log.error('signIn', e );
      this._log.logError( 'signInWithEmailAndPassword: catch (e)', event => {

        event.value.context['code'] = firebaseAuthError.code;
        event.value.context['message'] = firebaseAuthError.message;
        event.value.context['email'] = email;
      } )
      return firebaseAuthError;

    }
  }



  constructor( public http: HttpClient,
               public afAuth: AngularFireAuth ) {

    this.state = BackOfficeSessionState.Authenticating;

    this.afAuth.onAuthStateChanged( async (user: firebase.User|null) => {

      return this._init( user );

    });

  }

}
