
import {environment} from "../../../../environments/environment";
import {ECGReferenceType} from "../../../model/cg/core/CGReferenceType";
import {AppCluster} from "../../../model/app.cluster/AppCluster";
import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppClusterAnswers, IAppClusterAnswers} from "../../../model/app.cluster/AppClusterAnswers";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";


export class FirebaseClusterAnswers {


  private static _log: ILogger = LoggerFactory.build( 'FirebaseClusterAnswers' );


  private static _getPathFromCluster( propertyKey: string, cluster: AppCluster ): string {

    const productKey = environment.productConfig.productType;

    return `/answer-clusters/${productKey}/${propertyKey}/${ECGReferenceType.answer_cluster}/${ECGReferenceType.answer_cluster}:${cluster._self.value.id}`;
  }



  static async readValue( firebaseConnection: IFirebaseConnection, propertyKey: string, cluster: AppCluster ): Promise<IAppClusterAnswers|null> {

    const path = this._getPathFromCluster( propertyKey, cluster );
    this._log.debug( 'path', path );

    const answer: IAppClusterAnswers = await firebaseConnection.database2.getValue<IAppClusterAnswers>( path );
    if( !answer || !answer.answers ) {

      return AppClusterAnswers.buildValue( cluster.value._self.id );
    }

    return answer;

  }

  private static _getPathFromAnswers( propertyKey: string, answers: AppClusterAnswers ): string {

    const productKey = environment.productConfig.productType;

    return `/answer-clusters/${productKey}/${propertyKey}/${ECGReferenceType.answer_cluster}/${answers._self.toString}`;
  }

  static async writeValueObject(firebaseConnection: IFirebaseConnection, propertyKey: string, answers: AppClusterAnswers ) {


    const path = this._getPathFromAnswers( propertyKey, answers )
    this._log.debug( 'path', path );
    FirebaseMMValueObject.addUpdate( firebaseConnection, answers );
    return firebaseConnection.database2.setValue( path, answers.value );


  }



}
