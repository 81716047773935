import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";


export interface IModalSpinnerParams {
  wait: Promise<void>;
}

@Component({
  selector: 'modal-spinner',
  templateUrl: 'modal-spinner.html',
  styleUrls: ['modal-spinner.scss']
})
export class ModalSpinnerComponent {

  public static PANEL_CLASS = "confirm-cancel-dialog";

  params: IModalSpinnerParams;


  static open(dialog: MatDialog, wait: Promise<void> ): MatDialogRef<ModalSpinnerComponent> {

    const data: IModalSpinnerParams = {
      wait
    };

    const answer = dialog.open<ModalSpinnerComponent,IModalSpinnerParams>(ModalSpinnerComponent, {
      data,
      panelClass: ModalSpinnerComponent.PANEL_CLASS,
      width: '120px',
    });

    return answer;
  }


  onOkClick(): void {

    this.dialogRef.close( true );
  }

  async _init() {

    await this.params.wait;
    this.dialogRef.close();
  }


  constructor(
    public dialogRef: MatDialogRef<ModalSpinnerComponent>,
    @Inject(MAT_DIALOG_DATA)  data: IModalSpinnerParams,
  ) {

    this.params = data;
    this._init();
  }

}
