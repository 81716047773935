import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';



export interface IRegistrationDetails {



  name: string;
  address: string;
  country: string;
  postCode: string;
  telephone: string;
  email: string;
  website: string;
  mojoHotelCode: string;

}

export class RegistrationDetails {

  value: IRegistrationDetails;

  constructor( value: IRegistrationDetails = null) {

    if( value ) {
      this.value = value;
    } else {

      this.value = {

        name: null,
        address: null,
        country: null,
        postCode: null,
        telephone: null,
        email: null,
        website: null,
        mojoHotelCode: "0",
      }
    }
  }

  public static isValid( value: IRegistrationDetails ): boolean {

    if( !value.name ) {
      return false;
    }

    if( !value.address ) {
      return false;
    }

    if( !value.country ) {
      return false;
    }

    if( !value.postCode ) {
      return false;
    }

    if( !value.telephone ) {
      return false;
    }

    if( !value.email ) {
      return false;
    }

    if( !value.website ) {
      return false;
    }



    return true;
  }

}


@Injectable()
export class RegistrationProvider {

  registrationDetails: RegistrationDetails = null;
  isRegistered: boolean = false;


  constructor() {
  }

  register( registrationDetails: RegistrationDetails ) {

    this.registrationDetails = registrationDetails;
    this.isRegistered = true;

  }
}




