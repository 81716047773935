import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  DialogData
} from "../../module.evaluation-components/question-boolean/question-boolean-dialog/question-boolean-dialog";
import {EnumeratedConstantReference} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {DialogConfig} from "../../angular/DialogConfig";


export interface INotificationParams {
  message: string;
  okLabel?: string;
  title: string;
}

@Component({
  selector: 'notification-dialog',
  templateUrl: 'notification-dialog.html',
  styleUrls: ['notification-dialog.scss']
})
export class NotificationDialogComponent {

  public static PANEL_CLASS = "notification-dialog";

  params: INotificationParams;
  okLabel = 'OK';


  static open(dialog: MatDialog, params: INotificationParams ): MatDialogRef<NotificationDialogComponent> {

    const answer = dialog.open<NotificationDialogComponent,INotificationParams>(NotificationDialogComponent, {
      data: params,
      panelClass: NotificationDialogComponent.PANEL_CLASS,
      minWidth: DialogConfig.minWidth,
    });

    return answer;
  }


  onOkClick(): void {

    this.dialogRef.close();
  }


  constructor(
    public dialogRef: MatDialogRef<INotificationParams>,
    @Inject(MAT_DIALOG_DATA) data: INotificationParams,
  ) {
    this.params = data;
    if( this.params.okLabel ) {

      this.okLabel = this.params.okLabel;
    }
  }

}
