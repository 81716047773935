

export enum EProductType {
  banking = "banking",
  brandHome = "brandHome",
  facilities = "facilities",
  foodAndBeverage = "food-and-beverage",
  generic = "generic",
  manufacturing = "manufacturing",
  manufacturingCampus = "manufacturing-campus",
  postOffice = "post-office",
  publicRealm = "public-realm",
  retail = "retail",
  anPostDsu = "an-post-dsu",
  failteIrelandRetail = "failte-ireland-retail",
  failteIrelandAccommodation = "failte-ireland-accommodation",
}


