import {ReportSection} from "../../model.product/ReportSection";


export class FacilityReportSections2 {

  public static readonly VALUE: ReportSection[] = [

    /*
    select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
  report_section.id = 49 -- `Outdoor Areas`
order by
    report_section.order_in_document
;


... completely missing

     */

    new ReportSection(
      {
        nocoDbReportSectionId: 1, // select id,name from report_section where id = 1;
        title: 'Parking',
        overview: 'Parking',
        questionKeys: [
          'Bkp11', // Is there parking available for visitors/employees?
          'MpjwN', // Are parking charges in place?
          'Mpj2n', // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
          'BkqAn', // Are there designated wheelchair accessible parking spaces available?
          'BkqAx', // How many wheelchair accessible parking spaces are provided?
          'BkqA7', // Is there an accessible entrance from the indoor car park to the main building?
          'BkqBF', // From the car park, does the building entrance have an intercom or two-way communication system?
          'ajdl0', // Is there a step-free and unobstructed route from the designated accessible parking space(s) to the most accessible building entrance?
          'BkqBP', // From the car park, is the entrance to the building through two doors in a series?
          'BkqBZ', // In centimetres, what is the distance between the two doors in the series? (ie. the clear space between the two doors).
          'BkqBj', // Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)

        ],
        identifier: 'parking',
        features: [

          /*
          select
      report_section.id as section_id,
      report_section.name as section_name,
      report_feature.id as feature_id,
      report_feature.name as feature_name
  from
      report_feature, report_feature_section, report_section
  where
      report_feature.id = report_feature_section.report_feature_id
    and
      report_section.id = report_feature_section.report_section_id
    and
      report_section.product_id = 2 -- Facilities
    and
      report_section.id = 1 -- `Parking`
    and
      report_feature.indesign_tag is null
  order by
      report_section.order_in_document
  ;

  ... missing ...

  1|Parking|155|Colour contrast and tactile floor warning indicators at pedestrian crossing points

           */

          {
            nocoDbReportFeatureId: 2,
            identifier: 'parking-payment-machines',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'Mpj2n', // // Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },
          {
            nocoDbReportFeatureId: 1,
            identifier: 'designated-accessible-parking-spaces',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'BkqAn', // // Are there designated wheelchair accessible parking spaces available?
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },
          {
            nocoDbReportFeatureId: 3,
            identifier: 'accessible-entrance-from-indoor-car-park',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'BkqA7', // // Is there an accessible entrance from the indoor car park to the main building?
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },
          {
            nocoDbReportFeatureId: 4,
            identifier: 'door-security-and-entry-systems',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'BkqBF' // From the car park, does the building entrance have an intercom or two-way communication system?
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },
          {
            nocoDbReportFeatureId: 6,
            identifier: 'doors-in-series',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'BkqBP' // 	From the car park, is the entrance to the building through two doors in a series?
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },
          {
            nocoDbReportFeatureId: 5,
            identifier: 'accessible-routes-and-crossing-points',
            inclusionCriteria: {
              ifLessThanFullScore: {
                questionKeys: [
                  'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
                  'BkqCB', // How many steps are there up to the main entrance?
                  'ajd8e', // Are handrails provided at steps?
                  'BkqCL', // What type of door is there into the main entrance?
                  'ajeoO', // How do the automatic doors open?
                  'ajhxA', // Do automatic doors that swing open have audible warnings?
                  'aji50', // What type of door handles are on the entrance door?
                  'ajkEg', // Can the entrance door be opened by someone with limited strength?
                  'BkqCf', // What is the main entrance door width measurement? (In cm)
                  'BkqCp', // Is the main entrance door made of full clear glass panels?
                  'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
                  'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
                  'BkqCV', // Is there an alternative accessible entrance?
                  'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
                  'CjEZ3', // What type of door is there into the alternative accessible entrance?
                  'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
                  'BkqBt', // Is the alternative accessible entrance clearly signposted?
                  'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
                ],
              }
            },
            capexIntensive: [],
            capexLight: [],
            complianceScoring: {},
          },

        ],
      }),
    new ReportSection({


      nocoDbReportSectionId: 2, // select id,name from report_section where id = 2;
      title: 'Building Entrance',
      overview: 'Building Entrance',
      questionKeys: [
        // 'N/A	N/A
        // 'N/A	N/A
        'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
        'BkqCB', // How many steps are there up to the main entrance?
        'ajd8e', // Are handrails provided at steps?
        'BkqCL', // What type of door is there into the main entrance?
        'ajeoO', // How do the automatic doors open?
        'ajhxA', // Do automatic doors that swing open have audible warnings?
        'aji50', // What type of door handles are on the entrance door?
        'ajkEg', // Can the entrance door be opened by someone with limited strength?
        'BkqCf', // What is the main entrance door width measurement? (In cm)
        'BkqCp', // Is the main entrance door made of full clear glass panels?
        'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
        'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
        'BkqCV', // Is there an alternative accessible entrance?
        'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
        'CjEZ3', // What type of door is there into the alternative accessible entrance?
        'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
        'BkqBt', // Is the alternative accessible entrance clearly signposted?
        'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
      ],
      identifier: 'building-entrance',
      features: [

        /*
      select
      report_section.id as section_id,
      report_section.name as section_name,
      report_feature.id as feature_id,
      report_feature.name as feature_name
      from
      report_feature, report_feature_section, report_section
      where
      report_feature.id = report_feature_section.report_feature_id
      and
      report_section.id = report_feature_section.report_section_id
      and
      report_section.product_id = 2 -- Facilities
      and
      report_section.id = 2 -- `Building Entrances`
      and
      report_feature.indesign_tag is null
      order by
      report_section.order_in_document
      ;



         */


        // {
        //   identifier: 'building-entrances',
        //   inclusionCriteria: {
        //     ifLessThanFullScore: {
        //       questionKeys: [
        //         'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
        //         'BkqCB', // How many steps are there up to the main entrance?
        //         'ajd8e', // Are handrails provided at steps?
        //         'BkqCL', // What type of door is there into the main entrance?
        //         'ajeoO', // How do the automatic doors open?
        //         'ajhxA', // Do automatic doors that swing open have audible warnings?
        //         'aji50', // What type of door handles are on the entrance door?
        //         'ajkEg', // Can the entrance door be opened by someone with limited strength?
        //         'BkqCf', // What is the main entrance door width measurement? (In cm)
        //         'BkqCp', // Is the main entrance door made of full clear glass panels?
        //         'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
        //         'BkqC9', // Please take 2 photos of the main entrance (in landscape mode)
        //         'BkqCV', // Is there an alternative accessible entrance?
        //         'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
        //         'CjEZ3', // What type of door is there into the alternative accessible entrance?
        //         'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
        //         'BkqBt', // Is the alternative accessible entrance clearly signposted?
        //         'Cjk03', // Please take 2 photos of the alternative accessible entrance (in landscape mode)
        //       ],
        //     }
        //   }
        // },
        {
          nocoDbReportFeatureId: 7,
          identifier: 'step-free-access-at-main-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqB3' // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 8,
          identifier: 'number-of-steps-up-to-the-main-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqCB', // How many steps are there up to the main entrance?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 9,
          identifier: 'handrails-on-steps-and-ramps',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajd8e', // Are handrails provided at steps?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 10,
          identifier: 'main-entrance-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqCL', // What type of door is there into the main entrance?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 12,
          identifier: 'type-of-automatic-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajeoO', // How do the automatic doors open?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 13,
          identifier: 'audible-warnings-for-automatic-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajhxA', // Do automatic doors that swing open have audible warnings?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 15,
          identifier: 'type-of-door-handles-at-main-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'aji50', // What type of door handles are on the entrance door?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 16,
          identifier: 'door-opening-force',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajkEg', // Can the entrance door be opened by someone with limited strength?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 19, // 11 ???
          identifier: 'door-width-of-main-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqCf', // What is the main entrance door width measurement? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 14,
          identifier: 'glass-entrance-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqCz', // Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 17,
          identifier: 'step-free-access-at-alternative-accessible-entran',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CjAUx', // Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 18,
          identifier: 'alternative-accessible-entrance-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CjEZ3', // What type of door is there into the alternative accessible entrance?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 19,
          identifier: 'door-width-of-alternative-accessible-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CjFQt', // What is the alternative accessible entrance door width measurement? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 20,
          identifier: 'accessible-entrance-clearly-signposted',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkqBt', // Is the alternative accessible entrance clearly signposted?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 3, // select id,name from report_section where id = 3;
      title: 'Reception Area',
      overview: 'Reception Area',

      questionKeys: [
        'Bkve7', // Is there a lower counter/desk in the reception area?
        'BkvfF', // Are assistive listening systems provided at the reception area? (eg. Audio Induction Loop)
        'C9KCH', // Is there visitor seating provided at the reception area?
        'BkvfP', // Is there directional and informational signage in the reception area?
        'Mpj2x', // Are tactile maps available in the reception/lobby area?
        'Bkvfj', // Are guide/service dogs welcome on the premises?
        'BkvfZ', // Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)
      ],
      identifier: 'reception-area',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 3 -- `Reception Area`
      and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 21,
          identifier: 'lower-counter-or-desk-at-reception-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bkve7', // Is there a lower counter/desk in the reception area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 22,
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkvfF', // Are assistive listening systems provided at the reception area? (eg. Audio Induction Loop)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 129,
          identifier: 'seating-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'C9KCH', // Is there visitor seating provided at the reception area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 24,
          identifier: 'directional-and-informational-signage',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkvfP', // Is there directional and informational signage in the reception area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 25,
          identifier: 'tactile-maps-and-floor-plans',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj2x', // Are tactile maps available in the reception/lobby area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 26,
          identifier: 'guide-or-service-dogs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bkvfj', // Are guide/service dogs welcome on the premises?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 52, // select id,name from report_section where id = 52;
      title: 'Facilities',
      overview: 'Facilities',

      questionKeys: [
        'BkyVv', // Are baby changing facilities available on the premises?
        'BkyV5', // Is there a baby feeding room on the premises?
        'BkyWD', // Are there cloakrooms available on the premises?
        'BkyWN', // Are some coat hooks reacheable from a seated position?
        'BkyWX', // Are there storage lockers available on the premises?
        'BkyWh', // Are there storage lockers available at different heights?
        'Mpj4z', // Are there designated quiet spaces or rooms in the building?
        'BkyWr', // Are there gym facilities on the premises?
        'BkyW1', // Is there step-free access into the gym? This could be level, ramp, or lift access.
        'Mpj4L', // Are there vending machines in the building?
        'Mpj4V', // Is the control panel on the vending machine operable from a seated position?
      ],
      identifier: 'facilities',
      features: [
        /*
        select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 52 -- `Facilities`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          identifier: 'baby-changing-facilities',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkyVv', // Are baby changing facilities available on the premises?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 28,
          identifier: 'breastfeeding-room',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkyV5', // Is there a baby feeding room on the premises?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 29,
          identifier: 'wheelchair-accessible-cloakroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkyWN', // Are some coat hooks reacheable from a seated position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 30,
          identifier: 'storage-lockers-at-varying-heights',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkyWh', // Are there storage lockers available at different heights?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 31,
          identifier: 'designated-quiet-spaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj4z', // Are there designated quiet spaces or rooms in the building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 32,
          identifier: 'gym-or-fitness-facilities',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkyW1', // Is there step-free access into the gym? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 33,
          identifier: 'vending-machines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj4V', // Is the control panel on the vending machine operable from a seated position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: -1, // select id,name from report_section where id = -1;
      title: 'Hallways and Corridors/Internal Routes',
      overview: 'Hallways and Corridors/Internal Routes',
      questionKeys: [
        'Bky5D', // Are all main routes clearly defined to assist the orientation of people with visual impairments? For example, by using colour contrast between wall and floor surfaces.
        'Bky5N', // Is there suitable and sufficient lighting providing good illumination throughout the building?
        'Bky5X', // Please take 1 photo of a typical main route/corridor (in landscape mode)

      ],
      identifier: 'hallways-and-corridors',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 53 -- `Hallways and Corridors/Internal Routes`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */

        {
          nocoDbReportFeatureId: 34,
          identifier: 'accessible-routes',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bky5D', // Are all main routes clearly defined to assist the orientation of people with visual impairments? For example, by using colour contrast between wall and floor surfaces.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'lighting-to-facilitate-wayfinding',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bky5N', // Is there suitable and sufficient lighting providing good illumination throughout the building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 36,
          identifier: 'good-lighting-throughout-the-building',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bky5N', // Is there suitable and sufficient lighting providing good illumination throughout the building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 6, // select id,name from report_section where id = 6;
      title: 'Cafeteria & Food Services',
      overview: 'Cafeteria & Food Services',
      questionKeys: [
        'BkzJT', // Is there a cafeteria on the premises?
        'BkzJd', // Is there step-free access into the cafeteria? This could be level, ramp, or lift access.
        'BkzJn', // Are the tables and chairs movable or fixed to the floor?
        'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
        'Mpj27', // What type of cafeteria service is provided?
        'Mpj3F', // What is the height of the food counters from the floor to the top of the counter?
        'Mpj33', // Are there food display cabinets or shelves where food and drink products are on display for purchase?
        'Mpj4B', // Are the food and drink products displayed vertically on shelves and in cabinets?
        'ajlli', // Are menus available in print?
        'ajluw', // Are menus available in larger print?
        'ajl5E', // Are menus available in digital format?
        'ajmCw', // Are menus available in braille?
        'BkzJx', // Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?
        // 'N/A	N/A
        'BkzJw', // Please take 3 photos of the cafeteria from different angles (in landscape mode)
      ],
      identifier: 'cafeteria-and-food-services',
      features: [

        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 6 -- `Cafeteria and Food Services`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */

        {
          nocoDbReportFeatureId: 130,
          identifier: 'step-free-access-to-cafeteria-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzJd', // Is there step-free access into the cafeteria? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 38,
          identifier: 'movable-tables-and-chairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzJn', // Are the tables and chairs movable or fixed to the floor?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 39,
          identifier: 'wide-aisle-space',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'self-service-and-food-counters',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj3F', // What is the height of the food counters from the floor to the top of the counter?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 41,
          identifier: 'display-cabinets',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj4B', // Are the food and drink products displayed vertically on shelves and in cabinets?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 42,
          identifier: 'menus-in-alternative-formats',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajlli', // Are menus available in print?
                'ajluw', //	Are menus available in larger print?
                'ajl5E', //	Are menus available in digital format?
                'ajmCw', //	Are menus available in braille?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 43,
          identifier: 'table-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzJx', // Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'acoustics-in-the-cafeteria',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'N/A', //	N/A
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 7, // select id,name from report_section where id = 7;
      title: 'Breakout Areas',
      overview: 'Breakout Areas',
      questionKeys: [
        'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
        'Cmgc1', // Is there step-free access into some of the breakout areas? This could be level, ramp, or lift access.
        'CmgpB', // Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
        'CmgyZ', // Can the sink be reached from a seated position in the most accessible breakout area?
        'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
        'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
        'Cmie_', // Do kitchen appliances colour contrast with countertops in the most accessible breakout area?
        'BkzJ7', // Please take 3 photos of the most accessible breakout area from different angles (in landscape mode)
      ],
      identifier: 'breakout-spaces',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 7 -- `Breakout Spaces`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          identifier: 'breakout-areas-that-include-a-kitchenette-or-refres',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 131,
          identifier: 'step-free-access-to-breakout-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cmgc1', // Is there step-free access into some of the breakout areas? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 46,
          identifier: 'appliances-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CmgpB', // Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 47,
          identifier: 'kitchen-sink-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CmgyZ', // Can the sink be reached from a seated position in the most accessible breakout area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 48,
          identifier: 'cupboards-or-cabinets-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 49,
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 50,
          identifier: 'colour-contrasting-appliances',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cmie_', // Do kitchen appliances colour contrast with countertops in the most accessible breakout area?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 8, // select id,name from report_section where id = 8;
      title: 'Bar/Lounge Area',
      overview: 'Bar/Lounge Area',
      questionKeys: [
        'QZ_lT', // Is there a bar or lounge that serves beverages in the office?
        'QZ_ob', // Is there step-free access into the bar/lounge? This could be level, ramp, or lift access.
        'QZ_pD', // Is any part of the bar counter set at a lower height?
        'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
        'QZ_qx', // Are some lower tables provided where wheelchair users or persons of short stature can rest their plates and drinks?
        'QZ_rZ', // Is there at least 91cm of clear aisle space around some tables and chairs?
        'QZ_sf', // Please take 2 photo of the most accessible bar or lounge area (in landscape mode)
      ],
      identifier: 'bar-lounge',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 8 -- `Bar/Lounge Area`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;

         */
        {
          nocoDbReportFeatureId: 51,
          identifier: 'step-free-access-to-bar-or-lounge',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'QZ_ob', // Is there step-free access into the bar/lounge? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 52,
          identifier: 'lower-bar-counter',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'QZ_pD', // Is any part of the bar counter set at a lower height?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 53,
          identifier: 'movable-tables-and-chairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 54,
          identifier: 'table-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'QZ_qx', // Are some lower tables provided where wheelchair users or persons of short stature can rest their plates and drinks?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 55,
          identifier: 'wide-aisle-space',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'QZ_rZ', // Is there at least 91cm of clear aisle space around some tables and chairs?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 9, // select id,name from report_section where id = 9;
      title: 'Event Spaces',
      overview: 'Event Spaces',
      questionKeys: [
        'Bkzpp', // Is there an auditorium in the building?
        'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
        'Mpj4f', // Is there a clear line of sight from the wheelchair designated space to the presenter in the auditorium?
        'Bkzp9', // Are assistive listening systems provided in the auditorium? (eg. Audio Induction Loop)
        'BkzqH', // Is there a text display or subtitles option on presentation screens provided in the auditorium?
        'Mpj4p', // Is there space for a guide/service dog in the auditorium?
        'Bkzqb', // Please take 2 photos of the auditorium from different angles (in landscape mode)
      ],
      identifier: 'event-spaces',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 9 -- `Event Spaces`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 56,
          identifier: 'designated-spaces-for-wheelchair-users',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 57,
          identifier: 'sight-lines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj4f', // Is there a clear line of sight from the wheelchair designated space to the presenter in the auditorium?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 58,
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bkzp9', // Are assistive listening systems provided in the auditorium? (eg. Audio Induction Loop)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 59,
          identifier: 'presentation-screens-text-display-or-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzqH', // Is there a text display or subtitles option on presentation screens provided in the auditorium?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 60,
          identifier: 'guide-or-service-dogs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj4p', // Is there space for a guide/service dog in the auditorium?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 10, // select id,name from report_section where id = 10;
      title: 'Stairs and Lifts/Elevators',
      overview: 'Stairs and Lifts/Elevators',
      questionKeys: [
        'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        'BkzKF', // Are there flights of stairs within the building?
        'BkzKP', // Do the stairs have handrails that colour contrast with the surrounding walls?
        'ajmM6', // Are internal stairs well lit?
        'BkzeF', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
        'Bkz__', // Is there a lift/elevator in the building?
        'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
        'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
        'ajm9M', // Do the lift/elevator call buttons have visual indicators?
        'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
        'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
        'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
        'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
        'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
        'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
        'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
        'ajnX6', // Does the emergency alarm in the lift/elevator have braille or raised letters?
        'Bk0BP', //
        'XP_vG', // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
        'TgYOI', // Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?
        'Bk0BP', // Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)

      ],
      identifier: 'stairs-and-lifts',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 10 -- `Stairs and Lifts/Elevators`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {

          identifier: 'stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzKF', // Are there flights of stairs within the building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {

          identifier: 'colour-contrasting-handrails-on-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzKP', // Do the stairs have handrails that colour contrast with the surrounding walls?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 61,
          identifier: 'appropriate-light-levels-on-internal-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajmM6', // Are internal stairs well lit?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 63,
          identifier: 'detectable-warning-indicators-on-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'BkzeF', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 64,
          identifier: 'step-free-route-to-lift-or-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0AJ', // Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 65,
          identifier: 'lift-or-elevator-call-buttons',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajmsU', // Are the lift/elevator call buttons reachable from a seated position and free from obstructions?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 66,
          identifier: 'visual-indicators-on-lift-or-elevator-call-buttons',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajm9M', // Do the lift/elevator call buttons have visual indicators?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 69,
          identifier: 'floor-position-indicator-inside-lift-or-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajnKo', // Inside the lift/elevator, are there visual indicators showing the current floor position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 67,
          identifier: 'lift-or-elevator-measurements',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0AT', // What is the "door width" of the most accessible lift/elevator? (In cm)
                'Bk0An', // What is the "internal width" of the most accessible lift/elevator? (In cm)
                'Bk0Ax', // What is the "internal depth" of the most accessible lift/elevator? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 70,
          identifier: 'voice-announcements-in-the-lift-or-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0A7', // Are there voice announcements for each floor in the most accessible lift/elevator?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 68,
          identifier: 'raised-numbers-braille-or-symbols-on-lift-buttons',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0BF', // Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'colour-contrasting-emergency-alarm-in-lift-or-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CmlfV', // Is there a colour contrasted alarm button in the most accessible lift/elevator?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'symbols-on-lift-or-elevator-emergency-alarm',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajnX6', // Does the emergency alarm in the lift/elevator have braille or raised letters?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 73,
          identifier: 'designated-evacuation-lift-or-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'XP_vG', // Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 72,
          identifier: 'clear-signage-indicate-lifts-usable-emergency',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'TgYOI', // Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({


      nocoDbReportSectionId: 11, // select id,name from report_section where id = 11;
      title: 'Office Work Stations',
      overview: 'Office Work Stations',
      questionKeys: [
        // 'N/A	N/A
        'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
        'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
        'Bk2Br', // Are some office desks height adjustable?
        'Bk2BX', // Are some switches and sockets reachable from a seated position?
        'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
        'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
        // 'N/A	N/A
        'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)

      ],
      identifier: 'office-workstations',
      features: [

        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 11 -- `Office Workstations`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */

        {
          identifier: 'workstations',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
                'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
                'Bk2Br', // Are some office desks height adjustable?
                'Bk2BX', // Are some switches and sockets reachable from a seated position?
                'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
                'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
                'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'floor-surfaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'desk-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2Br', // Are some office desks height adjustable?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'switches-and-sockets-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2BX', // Are some switches and sockets reachable from a seated position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'colour-contrasting-switches-and-sockets',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'print-stations-and-photocopying-machines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'light-levels-at-work-stations',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2BD', // Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?
                'Bk2BN', // Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.)
                'Bk2Br', // Are some office desks height adjustable?
                'Bk2BX', // Are some switches and sockets reachable from a seated position?
                'Bk2Bh', // Do some switches and sockets colour contrast to the background which they are mounted against?
                'Bk2B_', // Are some printers and photocopying machines operable from a seated position?
                'Bk2B1', // Please take 2 photos of the most accessible office workstation area from different angles (in landscape mode)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 12, // select id,name from report_section where id = 12;
      title: 'Meeting Rooms',
      overview: 'Meeting Rooms',
      questionKeys: [
        'Bk0fj', // Are there meeting rooms on the premises?
        'Bk0ft', // How many of the meeting rooms are wheelchair accessible?
        'Bk0f3', // Is there braille, raised lettering, or appropriate symbols on the meeting room signage?
        'Bk0gB', // What is the door width measurement of the most accessible meeting room? (In cm)
        'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?
        'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
        'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
        'Bk0gf', // Are assistive listening systems provided in any of the meeting rooms? (eg. Audio Induction Loop)
        'Cmlsx', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?
        'Bk0gp', // Please take 1 photo of the most accessible meeting room (in landscape mode)
      ],
      identifier: 'meeting-rooms',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 12 -- `Meeting Rooms`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 180,
          identifier: 'meeting-room-signage',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0f3', // Is there braille, raised lettering, or appropriate symbols on the meeting room signage?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 81,
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0gB', // What is the door width measurement of the most accessible meeting room? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 82,
          identifier: 'door-opening-force',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 83,
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 84,
          identifier: 'wide-aisle-space',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 85,
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk0gf', // Are assistive listening systems provided in any of the meeting rooms? (eg. Audio Induction Loop)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 86,
          identifier: 'captioning-and-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cmlsx', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 13, // select id,name from report_section where id = 13;
      title: 'Training Rooms',
      overview: 'Training Rooms',
      questionKeys: [
        'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
        'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
        'Bk1db', // Is there braille, raised lettering, or appropriate symbols on the training room signage?
        'Bk1dl', // What is the width of the most accessible training room door? (In cm)
        'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?
        'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
        'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
        'Bk1eD', // Are assistive listening systems provided in any of the training rooms? (eg. Audio Induction Loop)
        'Bk1eN', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?
        'Bk1eX', // Please take 1 photo of the most accessible training room (in landscape mode)
      ],
      identifier: 'training-rooms',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 13 -- `Training Rooms`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 87,
          identifier: 'training-room-signage',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1db', // Is there braille, raised lettering, or appropriate symbols on the training room signage?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 88,
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1dl', // What is the width of the most accessible training room door? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 89,
          identifier: 'door-opening-force',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 90,
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 91,
          identifier: 'wide-aisle-space',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Mpj3t', // Is there at least 91cm of clear aisle space around some tables and chairs?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 92,
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1eD', // Are assistive listening systems provided in any of the training rooms? (eg. Audio Induction Loop)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 93,
          identifier: 'captioning-and-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk1eN', // Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 14, // select id,name from report_section where id = 14;
      title: 'General Bathrooms/Restrooms',
      overview: 'General Bathrooms/Restrooms',
      questionKeys: [
        'Bkvf3', // Do the general bathrooms have braille, raised lettering, or appropriate symbols to identify the bathroom type?
        'Cjlgx', // What type of handles are on the general bathroom entrance doors?
        'D1sTp', // What type of door locks are on the general bathroom doors?
        'Cjp6z', // What is the width of the general bathroom entrance doors? (In cm)
        'Cjp_z', // Can you see yourself in the mirror from a seated position in the general bathrooms?
        'Cjwul', // What type of tap handles are on the sink in the general bathrooms?
        'CjqNt', // Is there a sink at a lower height in the general bathrooms?
        'CjqVr', // What is the height of the lower sink in the general bathrooms?
      ],
      identifier: 'general-bathrooms',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 14 -- `General Bathrooms/Restrooms`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 94,
          identifier: 'signage-on-general-bathroom-or-restroom-door',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bkvf3', // Do the general bathrooms have braille, raised lettering, or appropriate symbols to identify the bathroom type?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 95,
          identifier: 'type-of-door-handles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cjlgx', // What type of handles are on the general bathroom entrance doors?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 97,
          identifier: 'door-locks',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'D1sTp', // What type of door locks are on the general bathroom doors?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 96,
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cjp6z', // What is the width of the general bathroom entrance doors? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 98,
          identifier: 'mirror-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cjp_z', // Can you see yourself in the mirror from a seated position in the general bathrooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 99,
          identifier: 'type-of-handles-on-sink-or-washbasins',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Cjwul', // What type of tap handles are on the sink in the general bathrooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 100,
          identifier: 'sink-or-washbasin-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CjqNt', // Is there a sink at a lower height in the general bathrooms?
                'CjqVr', // What is the height of the lower sink in the general bathrooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 15, // select id,name from report_section where id = 15;
      title: 'Accessible Bathrooms/Restrooms\n',
      overview: 'Accessible Bathrooms/Restrooms',
      questionKeys: [
        'Bk2e5', // "Is there a wheelchair accessible bathroom on the premises for employees?"
        'Bk2fD', // How many wheelchair accessible bathrooms are on the premises?
        'Bk2fN', // Do the accessible bathroom doors have braille, raised lettering, or appropriate symbols to identify the bathroom type?
        'Bk2fX', // Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?
        'Bk2fr', // What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)
        'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
        'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
        'Bk2f_', // In the most wheelchair accessible bathroom, what is the width of the door? (In cm)
        'Bk2gJ', // In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?
        'Bk2gT', // In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)
        'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
        'Bk2gn', // In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet?
        'ajzYg', // In the most accessible bathroom, is the toilet flush handle located beside a wide clear floor space?
        'Bk2gx', // In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?
        'CjqNt', // Is there a sink at a lower height in the accessible bathroom?
        'CjqVr', // What is the height of the lower sink in the accessible bathrooms?
        'Bk2g7', // In the most wheelchair accessible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?
        'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
        'Bk2hP', // In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?
        'Bk2hZ', // Is there a roll-in shower on the premises?
        'Bk2hj', // Please take 2 photos of the most  accessible bathroom/restroom from different angles (in landscape mode)

      ],
      identifier: 'accessible-bathrooms',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 15 -- `Accessible Bathrooms/Restrooms`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */

        {
          nocoDbReportFeatureId: 101,
          identifier: 'wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2e5', // "Is there a wheelchair accessible bathroom on the premises for employees?"
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 102,
          identifier: 'signage-on-wheelchair-accessible-bathroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2fN', // Do the accessible bathroom doors have braille, raised lettering, or appropriate symbols to identify the bathroom type?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 103,
          identifier: 'two-doors-in-series-into-wheelchair-accessible',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2fr', // What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 104,
          identifier: 'type-of-door-handles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 105,
          identifier: 'door-locks',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 106,
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2f_', // In the most wheelchair accessible bathroom, what is the width of the door? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 115,
          identifier: 'mirror-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2gJ', // In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 107,
          identifier: 'toilet-seat-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2gT', // In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 108,
          identifier: 'flush-controls',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'wide-clear-floor-space-beside-toilet',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2gn', // In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 110,
          identifier: 'location-of-toilet-flush-handle',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'ajzYg', // In the most accessible bathroom, is the toilet flush handle located beside a wide clear floor space?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 111,
          identifier: 'grab-bar-beside-the-toilet',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2gx', // In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 112,
          identifier: 'sink-or-washbasin-at-accessible-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'CjqVr', // What is the height of the lower sink in the accessible bathrooms?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 113,
          identifier: 'clear-space-beneath-the-sink-or-washbasin',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2g7', // In the most wheelchair accessible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 114,
          identifier: 'type-of-handles-on-sink-or-washbasin',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2hF', // In the most wheelchair accessible bathroom, what type of handle is on the sink?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 116,
          identifier: 'emergency-assistance-alarm-bell-or-cord',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2hP', // In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 117,
          identifier: 'wet-room-or-roll-in-shower',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk2hZ', // Is there a roll-in shower on the premises?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 16, // select id,name from report_section where id = 16;
      title: 'Safety and Evacuation',
      overview: 'Safety and Evacuation',
      questionKeys: [
        // 'N/A	N/A
        'Bk3dP', // Does the fire alarm system have visible flashing lights?
        'Bk3dZ', // Is there an emergency evacuation procedure available for people with accessibility needs?
        'Bk3dj', // Is emergency evacuation information available in both visual and audible formats?
        'Bk3dt', // Is there an emergency evacuation chair in the building?
        'XQATW', // In the case of fire or an emergency, are areas of refuge provided in your building?
        'TgYWG', // Are all emergency exits clearly signposted?
      ],
      identifier: 'safety-and-evacuation',
      features: [
        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 16 -- `Safety and Evacuation`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          identifier: 'personal-emergency-evacuation-plan-peeps',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3dP', // Does the fire alarm system have visible flashing lights?
                'Bk3dZ', // Is there an emergency evacuation procedure available for people with accessibility needs?
                'Bk3dj', // Is emergency evacuation information available in both visual and audible formats?
                'Bk3dt', // Is there an emergency evacuation chair in the building?
                'XQATW', // In the case of fire or an emergency, are areas of refuge provided in your building?
                'TgYWG', // Are all emergency exits clearly signposted?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 119,
          identifier: 'fire-alarm-with-a-visual-indicator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3dP', // Does the fire alarm system have visible flashing lights?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 120,
          identifier: 'emergency-evacuation-procedure',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3dZ', // Is there an emergency evacuation procedure available for people with accessibility needs?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 122,
          identifier: 'evacuation-information-in-visual-and-audible',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3dj', // Is emergency evacuation information available in both visual and audible formats?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 123,
          identifier: 'emergency-evacuation-chair',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3dt', // Is there an emergency evacuation chair in the building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'accessible-areas-of-refuge',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'XQATW', // In the case of fire or an emergency, are areas of refuge provided in your building?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 121,
          identifier: 'emergency-exits-clearly-signposted',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'TgYWG', // Are all emergency exits clearly signposted?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 17, // select id,name from report_section where id = 17;
      title: 'Disability Awareness Training',
      overview: 'Disability Awareness Training',
      questionKeys: [
        'Bk3d3' // Do staff receive disability awareness training?
        // 'N/A	N/A
      ],
      identifier: 'disability-awareness-training',
      features: [

        /*
select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 17 -- `Disability Awareness Training`
  and
    report_feature.indesign_tag is null
order by
    report_section.order_in_document
;
         */
        {
          nocoDbReportFeatureId: 125,
          identifier: 'disability-awareness-training',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3d3' // Do staff receive disability awareness training?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          nocoDbReportFeatureId: 126,
          identifier: 'training-resources',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3d3' // Do staff receive disability awareness training?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      nocoDbReportSectionId: 18, // select id,name from report_section where id = 18;
      title: 'Accessibility Certification',
      overview: 'Accessibility Certification',
      questionKeys: [
        'Bk3eB', // Does the building have an accessibility compliance certification, inclusion or universal design standards, building code accreditation?
        'DHYrn', // What type of accessibility certification, inclusion or universal design standards accreditation does the building have?
      ],
      identifier: 'accessibility-certification',
      features: [
        /*

select
    report_section.id as section_id,
    report_section.name as section_name,
    report_feature.id as feature_id,
    report_feature.name as feature_name
from
    report_feature, report_feature_section, report_section
where
    report_feature.id = report_feature_section.report_feature_id
  and
    report_section.id = report_feature_section.report_section_id
  and
    report_section.product_id = 2 -- Facilities
  and
    report_section.id = 18 -- `Accessibility Certification`
order by
    report_section.order_in_document
;

         */
        {
          identifier: 'accessibility-of-buildings1',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'Bk3eB', // Does the building have an accessibility compliance certification, inclusion or universal design standards, building code accreditation?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
        {
          identifier: 'accessibility-of-buildings2',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [
                'DHYrn', // What type of accessibility certification, inclusion or universal design standards accreditation does the building have?
              ],
            }
          },
          capexIntensive: [],
          capexLight: [],
          complianceScoring: {},
        },
      ],
    }),
  ];

}

