import {IFirebaseConnection} from "../FirebaseConnection";
import {EvaluationStatus, IEvaluationState} from "../../model/evaluation/EvaluationStatus";
import {LoggerFactory} from "../../log/LoggerFactory";


export class FirebaseEvaluationState {


  private static _log = LoggerFactory.build( 'FirebaseEvaluationState' );

  private static _getPath( productId: string, propertyKey: string ) {

    return `/evaluation/${productId}/${ propertyKey }`;
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, productId: string, propertyKey: string, evaluationState: IEvaluationState ) {

    const path = FirebaseEvaluationState._getPath( productId, propertyKey );

    await firebaseConnection.database2.setValue<IEvaluationState>( path, evaluationState );

  }

  static async read( firebaseConnection: IFirebaseConnection, productId: string, propertyKey: string): Promise<EvaluationStatus|null> {

    const path = FirebaseEvaluationState._getPath( productId, propertyKey );

    const value = await firebaseConnection.database2.getValue<IEvaluationState>( path );

    if ( value ) {

      return new EvaluationStatus( value );
    }
    return null;
  }

  static async readAll( firebaseConnection: IFirebaseConnection, productId: string ): Promise<{[key: PropertyKey]: EvaluationStatus}> {

    const answer: {[key: string]: EvaluationStatus} = {};

    const path = `/evaluation/${productId}`;

    const value: {[key: string]: IEvaluationState} = await firebaseConnection.database2.getValue<{[key: PropertyKey]: IEvaluationState}>( path );

    this._log.debug( 'path', path, 'value',  value);

    for( const key of Object.keys( value ) ) {

      answer[key] = new EvaluationStatus( value[key] );
    }

    return answer;

  }

}
