
import {ValueObject} from "../ValueObject";
import {QuestionKey} from "../QuestionKey";
import {IGuard} from "../guard/Guard";
import {ScoreRuleKey} from "./ScoreRuleKey";

export interface IScoreRule {

  key: ScoreRuleKey;
  applicableQuestions: QuestionKey[];
  description: string;
  guard: IGuard;
  policy: 'fullMarks'|'noMarks';
}


export class ScoreRule extends ValueObject<IScoreRule>{



  protected onSetValue(value: IScoreRule | null) {
  }

  constructor( value: IScoreRule ) {

    super( value );
  }

}
