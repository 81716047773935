import {DevelopmentConsoleLogger} from "./DevelopmentConsoleLogger";
import {ILogger} from "./Logger";

export interface ILoggerFactory {

  build(logContainer: string): ILogger;
}

export class LoggerFactory {

  private static factory: ILoggerFactory = DevelopmentConsoleLogger.factory;

  static build(logContainer: string): ILogger {

    return LoggerFactory.factory.build(logContainer);
  }


  static init(factory: ILoggerFactory) {

    LoggerFactory.factory = factory;
  }

  private constructor() {
  }


}
