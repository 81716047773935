export class ImageHelper {


  static resolve(image: string): string {

    if (image) {


      if( 0 === image.indexOf( 'EvaluationQuestionImage/')) {

        return `assets/${image}`;
      }

      if( -1 !== image.indexOf( '/EvaluationQuestionImage/SVG')) {

        return image;
      }


      if (0 === image.indexOf(`artdept.ie/`)) {

        return `assets/${image}`;
      }

      if( 0 === image.indexOf(`facilities-help-images/`)) {

        return `assets/${image}`;
      }

      if( 0 === image.indexOf( 'facilities-help-images.2022-08-05/')) {

        return `assets/${image}`;
      }

      // old school ...
      return `assets/help-images/${image}`;
    }

    return image;
  }
}
