

<div *ngIf="!answers">
  Loading ...
</div>


<div *ngIf="answers">


  <div *ngFor="let answer of answers">

    <div [ngSwitch]="answer.question.value.type">

<!--      <div *ngIf="includeQuestionId" style="user-select: text">-->
<!--        <code>-->
<!--          {{answer.question.value.key}} ({{answer.question.value.nocoDbId}})-->
<!--        </code>-->
<!--      </div>-->

      <question-boolean *ngSwitchCase="questionTypes.TYPE_BOOLEAN"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
      >
      </question-boolean>

      <question-centimeters *ngSwitchCase="questionTypes.TYPE_CM_MEASUREMENT"
                            [answer]="answer"
                            [showQuestionId]="showQuestionId"
      >
      </question-centimeters>

      <question-enum *ngSwitchCase="questionTypes.TYPE_ENUM"
                     [answer]="answer"
                     [showQuestionId]="showQuestionId"
      >
      </question-enum>


      <question-integer *ngSwitchCase="questionTypes.TYPE_INTEGER"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
      >
      </question-integer>


      <div *ngSwitchCase="questionTypes.TYPE_LINE">
        TYPE_LINE
      </div>


      <question-photo *ngSwitchCase="questionTypes.TYPE_PHOTO"
                      [answer]="answer"
                      [propertyKey]="propertyKey"
                      [showQuestionId]="showQuestionId"
      >
      </question-photo>

      <question-ternary *ngSwitchCase="questionTypes.TYPE_TERNARY"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
      >
      </question-ternary>

      <question-text *ngSwitchCase="questionTypes.TYPE_TEXT"
                     [questionAnswer]="answer"
                     [showQuestionId]="showQuestionId"
      >
      </question-text>

    </div>
  </div>

</div>
