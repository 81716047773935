

<div fxLayout="column" fxLayoutGap="10px" style="padding-top: 10px; padding-bottom: 10px;">

  <div *ngIf="sectionTitle" fxLayout="row" fxLayoutAlign="start center" >
    <div fxFlex fxFlexOffset="10px" cclass="header-text">
      <strong>
        {{sectionTitle}} {{headerText}}
      </strong>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" >
    <div fxFlex fxFlexOffset="10px">
      <mat-progress-bar mode="determinate" [value]="progress" >
      </mat-progress-bar>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" >
    <div fxFlex cclass="header-text" fxFlexOffset="10px">
      <strong>

      </strong>
    </div>
  </div>


</div>
