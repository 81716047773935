import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SideMenuComponent} from './component.side-menu/side-menu.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from "@angular/material/list";
import {ConfirmCancelDialogComponent} from "./component.confirm-cancel-dialog/confirm-cancel-dialog";
import {SignInComponent} from "./component.sign-in/sign-in";
import {MatTabsModule} from "@angular/material/tabs";
import {AlertDialogComponent} from "./component.alert-dialog/alert-dialog";
import {ModalSpinnerComponent} from "./component.modal-spinner/modal-spinner";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ToastComponent} from "./component.toast/toast";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PasswordResetComponent} from "./component.password-reset/password-reset.component";
import {DialogFooterComponent} from "./component.dialog-footer/component.dialog-footer";
import {DialogHeaderComponent} from "./component.dialog-header/component.dialog-header";
import {NotificationDialogComponent} from "./component.notification-dialog/notification-dialog";
import {AuthenticateEmailPasswordComponent} from "./component.sign-in/component.authenticate-email-password/authenticate-email-password.component";
import {
  AuthenticateEmailLinkComponent
} from "./component.sign-in/component.authenticate-email-link/authenticate-email-link.component";
import {
  AuthenticatePhoneComponent
} from "./component.sign-in/component.authenticate-phone/authenticate-phone.component";
import {
  AuthenticateMicrosoftComponent
} from "./component.sign-in/component.authenticate-microsoft/authenticate-microsoft.component";

@NgModule({
  declarations: [
    AlertDialogComponent,
    AuthenticateEmailLinkComponent,
    AuthenticateEmailPasswordComponent,
    AuthenticatePhoneComponent,
    AuthenticateMicrosoftComponent,
    ConfirmCancelDialogComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    ModalSpinnerComponent,
    NotificationDialogComponent,
    PasswordResetComponent,
    SideMenuComponent,
    SignInComponent,
    ToastComponent,
  ],
  exports: [
    DialogFooterComponent,
    DialogHeaderComponent,
    NotificationDialogComponent,
    PasswordResetComponent,
    SideMenuComponent,
    SignInComponent,
  ],
  imports: [
    // Core ...
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // angular material ...
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    // 3rd party ...
    FlexLayoutModule,
    FontAwesomeModule,
    // in-house ...
  ]
})
export class BaseComponentsModule {}
