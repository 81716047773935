

<div fxLayout="column" fxLayoutGap="10px" style="padding-top: 10px;"
>

  <div fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="33"></div>


    <div fxFlex="33" fxFlexOffset="10px" style="text-align: center;">
      <div style="position: relative">

        <div class="score-label">
          <div style="font-size: medium">ACCESSIBLE RATING</div>

          <div >{{(( score.numerator / score.denominator ) * 10 ).toFixed( 1 )}}</div>
          <span>
            <img src="{{ratingUrl}}" style="height: 32px; border-width: 1px; border-style: solid; border-color: #00000040">
          </span>
        </div>

        <round-progress
          [current]="score.numerator"
          [max]="score.denominator"
          color="#370178"
          background="#cecfd5"
        >
        </round-progress>

      </div>

    </div>

    <div fxFlex="33"></div>

  </div>

</div>




