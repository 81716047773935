


<dialog-header [title]="params.title">
</dialog-header>


<div mat-dialog-content style="" class="mat-typography">

  {{params.message}}

</div>


<dialog-footer>
  <span style="width: 8px;"></span>
  <button mat-stroked-button color="primary" (click)="onOkClick()">
    {{okLabel}}
  </button>
</dialog-footer>
