import {LogEvent} from "./LogEvent";
import {IFirebaseFirestore} from "../firebase/IFirebaseFirestore";
import {ILoggerDelegate} from "./LoggerDelegate";

export class FirestoreLoggerDelegate implements ILoggerDelegate {

  private async _setValue( documentPath: string, event: LogEvent) {

    try {

      await this._firestore.setValue( documentPath, event.value );

    } catch ( e ) {

      console.error( `'this._firestore.setValue' call failed`, 'event.value', event.value, 'e', e);
    }

  }

  handle(event: LogEvent) {


    const date = new Date( event.value.time );

    let month = `${date.getUTCMonth()+1}`;
    month = month.padStart( 2, '0' );

    const yyyymm = `${date.getUTCFullYear()}.${month}`;

    let dateStr = `${date.getUTCDate()}`;
    dateStr = dateStr.padStart( 2, '0' );

    let hours = `${date.getUTCHours()}`;
    hours = hours.padStart( 2, '0' );

    let minutes = `${date.getUTCMinutes()}`;
    minutes = minutes.padStart( 2, '0');

    let seconds = `${date.getUTCSeconds()}`;
    seconds = seconds.padStart( 2, '0');

    let millis = `${date.getUTCMilliseconds()}`;
    millis = millis.padStart( 4, '0');

    const time = `${hours}:${minutes}:${seconds}.${millis}`

    let identifier = time;
    if( event.value.correlationId ) {

      identifier = `${event.value.correlationId}.${time}`
    }

    const documentPath = `log/${yyyymm}/${dateStr}/${identifier}`;

    this._setValue( documentPath, event );
  }

  constructor(private _firestore: IFirebaseFirestore ) {
  }

}
