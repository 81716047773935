import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RegistrationProvider} from "../../../service.registration/registration";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PwaApplicationContextProvider} from "../../../service.pwa-application-context/pwa-application-context";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Router} from "@angular/router";
import {FirebaseEvaluationUser} from "../../../javascript.lib.mojo-base/firebase/realtime-database/users/FirebaseEvaluationUser";
import {EvaluationUser} from "../../../javascript.lib.mojo-base/product.facilities/EvaluationUser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastComponent} from "../../../browser.lib.evaluation-tool/module.base-components/component.toast/toast";
import {
  FirebaseConnectionService
} from "../../../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {UserPropertyView} from "../component.user-property/UserPropertyView";
import {PropertyReference} from "../../../javascript.lib.mojo-base/product.facilities/Property";
import {environment} from "../../../environments/environment";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the RegistrationComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-user-edit',
  templateUrl: 'user-edit.component.html'
})
export class UserEditComponent {

  private _log = LoggerFactory.build( 'UserEditComponent' );

  @Input() existingUsers: EvaluationUser[] = [];
  @Output() usersModified = new EventEmitter<void>();


  loadFormControls = {
    email: new FormControl(null, [ Validators.required, Validators.email ] ),
  };

  loadFormGroup = new FormGroup( this.loadFormControls );

  editFormControls = {
    fullName: new FormControl(null, [ Validators.required ] ),
  }

  editFormGroup = new FormGroup( this.editFormControls );


  @Input() properties: PropertyReference[] = [];
  userPropertyViews: UserPropertyView[] = [];



  formControlUserUid = new FormControl('', [
    Validators.required,
  ]);


  existingUser: EvaluationUser = null;


  async onLoadUser() {

    this.existingUser = null;
    const email:string = this.loadFormControls.email.value.trim();

    let loadedUser: EvaluationUser = null;
    for( const candidate of this.existingUsers ) {
      if( email.toLowerCase() === candidate.value.email.toLowerCase() ) {
        loadedUser = candidate;
        break;
      }
    }

    if( !loadedUser ) {
      this._log.error( '!loadedUser', 'email', email );
    }

    this.existingUser = await FirebaseEvaluationUser.readReference2( this.firebase, loadedUser.userUid );

    this.editFormControls.fullName.setValue( this.existingUser.fullName );



    if( !this.existingUser.value.productPropertyKeys ) {

      this._log.warn( `!this.existingUser.value.productPropertyKeys`, 'this.existingUser.value', this.existingUser.value );
      this.existingUser.value.productPropertyKeys = {};
    }

    let productPropertyKeys = this.existingUser.value.productPropertyKeys[environment.productConfig.productType];
    if( !productPropertyKeys ) {

      this._log.warn( `!productPropertyKeys`, 'this.existingUser.value', this.existingUser.value );
      productPropertyKeys = {};
      this.existingUser.value.productPropertyKeys[environment.productConfig.productType] = productPropertyKeys;
    }

    this.userPropertyViews = [];
    for( const property of this.properties ) {

      let include = false;
      if( productPropertyKeys[property.propertyKey]) {
        include = true;
      }
      this.userPropertyViews.push( new UserPropertyView( property, include ));
    }

  }


  private _getPropertyKeysForOtherProducts() {

    const answer: {[key: string]: string} = {};
    for( const productKey of Object.keys( this.existingUser.value.productPropertyKeys ) ) {

      // our environment ?
      if( productKey === environment.productConfig.productType ) {

        // then skip
        continue;
      }
      const properties = this.existingUser.value.productPropertyKeys[productKey];
      for( const propertyKey of Object.keys( properties ) ) {
        answer[propertyKey] = propertyKey;
      }
    }

    return answer;
  }


  async onSaveUser() {

    this.existingUser.setFullName( this.editFormControls.fullName.value );

    const propertyKeys: {[key: string]: string} = this._getPropertyKeysForOtherProducts();

    for( const userPropertyView of this.userPropertyViews ) {

      if( userPropertyView.include ) {
        this._log.debug( 'userPropertyView.include', 'userPropertyView.property.propertyKey', userPropertyView.property.propertyKey);
        const propertyKey = userPropertyView.property.propertyKey;
        propertyKeys[propertyKey] = propertyKey;
      }
    }

    this._log.debug( 'propertyKeys', propertyKeys );
    this.existingUser.value.propertyKeys = propertyKeys;
    this.existingUser.value.productPropertyKeys[environment.productConfig.productType] = propertyKeys;

    await FirebaseEvaluationUser.writeReference( this.firebase, this.existingUser );

    this.usersModified.emit();

    ToastComponent.show( this.snackBar, "User saved" );
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


  constructor( public router: Router,
               public registrationProvider: RegistrationProvider,
               public snackBar: MatSnackBar,
               public applicationContext: PwaApplicationContextProvider,
               public afDb: AngularFireDatabase,
               public firebase: FirebaseConnectionService
  ) {}
}
