import {Component, Input} from "@angular/core";

@Component({
  selector: 'dialog-header',
  styleUrls: ['component.dialog-header.scss'],
  templateUrl: 'component.dialog-header.html',
})
export class DialogHeaderComponent {

  @Input() title: string = "";
}

