


export class BrowserAppEvaluationToolInfo {

  public version = '2024.2.27.1';

  constructor() {
  }

}
