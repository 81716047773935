import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {Subscription} from "rxjs";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {MatDialog} from "@angular/material/dialog";
import {DialogData, QuestionTernaryDialogComponent} from "./question-ternary-dialog/question-ternary-dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";



/**
 */
@Component({
  selector: 'question-ternary',
  templateUrl: 'question-ternary.html',
  styleUrls: ['question-ternary.scss']
})
export class QuestionTernaryComponent implements OnInit, AfterViewInit, OnDestroy {


  private _log: ILogger = LoggerFactory.build( 'QuestionTernaryComponent' );

  @Input() answer: AppAnswer;
  selectedOption: IEnumeratedConstant|null = null;



  @Output() change = new EventEmitter<QuestionTernaryComponent>();


  private _dependantSubscription: Subscription|null = null;

  _disabled: boolean = false;

  @Input() showQuestionId: boolean = false;

  @Input() set disabled(value: boolean) {

    this._disabled = value;

    if( this._disabled ) {

      this.answer.value.value = null;
      this.selectedOption = null;

      // hacky way to get the subscription to trigger
      this.answer.value = this.answer.value;
    }
  }



  private _initSelectedOption() {

    if( "number" === typeof this.answer.value.value ) {

      const currentValue: number = this.answer.value.value as number;

      if( EnumeratedConstantReference.yes.codeAsNumber === currentValue ) {

        this.selectedOption = EnumeratedConstantReference.yes;
      } else if( EnumeratedConstantReference.no.codeAsNumber === currentValue ) {

        this.selectedOption = EnumeratedConstantReference.no;
      } else {

        this.selectedOption = EnumeratedConstantReference.notApplicable;
      }

    } else {

      this.answer.value.value = null;
    }
  }


  onOpenDialog(): void {

    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionTernaryDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionTernaryDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {

      this._log.debug('dialog was closed');
      this._initSelectedOption();
    });
  }


  ngOnInit(): void {

    if( this.answer.question.value.type !== AppQuestion.TYPE_TERNARY ) {

      this._log.error( 'ngOnInit', 'this.questionAnswer.question.value.type !== QuestionReference.TYPE_TERNARY' );
    }

    this._initSelectedOption();

    if( this.answer.dependant ) {

      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        this._log.debug( 'ngOnInit', dependant, 'isTruthy: ' + isTruthy );
        this.disabled = !isTruthy;
      } );
    }

  }


  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }

  ngAfterViewInit(): void {
  }

  constructor( public dialog: MatDialog ) {
  }

}
