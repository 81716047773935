import {IAppQuestion} from "../../../model/AppQuestion";
import {
  BathroomQuestions01,
  BathroomQuestions02,
  BathroomQuestions03,
  BathroomQuestions04,
  BathroomQuestions05,
  BathroomQuestions06
} from "./AccessibilityQuestions";


export class Bathroom {


  public static readonly ALL_QUESTION_VALUES_SETS: IAppQuestion[][] = [

    BathroomQuestions01.QUESTION_VALUES,
    BathroomQuestions02.QUESTION_VALUES,
    BathroomQuestions03.QUESTION_VALUES,
    BathroomQuestions04.QUESTION_VALUES,
    BathroomQuestions05.QUESTION_VALUES,
    BathroomQuestions06.QUESTION_VALUES,
  ];

  private static _QUESTIONS_ALL: IAppQuestion[] = null;

  public static getAllQuestionValues() {

    if ( null !== Bathroom._QUESTIONS_ALL ) {

      return Bathroom._QUESTIONS_ALL;
    }

    this._QUESTIONS_ALL = [];

    for ( const questionGroup of Bathroom.ALL_QUESTION_VALUES_SETS ) {
      for ( const question of questionGroup ) {

        this._QUESTIONS_ALL.push( question );
      }
    }

    return Bathroom._QUESTIONS_ALL;
  }


}
