import {IMMValueObject, FirebaseValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {AppTypedReferenceString} from "../cg/core/AppTypedReference";
import {IAppClusterAnswer} from "./AppClusterAnswer";
import {AppQuestionSet} from "../AppQuestionSet";
import {AppAnswerSet} from "../AppAnswerSet";
import {QuestionKey} from "../QuestionKey";
import {AppAnswer, IAppAnswer} from "../AppAnswer";
import {ECGReferenceType} from "../cg/core/CGReferenceType";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";



export interface IAppClusterAnswers extends IMMValueObject {

  answers: { [key: AppTypedReferenceString]: IAppClusterAnswer; };
}



export class AppClusterAnswers extends FirebaseValueObject<IAppClusterAnswers> {

  private _log: ILogger = LoggerFactory.build( 'AppClusterAnswers' );


  public static buildValue( selfId: string ): IAppClusterAnswers {

    return {
      _self: {
        id: selfId,
        type: ECGReferenceType.answer_cluster
      },
      _meta: {
        updates: []
      },
      answers: {}
    };
  }

  public static build( selfId: string ): AppClusterAnswers {

    return new AppClusterAnswers( AppClusterAnswers.buildValue( selfId ) );
  }

  public update( answerSet: AppAnswerSet ) {

    for( const answerKey of Object.keys( answerSet.answerByKey ) ) {

      const answer = answerSet.answerByKey[answerKey];
      const typedReference = answer.toAnswerReference();
      let clusterAnswer: IAppClusterAnswer = this.value.answers[typedReference.toString];
      if( !clusterAnswer ) {
        clusterAnswer = {
          _self: typedReference.value,
          _meta: {
            updates: []
          },
          answer: answer.value
        }
        this.value.answers[typedReference.toString] = clusterAnswer;
      }
      clusterAnswer.answer = answer.value;
    }
  }


  toAnswerSet( questions: AppQuestionSet ): AppAnswerSet {

    const answerSetValue: { [key: QuestionKey]: IAppAnswer; } = {}

    this._log.debug( 'this.value.answers', this.value.answers );
    this._log.debug( 'this.value', this.value );
    for( const clusterAnswerKey of Object.keys( this.value.answers )) {

      const clusterAnswer: IAppClusterAnswer =  this.value.answers[clusterAnswerKey];
      const questionKey = clusterAnswer.answer.questionKey;
      answerSetValue[questionKey] = clusterAnswer.answer;
    }

    return new AppAnswerSet( questions, answerSetValue );
  }

  constructor( value: IAppClusterAnswers ) {

    super(value);

    if( value ) {

      this.value = value;
    }
  }

}
