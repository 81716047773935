import {FirebaseValueObject, IMMValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {ECGReferenceType} from "../cg/core/CGReferenceType";
import {NocoClusterId} from "../../nocodb/model/NocoCluster";


export interface IAppCluster extends IMMValueObject {

  clusterTypeId: NocoClusterId;
  name: string;
  responsibility: 'landlord'|'tenant';
  completed: boolean;
  core: boolean;
}

export class AppCluster extends FirebaseValueObject<IAppCluster>{

  public static readonly TYPE = ECGReferenceType.cluster;


  protected onSetValue(value: IAppCluster | null) {

    super.onSetValue( value );
  }

  constructor( value: IAppCluster ) {

    super(value);

    if( value ) {

      this.value = value;
    }
  }



}
