import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Watchable} from "../javascript.lib.mojo-base/util/Watchable";
import {PwaPropertyContext} from "./PwaPropertyContext";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import firebase from 'firebase/compat/app';
import User = firebase.User;
import {FirebaseEvaluationUser} from "../javascript.lib.mojo-base/firebase/realtime-database/users/FirebaseEvaluationUser";
import {EvaluationUser} from "../javascript.lib.mojo-base/product.facilities/EvaluationUser";
import {FirebaseProperty} from "../javascript.lib.mojo-base/firebase/realtime-database/properties/FirebaseProperty";
import {PropertyReference} from "../javascript.lib.mojo-base/product.facilities/Property";
import {FirebaseAnswersListener} from "./FirebaseAnswersListener";
import {
  BaseSessionContext,
  SessionContextState
} from "../browser.lib.evaluation-tool/service.session-context/BaseSessionContext";
import {AppStorage} from "../app/AppStorage";
import {FirebaseContext} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseContext";
import {AngularFirebaseAnswers} from "../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/AngularFirebaseAnswers";
import {IFirebaseError} from "../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {DocumentReference} from "./DocumentReference";
import {
  getStorage,
  ref,
  listAll
} from "firebase/storage";
import {StorageReference} from "@firebase/storage";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import ConfirmationResult = firebase.auth.ConfirmationResult;
import {HttpClient} from "@angular/common/http";
import {EvaluationStatus, IEvaluationState} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {IProxyResponse} from "../javascript.lib.mojo-base/firebase/functions/ProxyResponse";
import {environment} from "../environments/environment";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";


export interface ISessionContextProvider<T> {

  settingUp: boolean;
  fbUser: User|null;
}


@Injectable()
export class PwaSessionContextProvider extends BaseSessionContext {

  private log = LoggerFactory.build( 'PwaSessionContextProvider' );

  public settingUp: boolean = null;


  mmUser: EvaluationUser|null = null; // null when not logged in, can be null if administrator
  mmUserWatch = new Watchable<EvaluationUser|null>();
  isAdministrator = false;

  availableProperties: PropertyReference[] = [];
  availablePropertiesWatch = new Watchable<PropertyReference[]|null>([]);

  documents: DocumentReference[] = [];


  /**
   * @deprecated use 'afDb' and 'afAuth' directly
   */
  firebaseContext: FirebaseContext = null;

  propertyContext: PwaPropertyContext|null = null; // null when not logged in, can be null if administrator

  pageParams = {

    completedPage: {

      showCongratulationsText: false
    },
  };


  private async initReferences( user: User ) {

    this.state = SessionContextState.UserDataLoading;

    this.mmUser = await FirebaseEvaluationUser.readReference( this.afDb.database, user.uid );

    this.log.debug( 'this.mmUser', this.mmUser );

    if( !this.mmUser ){

      this.log.warn( '!this.mmUser; will attempt to load from legacy data', 'user.uid', user.uid );
      this.mmUser = await FirebaseEvaluationUser.readLegacyHotelsReference( this.firebaseConnection, user.uid );
    }

    this.state = SessionContextState.UserDataLoaded;

    if( this.mmUser ) {

      this.mmUserWatch.value = this.mmUser;
      await this._initProperties();

    } else {  // admin user

      this.log.info( '!this.mmUser');
      this.state = SessionContextState.UserIsReady;
    }

    {
      const storage = getStorage();
      const storageRef: StorageReference = ref(storage);
      const path = `documents/${user.uid}/`;
      this.log.info( 'path', path );
      const documentsRef = ref( storageRef, path );
      const listing = await listAll(documentsRef);
      this.log.info( 'listing.items.length', listing.items.length );

      const documents: DocumentReference[] = [];

      for( const item of listing.items ) {
        const document = new DocumentReference( item.fullPath );
        await document.resolveStorageUrl( storageRef );
        documents.push( document );
      }
      this.documents = documents;
    }
  }


  private async _initProperties() {

    this.state = SessionContextState.PropertyDataLoading;
    const availableHotels: PropertyReference[] = [];

    let primaryProperty: PropertyReference|null= null;


    if ( !this.mmUser || !this.mmUser.value ) {

      this.log.error( '!this.hotelUser || !this.hotelUser.value' );
    } else {

      const primaryPropertyKey: string|null = this.mmUser.getPrimaryPropertyKey();

      if( primaryPropertyKey ) {

        primaryProperty = await FirebaseProperty.readReference( this.afDb.database, this.mmUser.getPrimaryPropertyKey() );

        if( primaryProperty ) {

          availableHotels.push( primaryProperty );
        }
      }



      if( this.mmUser.value.propertyKeys ) {
        for( const propertyKey of this.mmUser.propertyKeys ) {

          if( primaryPropertyKey === propertyKey ) {

            continue;
          }

          let property: PropertyReference|null= await FirebaseProperty.readReference( this.afDb.database, propertyKey );
          if( property ) {

            availableHotels.push( property );
          }
        }
      }
    }


    this.availablePropertiesWatch.value = availableHotels;
    this.availableProperties = availableHotels;

    this.state = SessionContextState.PropertyDataLoaded;

    {
      const selectedPropertyKey = AppStorage.getPropertyKey();
      if( selectedPropertyKey ) {
        for( const candidate of availableHotels ) {
          if( selectedPropertyKey === candidate.propertyKey ) {

            primaryProperty = candidate;
            break;
          }
        }
      }
    }

    if( primaryProperty ) {

      await this.selectProperty( primaryProperty );
    }

    this.state = SessionContextState.UserIsReady;
  }

  public isClusteredProperty(): boolean|null {

    if( !this.propertyContext?.property ) {
      return null;
    }
    if( this.propertyContext.property.value.clustered ) {
      return true;
    }
    return false;
  }


  public async selectProperty(property: PropertyReference ) {

    this.log.info( "selectProperty", "property.propertyKey", property.propertyKey );

    AppStorage.setPropertyKey( property.propertyKey );
    AngularFirebaseAnswers.listener = new FirebaseAnswersListener();
    this.propertyContext = await PwaPropertyContext.build( this.applicationContext, property, this.firebaseConnection );
  }




  async signInWithEmailAndPassword(email: string, password: string ): Promise<IFirebaseError|null> {

    try {

      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithEmailAndPassword( email, password );
      await this.init( userCredential.user );
      return null; // no error

    } catch (e) {

      this.log.warn('login', e );
      return e as IFirebaseError;
    }
  }

  async signInWithEmailLink(email: string, emailLink: string ): Promise<IFirebaseError|null> {

    try {

      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithEmailLink( email, emailLink );
      await this.init( userCredential.user );
      return null; // no error

    } catch (e) {

      this.log.warn('login', e );
      return e as IFirebaseError;
    }
  }

  async signInWithPhone( confirmationResult: ConfirmationResult, phoneAuthCode: string ): Promise<IFirebaseError|null> {

    try {

      const userCredential: firebase.auth.UserCredential = await confirmationResult.confirm( phoneAuthCode );
      await this.init( userCredential.user );
      return null; // no error

    } catch (e) {

      this.log.warn('login', e );
      return e as IFirebaseError;
    }
  }



  public async sendSignInLinkToEmail( email: string ): Promise<IFirebaseError|null> {



    // vvv https://firebase.google.com/docs/auth/web/email-link-auth

    // const actionCodeSettings = {
    //
    //   // url: `https://${environment.postAuthLinkDomain}/finishSignUp`,
    //   url: `https://facilities-test-8c1f9.web.app/finishSignUp`,
    //   handleCodeInApp: true,
    //   // iOS: {
    //   // },
    //   // android: {
    //   // }
    //   dynamicLinkDomain: 'facilities-test-8c1f9.web.app',
    // };

    const actionCodeSettings = {

      // url: `https://${environment.postAuthLinkDomain}/finishSignUp`,
      url: `https://localhost:10000/finishSignUp`,
      handleCodeInApp: true,
      // iOS: {
      // },
      // android: {
      // }
      // dynamicLinkDomain: 'localhost',
    };


    this.log.debug( 'actionCodeSettings.url', actionCodeSettings.url );
    this.log.debug( 'actionCodeSettings', actionCodeSettings );
    this.log.debug( 'email', email );

    try {

      await this.afAuth.sendSignInLinkToEmail( email, actionCodeSettings );
      return null; // no error

    } catch (e) {

      this.log.error( 'e', e );
      return e as IFirebaseError;

    }

    // ^^^ https://firebase.google.com/docs/auth/web/email-link-auth
  }

  async evaluationStarted(): Promise<EvaluationStatus|null> {


    if( !this.propertyContext ) {

      this.log.error( "!this.propertyContext");
      return null;
    }

    const evaluationStatus = this.propertyContext.evaluationStatus;

    if( evaluationStatus.isStarted() ) {

      this.log.error( "evaluationStatus.isStarted()");
      return this.propertyContext.evaluationStatus;
    }

    const propertyId: string = this.propertyContext.propertyKey;
    const userUid = this.fbUser.uid;
    const userEmail = this.fbUser.email;

    const proxy = await this.buildAuthenticatedProxy( this.httpClient );
    const response: IProxyResponse<IEvaluationState> = await proxy.evaluationStarted( propertyId, userUid, userEmail );
    if( "0" !== response.status ) {

      throw response.status;
    }
    this.propertyContext.evaluationStatus = new EvaluationStatus( response.payload );

    return this.propertyContext.evaluationStatus;
  }


  async evaluationCompleted(): Promise<EvaluationStatus|null> {


    if( !this.propertyContext ) {

      this.log.error( "!this.propertyContext");
      return null;
    }

    const evaluationStatus = this.propertyContext.evaluationStatus;

    if( evaluationStatus.isCompleted() ) {

      this.log.error( "evaluationStatus.isCompleted()");
      return this.propertyContext.evaluationStatus;
    }


    const propertyId: string = this.propertyContext.propertyKey;
    const userUid = this.fbUser.uid;
    const userEmail = this.fbUser.email;

    const proxy = await this.buildAuthenticatedProxy( this.httpClient );
    const response: IProxyResponse<IEvaluationState> = await proxy.evaluationCompleted( propertyId, userUid, userEmail );
    if( "0" !== response.status ) {

      throw response.status;
    }
    this.propertyContext.evaluationStatus = new EvaluationStatus( response.payload );
    return this.propertyContext.evaluationStatus;
  }


  async signOut() {

    this.fbUser = null;
    this.mmUser = null;
    this.propertyContext = null;

    return super.signOut();
  }


  private async init( user: User|null) {

    this.firebaseContext = new FirebaseContext( this.afDb, this.afAuth );

    this.fbUser = user;
    this.fbUserWatch.value = user;
    this.isAdministrator = false;
    this.propertyContext = null;


    if( null == user ) {

      this.availableProperties = [];
      this.availablePropertiesWatch.value = this.availableProperties;

      this.mmUser = null;
      // this.hotel = null;

    } else {

      this.settingUp = true;

      try {

        await this.initReferences( user );
      } finally {

        this.settingUp = false;
      }

    }

  }


    constructor( public afDb: AngularFireDatabase,
                 afAuth: AngularFireAuth,
                 private zone: NgZone,
                 private applicationContext: PwaApplicationContextProvider,
                 public firebaseConnection: FirebaseConnectionService,
                 public httpClient: HttpClient,
                 public router: Router,
                 ) {

      super( LoggerFactory.build( 'PwaSessionContextProvider' ), afAuth );

      this.state = SessionContextState.UserIsAuthenticating;

      this.afAuth.onAuthStateChanged( (value: firebase.User|null) => {

        if( value ) {

          this.state = SessionContextState.UserIsAuthenticated;
          this.init( value );
        } else {

          this.state = SessionContextState.LoggedOut;
        }

      });

  }

}
