
// vvv derived from [Base64 encode & decode (only for numbers) in JavaScript · GitHub](https://gist.github.com/alkaruno/b84162bae5115f4ca99b)
export class Base64 {

  public static SODEXO_FACILITIES_SUFFIX = 1;

  // [RFC 4648 - The Base16, Base32, and Base64 Data Encodings](https://tools.ietf.org/html/rfc4648#section-5)
  static readonly ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

  static encode(value: number) {

    let result = '', mod;
    do {
      mod = value % 64;
      result = Base64.ALPHA.charAt(mod) + result;
      value = Math.floor(value / 64);
    } while(value > 0);

    return result;
  };

  static decode(value: string) {

    let result = 0;
    for (let i = 0, len = value.length; i < len; i++) {
      result *= 64;
      result += Base64.ALPHA.indexOf(value[i]);
    }

    return result;
  };


  static generateId( suffix = 0 ): string {

    const epoc = new Date( '2021-03-01');
    const now = new Date();
    let numericId = Math.floor( (now.getTime() - epoc.getTime()) / 1000 );

    numericId *= 10;
    numericId += suffix;

    const id = Base64.encode( numericId );
    console.log( `${numericId} -> "${id}" -> ${Base64.decode( id )}`  );

    return id;
  }


}
// ^^^ derived from [Base64 encode & decode (only for numbers) in JavaScript · GitHub](https://gist.github.com/alkaruno/b84162bae5115f4ca99b)
