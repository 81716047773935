


export class MojoHttpHeaders {


  public static readonly CONTENT_TYPE: {

    header: string;
    values: {
      applicationOctetStream: string
      applicationOctetJson: string
    }
  } = {
    header: "Content-Type",
    values: {
      applicationOctetStream: 'application/octet-stream',
      applicationOctetJson: 'application/json'
    }
  }

  public static readonly AUTHORIZATION: {
    header: string
  } = {
    header: 'Authorization'
  }

}
