import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoProduct} from "./NocoProduct";


export interface INocoEvaluationQuestionTextAlt {
  Id: number;
  EvaluationQuestionId: number;
  InfoBox: string;
  ProductId: number;
  QuestionText: string;

}
export class NocoEvaluationQuestionTextAlt extends ValueObject<INocoEvaluationQuestionTextAlt>{

  protected onSetValue(value: INocoEvaluationQuestionTextAlt | null) {
  }

  constructor( value: INocoEvaluationQuestionTextAlt | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }

}


export class NocoEvaluationQuestionTextAltSet {

  evaluationQuestionTextAlts: NocoEvaluationQuestionTextAlt[] = [];
  evaluationQuestionTextAltsById: {[id: number]: NocoEvaluationQuestionTextAlt} = {};


  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {

    if( projectProxy.proxy.isNocoDbVersion202Plus ) {

      return 'evaluation_question_text_alt';
    }
    return 'EvaluationQuestionTextAlt';

  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEvaluationQuestionTextAlt>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoEvaluationQuestionTextAlt>( tableName );
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEvaluationQuestionTextAltSet> {

    const value = await NocoEvaluationQuestionTextAltSet.getValue( proxy );
    return new NocoEvaluationQuestionTextAltSet( value );
  }


  public filterByProduct( product: NocoProduct ): NocoEvaluationQuestionTextAlt[] {

    const targetProductId = product.value.Id;
    const answer = [];

    for( const candidate of this.evaluationQuestionTextAlts ) {

      if( targetProductId === candidate.value.ProductId ) {

        answer.push( candidate );
      }
    }

    return answer;
  }



  public constructor( public value: IListingReponse<INocoEvaluationQuestionTextAlt> ) {


    for( const rowValue of value.list ) {

      const reference = new NocoEvaluationQuestionTextAlt( rowValue );
      this.evaluationQuestionTextAlts.push( reference );
      this.evaluationQuestionTextAltsById[rowValue.Id] = reference;
    }

  }

}


