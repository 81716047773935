
<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">

    <app-side-menu homeDisabled="true" [sidenav]="sidenav">
    </app-side-menu>

  </mat-sidenav>

  <mat-sidenav-content>


    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">
        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png" style="height: 34px;">
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>
      </mat-toolbar>


      <div aclass="page-content">


        <app-session-checker
          *ngIf="!sessionContext.sessionIsReady">
        </app-session-checker>


        <div *ngIf="sessionContext.sessionIsReady">

          <div *ngIf="!sessionContext.propertyContext">

            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex cclass="instructions">
                  You have no properties associated with your account.
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">
                <div fxFlex cclass="instructions">
                  If you believe this is a mistake, please contact the <strong>Mobility Mojo</strong> staff.
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="sessionContext.propertyContext && sessionContext.propertyContext.property.value.clustered">
            Jumping to Questions by area {{jumpToClusters()}}
          </div>

          <div *ngIf="sessionContext.propertyContext && !sessionContext.propertyContext.property.value.clustered">

            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex cclass="instructions">
                  Evaluating:
                  <strong>
                    {{sessionContext.propertyContext.property.value.name}}
                  </strong>
                  <span style="display: none">
                    propertyKey: {{sessionContext.propertyContext.property.propertyKey}}
                  </span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">
                <div fxFlex cclass="instructions">
                  <strong>
                    To complete your
                    <span [ngSwitch]="productType">
                  <span *ngSwitchCase="productTypes.brandHome">brand home</span>
                  <span *ngSwitchCase="productTypes.facilities">facilities</span>
                  <span *ngSwitchCase="productTypes.manufacturing">manufacturing</span>
                </span>
                    evaluation, click each section and answer the questions
                  </strong>
                </div>
              </div>
            </div>





            <button class="button-general" block cclass="hero-button"
                    *ngFor="let $aspectAnswers of sessionContext.propertyContext.aspectAnswers; let $index = index"
                    (click)="onSectionOpen( $index )">


              <div cclass="section-button">
                <div cclass="section-icon">
                  <img src="assets/icons/general_hotel_icon.purple.svg">
                </div>
                <div cclass="section-index">
                  {{getSectionTitle($index)}}
                </div>
                <div cclass="section-title">
                  <h3>
                    {{$aspectAnswers.aspectQuestions.value.name}}
                  </h3>
                </div>
                <div cclass="section-completed">
                  <completed-tick
                    [completed]="$aspectAnswers.isCompleted()"
                  ></completed-tick>
                </div>
              </div>
            </button>



            <div *ngIf="sessionContext.propertyContext">

              <button class="button-general" block cclass="hero-button"
                      (click)="onSubmit()"
                      [disabled]="null!=reasonSubmitDisabled()"
              >

                <div cclass="section-button">
                  <div cclass="section-icon">
                    <mat-icon>send</mat-icon>
                  </div>
                  <div cclass="section-index">
                    {{submitSectionIndexText()}}
                  </div>
                  <div cclass="section-title">
                    <h3>
                      Submit
                    </h3>
                  </div>
                  <div cclass="section-completed">
                  </div>
                </div>
              </button>

            </div>







            <div *ngIf="!productIsBanking && sessionContext.propertyContext.isEvaluationCompleted()">

              <button class="button-general" block cclass="hero-button"
                      (click)="onShowScore()">


                <div cclass="section-button">
                  <div cclass="section-icon">
                    <mat-icon style="">trending_up</mat-icon>
                  </div>
                  <div cclass="section-index">
                  </div>
                  <div cclass="section-title">
                    <h3>
                      Calculate rating
                    </h3>
                  </div>
                  <div cclass="section-completed">
                  </div>
                </div>
              </button>

              <button class="button-general" block cclass="hero-button"
                      (click)="openPopup()">


                <div cclass="section-button">
                  <div cclass="section-icon">
                    <mat-icon style="">accessible_forward</mat-icon>
                  </div>
                  <div cclass="section-index">
                  </div>
                  <div cclass="section-title">
                    <h3>
                      Demo popup
                    </h3>
                  </div>
                  <div cclass="section-completed">
                  </div>
                </div>
              </button>



            </div>


          </div>


          <div *ngIf="sessionContext.documents.length">

            <button class="button-general" block cclass="hero-button"
                    (click)="onOpenDocuments()">


              <div cclass="section-button">
                <div cclass="section-icon">
                  <mat-icon style="">cloud_download</mat-icon>
                </div>
                <div cclass="section-index">
                </div>
                <div cclass="section-title">
                  <h3>
                    Documents
                  </h3>
                </div>
                <div cclass="section-completed">
                </div>
              </div>
            </button>

          </div>



        </div>


<!--        <div style="position: absolute; z-index: 999;">-->
<!--          <button (click)="toggleFireworks()">{{ enabled ? 'Mounted' : 'Unmounted' }}</button>-->
<!--          <button (click)="waitStop()">waitStop</button>-->
<!--        </div>-->

        <ng-fireworks #fireworks="ngFireworks"
                      [options]="options"
                      class="fireworks"
                      *ngIf="fireworksEnabled"
        >
        </ng-fireworks>


      </div>


      <mat-toolbar aclass="footer-toolbar">

        <mat-toolbar-row *ngIf="sessionContext.fbUser">
          <button mat-button disabled>
            <mat-icon>chevron_left</mat-icon>
            PREVIOUS
          </button>
          <span style="flex: 1 1 auto;"></span>
          <button mat-button
                  [disabled]="!sessionContext.propertyContext"
                  (click)="onNext()">
            NEXT
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>


    </div>
  </mat-sidenav-content>

</mat-sidenav-container>



