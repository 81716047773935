


export interface IMMBaseException {

  codeOrigin: string;
  issue: string;
  callingContext: any;
}


export class MMBaseException {

  public static build( codeOrigin: string, issue: string, callingContext = {} ) {

    const value: IMMBaseException = {
      codeOrigin,
      issue,
      callingContext,
    }

    return new MMBaseException( value );
  }

  constructor( public value: IMMBaseException ) {
  }


}
