

export abstract class BaseApplicationContext {


  public isTest:boolean;
  public isProduction:boolean;


}
