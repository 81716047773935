

<div cclass="app-content">

  <div *ngIf="isTest" class="corner-ribbon top-right sticky">test</div>

  <div cclass="router_wrapper">


    <router-outlet >
    </router-outlet>

  </div>



</div>


