import {LogEvent} from "./LogEvent";
import {ILogger} from "./Logger";
import {BaseLogger} from "./BaseLogger";
import {LoggerDelegate} from "./LoggerDelegate";
import {ILoggerFactory} from "./LoggerFactory";

export class ProductionConsoleLogger extends BaseLogger implements ILogger {


  public static factory: ILoggerFactory = {

    build(logContainer: string): ILogger {

      return new ProductionConsoleLogger(logContainer);
    }
  }


  public debug(message: string, ...params ) {
  }

  enteredMethod(methodName: string = null) {
  }

  public error(message: string, ...params ) {

    {
      const event = this.buildErrorEvent(message, params );
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.error(`${this.logContainer}: ${message}`, ... params);
  }

  public info(message: string, ... params ) {

    // {
    //   const event = this.buildInfoEvent(message, params );
    //   LoggerDelegate.INSTANCE.handle( event );
    // }

    console.info(`${this.logContainer}: ${message}`, ... params);
  }


  logDebug(message: string, addContext: (event: LogEvent) => void) {
  }


  logError(message: string, addContext: (event: LogEvent) => void) {

    {
      const event = this.buildErrorEvent(message );
      addContext(event);
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.error(`${this.logContainer}: ${message}`);
  }

  logInfo(message: string, addContext: (event: LogEvent) => void) {

    {
      const event = this.buildInfoEvent(message );
      addContext(event);
      // LoggerDelegate.INSTANCE.handle( event );
    }

    console.info(`${this.logContainer}: ${message}`);
  }

  logWarn(message: string, addContext: (event: LogEvent) => void) {

    {
      const event = this.buildWarnEvent(message );
      addContext(event);
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.warn(`${this.logContainer}: ${message}`);
  }

  public warn(message: string, ...params ) {

    {
      const event = this.buildWarnEvent(message, params );
      LoggerDelegate.INSTANCE.handle( event );
    }

    console.warn(`${this.logContainer}: ${message}`, ... params);
  }

  public constructor(logContainer: string) {

    super(logContainer);
  }
}
