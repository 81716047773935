import {IAppQuestion} from "../../../model/AppQuestion";
import {IAppAnswer} from "../../../model/AppAnswer";
import {
  BedroomQuestions01,
  BedroomQuestions02,
  BedroomQuestions03,
  BedroomQuestions04,
  BedroomQuestions05,
  BedroomQuestions06,
  BedroomQuestions07,
  BedroomQuestions08
} from "./AccessibilityQuestions";


export class Bedroom {


  /**
   * @deprecated use 'BedroomQuestions01.QUESTION_VALUES'
   */
  static readonly QUESTIONS_1: IAppQuestion[] = BedroomQuestions01.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions02.QUESTION_VALUES'
   */
  static readonly QUESTIONS_2: IAppQuestion[] = BedroomQuestions02.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions03.QUESTION_VALUES'
   */
  static readonly QUESTIONS_3: IAppQuestion[] = BedroomQuestions03.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions04.QUESTION_VALUES'
   */
  static readonly QUESTIONS_4: IAppQuestion[] = BedroomQuestions04.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions05.QUESTION_VALUES'
   */
  static readonly QUESTIONS_5: IAppQuestion[] = BedroomQuestions05.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions06.QUESTION_VALUES'
   */
  static readonly QUESTIONS_6: IAppQuestion[] = BedroomQuestions06.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions07.QUESTION_VALUES'
   */
  static readonly QUESTIONS_7: IAppQuestion[] = BedroomQuestions07.QUESTION_VALUES;

  /**
   * @deprecated use 'BedroomQuestions08.QUESTION_VALUES'
   */
  static readonly QUESTIONS_8: IAppQuestion[] = BedroomQuestions08.QUESTION_VALUES;



  public static readonly ALL_QUESTION_VALUES_SETS: IAppQuestion[][] = [

    BedroomQuestions01.QUESTION_VALUES,
    BedroomQuestions02.QUESTION_VALUES,
    BedroomQuestions03.QUESTION_VALUES,
    BedroomQuestions04.QUESTION_VALUES,
    BedroomQuestions05.QUESTION_VALUES,
    BedroomQuestions06.QUESTION_VALUES,
    BedroomQuestions07.QUESTION_VALUES,
    BedroomQuestions08.QUESTION_VALUES,
  ];


  private static _QUESTIONS_ALL: IAppQuestion[] = null;

  public static getAllQuestionValues() {

    if ( null !== Bedroom._QUESTIONS_ALL ) {

      return Bedroom._QUESTIONS_ALL;
    }


    this._QUESTIONS_ALL = [];

    for ( const questionGroup of Bedroom.ALL_QUESTION_VALUES_SETS ) {
      for ( const question of questionGroup ) {

        this._QUESTIONS_ALL.push( question );
      }
    }

    return Bedroom._QUESTIONS_ALL;
  }


  static calculateScoreAcrossQuestions( answerValues: { [key: string]: IAppAnswer; } ): number {
    let answer = 0;

    answer += BedroomQuestions01.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions02.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions03.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions04.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions05.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions06.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions07.calculateScoreAcrossQuestions( answerValues );
    answer += BedroomQuestions08.calculateScoreAcrossQuestions( answerValues );

    return answer;
  }


}
