import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  DialogData
} from "../../module.evaluation-components/question-boolean/question-boolean-dialog/question-boolean-dialog";
import {EnumeratedConstantReference} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {DialogConfig} from "../../angular/DialogConfig";


export interface IConfirmCancelParams {
  cancelLabel?: string;
  hideCancelLabel?: boolean;
  message: string;
  okLabel?: string;
  title: string;
}

@Component({
  selector: 'confirm-cancel-dialog',
  templateUrl: 'confirm-cancel-dialog.html',
  styleUrls: ['confirm-cancel-dialog.scss']
})
export class ConfirmCancelDialogComponent {

  public static PANEL_CLASS = "confirm-cancel-dialog";

  params: IConfirmCancelParams;
  okLabel = 'OK';
  cancelLabel = 'CANCEL';



  static open(dialog: MatDialog, params: IConfirmCancelParams ): MatDialogRef<ConfirmCancelDialogComponent> {

    const answer = dialog.open<ConfirmCancelDialogComponent,IConfirmCancelParams,boolean>(ConfirmCancelDialogComponent, {
      data: params,
      panelClass: ConfirmCancelDialogComponent.PANEL_CLASS,
      minWidth: DialogConfig.minWidth,
    });

    return answer;
  }


  onCancelClick(): void {

    this.dialogRef.close(false );
  }

  onOkClick(): void {

    this.dialogRef.close( true );
  }


  constructor(
    public dialogRef: MatDialogRef<ConfirmCancelDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IConfirmCancelParams,
  ) {
    this.params = data;
    if( this.params.cancelLabel ) {

      this.cancelLabel = this.params.cancelLabel;
    }
    if( this.params.okLabel ) {

      this.okLabel = this.params.okLabel;
    }
  }

}
