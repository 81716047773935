import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {QuestionCentimetersComponent} from '../question-centimeters/question-centimeters';
import {QuestionIntegerComponent} from '../question-integer/question-integer';
import {AppQuestion} from '../../../javascript.lib.mojo-base/model/AppQuestion';
import {environment} from "../../../environments/environment";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";

@Component({
  selector: 'app-question-list',
  templateUrl: './aspect-question-list.component.html',
})
export class AspectQuestionListComponent implements OnInit {


  @Input() answers: AppAnswer[] = null;
  @Input() propertyKey: string = null;

  showQuestionId: boolean = environment.isTest || environment.isDevelopment;

  @ViewChildren(QuestionCentimetersComponent) questionCentimetersComponents: QueryList<QuestionCentimetersComponent>;
  @ViewChildren(QuestionIntegerComponent) questionIntegerComponents: QueryList<QuestionIntegerComponent>;



  questionTypes = {
    TYPE_BOOLEAN: AppQuestion.TYPE_BOOLEAN,
    TYPE_CM_MEASUREMENT: AppQuestion.TYPE_CM_MEASUREMENT,
    TYPE_ENUM: AppQuestion.TYPE_ENUM,
    TYPE_FLOAT: AppQuestion.TYPE_FLOAT,
    TYPE_INTEGER: AppQuestion.TYPE_INTEGER,
    TYPE_LINE: AppQuestion.TYPE_LINE,
    TYPE_PHOTO: AppQuestion.TYPE_PHOTO,
    TYPE_TERNARY: AppQuestion.TYPE_TERNARY,
    TYPE_TEXT: AppQuestion.TYPE_TEXT,
  };


  hasIssues(): boolean {

    for( const questionCentimetersComponent of this.questionCentimetersComponents.toArray() ) {

      if( questionCentimetersComponent.hasIssues() ) {
        return true;
      }
    }
    for( const questionIntegerComponent of this.questionIntegerComponents.toArray()) {

      if( questionIntegerComponent.hasIssues() ) {
        return true;
      }
    }

    return false;
  }

  ngOnInit() {

    if( !this.showQuestionId ) {

      // Failte Ireland environment ?
      if( -1 !== environment.filename.indexOf( '-70768473') ) {

        this.showQuestionId = true;
      }
    }

  }

  constructor() { }


}
