



export interface ISignInParams {

  apiKey: string;
  lang: string;
  mode: string;
  oobCode: string;
}


export class SignInParams {


  public static INSTANCE: SignInParams|null = null;

  // public apiKey: string;
  // public lang: string;
  // public mode: string;
  // public oobCode: string;

  private static _initFromParams( params:{ [key: string]: any; }, locationHref: string ): SignInParams|null {

    if( null != this.INSTANCE ) {
      return this.INSTANCE;
    }


    const apiKey = params['apiKey'];
    console.log( 'SignInParams.ts', 'apiKey', apiKey );

    if( !apiKey ) {

      return null;
    }

    const lang = params['lang'];
    console.log( 'SignInParams.ts', 'lang', lang );
    if( !lang ) {

      return null;
    }

    const mode = params['mode'];
    console.log( 'SignInParams.ts', 'mode', mode );
    if( !mode ) {

      return null;
    }

    if( 'signIn' !== mode ) {

      return null;
    }


    const oobCode = params['oobCode'];
    console.log( 'SignInParams.ts', 'oobCode', oobCode );

    if( !oobCode ) {

      return null;
    }

    this.INSTANCE = new SignInParams({
      apiKey,
      lang,
      mode,
      oobCode,
    }, locationHref );

    return this.INSTANCE;

  }

  public static initFromWindow(window: Window): SignInParams|null {

    const urlParameters: { [key: string]: any; } = {
    };

    { // derived from https://stackoverflow.com/questions/48447629/new-urllocation-href-doesnt-work-in-ie

      const qs = window.location.search.substring(1);

      const pattern = /([^&=]+)=([^&]*)/g;
      let itm;
      while (itm = pattern.exec(qs)) {
        urlParameters[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
      }

    } // derived from https://stackoverflow.com/questions/48447629/new-urllocation-href-doesnt-work-in-ie

    // console.log( 'SignInParams.ts', 'urlParameters', urlParameters );

    return SignInParams._initFromParams( urlParameters, window.location.href );
  }



  private constructor( public value: ISignInParams, public locationHref: string ) {


  }

}
