



<div mclass="question-card">

  <div mclass="help">
    <help [answer]="answer"></help>
  </div>

  <div mclass="question-label">
    <div style="display: none">
      {{answer.question.value.key}}
    </div>
    <strong *ngIf="showQuestionId"> (#{{answer.question.value.nocoDbId}})</strong>
    <span mclass="question-label-text"
          [ngClass]="{'disabledQuestion':_disabled}">
        {{answer.question.value.label}}
      </span>
  </div>

  <div mclass="answer-widget">
    <button cclass="picker" mclass="picker" mat-stroked-button color="primary"
            [disabled]="_disabled"
            (click)="onOpenDialog()">
      <mat-icon>arrow_drop_down</mat-icon>
      {{answer.value.value?trueLabel:falseLabel}}
    </button>
  </div>

</div>

<!--<div fxLayout="column" fxLayoutGap="10px" style="padding: 10px 8px 0px 8px;">-->

<!--  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">-->
<!--    <div fxFlex="16" [fxFlex.xs]="16">-->
<!--      <help [answer]="answer"></help>-->
<!--    </div>-->
<!--    <div fxFlex="67" [fxFlex.xs]="60">-->
<!--      <div style="display: none">-->
<!--        {{answer.question.value.key}}-->
<!--      </div>-->
<!--      <span style="margin-top: 6px; margin-bottom: 4px;"-->
<!--            [ngClass]="{'disabledQuestion':_disabled}">-->
<!--        {{answer.question.value.label}}-->
<!--      </span>-->


<!--    </div>-->
<!--    <div fxFlex="16" [fxFlex.xs]="23" [ngSwitch]="_disabled">-->


<!--      <button cclass="picker" mclass="picker" mat-stroked-button color="primary"-->
<!--              [disabled]="_disabled"-->
<!--              (click)="onOpenDialog()">-->
<!--        <mat-icon>arrow_drop_down</mat-icon>-->
<!--        {{answer.value.value?trueLabel:falseLabel}}-->
<!--      </button>-->

<!--    </div>-->

<!--  </div>-->

<!--</div>-->



