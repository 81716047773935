import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppParentChild, IAppParentChild} from "../../../model/app.cluster/AppParentChild";
import {ECGReferenceType} from "../../../model/cg/core/CGReferenceType";
import {environment} from "../../../../environments/environment";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";
import {AppTypedReferenceString} from "../../../model/cg/core/AppTypedReference";
import {AppParentChildSet} from "../../../model/app.cluster/AppParentChildSet";


export class FirebaseParentChild {

  private static _getPath( propertyKey: string, parentChild: AppParentChild|null ): string {

    const productKey = environment.productConfig.productType;

    if( parentChild ) {

      return `/answer-clusters/${productKey}/${propertyKey}/${ECGReferenceType.parent_child}/${parentChild._self.toString}`;
    }

    return `/answer-clusters/${productKey}/${propertyKey}/${ECGReferenceType.parent_child}`;
  }

  static async writeReference( firebaseConnection: IFirebaseConnection, propertyKey: string, parentChild: AppParentChild ): Promise<void> {

    const path = this._getPath( propertyKey, parentChild );
    FirebaseMMValueObject.addUpdate( firebaseConnection, parentChild );
    return firebaseConnection.database2.setValue( path, parentChild.value );
  }


  static async readReferences( firebaseConnection: IFirebaseConnection, propertyKey: string ): Promise<AppParentChildSet> {

    const path = this._getPath( propertyKey, null );
    const values = await firebaseConnection.database2.getValue<{ [key: AppTypedReferenceString]: IAppParentChild; }|null>( path );

    if( !values ) {

      return new AppParentChildSet({});
    }
    return new AppParentChildSet( values );
  }


}
